<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md8 offset-md2 lg6 offset-lg3>
      <v-card>
        <v-btn text color="blue" to="/messages" class="mt-10 ml-5"><v-icon>mdi-chevron-left</v-icon> Inapoi la mesaje</v-btn>
        <v-card-title class="display-3 font-weight-light pl-10">
          Invitație în competiție
        </v-card-title>
        
        <v-spacer></v-spacer>
        
        <v-card-text v-if="carInviteActionResponse.status == 200">
          <v-subheader class="text-h5 font-weight-light mb-3">
            <v-icon class="mr-3">mdi-comment-question</v-icon> {{carInvite.title}}
          </v-subheader>
          
          <span class="subtitle-1 ml-4">
            Ai fost invitat să conduci mașina <v-icon>mdi-car</v-icon> <b>#{{carInvite.car.raceNumber}} {{carInvite.car.carName}}</b> în competiția <v-icon>mdi-trophy-outline</v-icon> <b>{{carInvite.season.title}}</b>. Accepți?
          </span>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              to="/messages"
            >
              Amână
            </v-btn>
            
            <v-btn
              color="red" 
              dark
              @click="rejectInvite()"
            >
              Refuză
            </v-btn>
            
            <v-btn
              color="green"
              dark
              @click="acceptInvite()"
            >
              Acceptă
            </v-btn>
          </v-card-actions>
        </v-card-text>
        
        <v-card-text v-else>
          <v-subheader class="text-h4 font-weight-light mb-3">
            <v-icon large class="mr-3 mdi-flip-v">mdi-timer-sand</v-icon> {{carInviteActionResponse.data}}
          </v-subheader>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar
      :timeout="5000"
      :value="alert.visible"
      :color="alert.color"
      >
      {{alert.content}}
    </v-snackbar>
  </v-layout>
</template>
<script>  
  import {mapState, mapActions} from 'vuex'
  export default {
    data: () => {
      return {
        alert: {
          content: '',
          color: 'success',
          visible: false
        },
      }
    },
    methods: {
      ...mapActions('carInvite', ['getCarInvite', 'acceptCarInvite', 'rejectCarInvite']),
      
      async acceptInvite () {
        await this.acceptCarInvite( this.$route.params.id )
        this.$router.push ( {path: `/season/${this.carInvite.season._id}` } )
      },
      
      async rejectInvite () {
        await this.rejectCarInvite( this.$route.params.id )
        this.$router.push ( {path: '/' } )
      },
    },
    computed: {
      ...mapState ( 'carInvite', ['carInvite', 'carInviteActionResponse'] ),
    },
    async mounted () {
      await this.getCarInvite ( this.$route.params.id )
      
    }
  }
</script>
