import axios from '@/plugins/axios'

const state = {
    teamInvite: {},
    teamInviteActionResponse: {}
}

const getters = {
    teamInvite(state) {
        return state.teamInvite
    },
}

const actions = {
    async getTeamInvite({
        commit
    }, teamInviteId) {
        let eventsResponse = await axios({
            url: `/api/team-invite/${teamInviteId}`,
            method: 'GET'
        })

        commit('GET_TEAM_INVITE', eventsResponse.data)
    },
    async acceptTeamInvite({
        commit
    }, teamInviteId) {
        let eventsResponse = await axios({
            url: `/api/team-invite/${teamInviteId}/accept`,
            method: 'POST'
        })
        commit('POST_ACCEPT_TEAM_INVITE', eventsResponse.data)
    },
    async rejectTeamInvite({
        commit
    }, teamInviteId) {
        let eventsResponse = await axios({
            url: `/api/team-invite/${teamInviteId}/reject`,
            method: 'POST'
        })
        commit('POST_REJECT_TEAM_INVITE', eventsResponse.data)
    },
}

// mutations
const mutations = {
    GET_TEAM_INVITE(state, payload) {
      if (payload.status == 200 ) {
        state.teamInvite = payload.data
        state.teamInviteActionResponse = payload
      } else {
        state.teamInvite = {}
        state.teamInviteActionResponse = payload
      }
    },
    POST_ACCEPT_TEAM_INVITE(state, payload) {
        state.teamInviteActionResponse = payload
    },
    POST_REJECT_TEAM_INVITE(state, payload) {
        state.teamInviteActionResponse = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

