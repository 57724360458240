<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md8 offset-md2 lg6 offset-lg3>
      <v-card>
        <v-btn text color="blue" to="/messages" class="mt-10 ml-5"><v-icon>mdi-chevron-left</v-icon> Înapoi la mesaje</v-btn>
        <v-card-title class="display-3 font-weight-light pl-10">
          Invitație în club
        </v-card-title>
        
        <v-spacer></v-spacer>
        
        <v-card-text v-if="teamInviteActionResponse.status == 200">
          <v-subheader class="text-h5 font-weight-light mb-3">
            <v-icon class="mr-3">mdi-comment-question</v-icon> {{teamInvite.title}}
          </v-subheader>
          
          <span class="subtitle-1 ml-4">
            <v-icon>mdi-account-group</v-icon> <b>{{teamInvite.team.name}}</b> ți-au oferit o invitație ca să devii <v-icon>mdi-racing-helmet</v-icon> <b>pilot</b> în club. Accepți?
          </span>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              to="/messages"
            >
              Amână
            </v-btn>
            
            <v-btn
              color="red" 
              dark
              @click="rejectInvite()"
            >
              Refuză
            </v-btn>
            
            <v-btn
              color="green"
              dark
              @click="acceptInvite()"
            >
              Acceptă
            </v-btn>
          </v-card-actions>
        </v-card-text>
        
        <v-card-text v-else>
          <v-subheader class="text-h4 font-weight-light mb-3">
            <v-icon large class="mr-3 mdi-flip-v">mdi-timer-sand</v-icon> {{teamInviteActionResponse.data}}
          </v-subheader>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar
      :timeout="5000"
      :value="alert.visible"
      :color="alert.color"
      >
      {{alert.content}}
    </v-snackbar>
  </v-layout>
</template>
<script>  
  import {mapState, mapActions} from 'vuex'
  export default {
    data: () => {
      return {
        alert: {
          content: '',
          color: 'success',
          visible: false
        },
      }
    },
    methods: {
      ...mapActions('teamInvite', ['getTeamInvite', 'acceptTeamInvite', 'rejectTeamInvite']),
      
      async acceptInvite () {
        await this.acceptTeamInvite( this.$route.params.id )
        this.$router.push ( {path: '/teams' } )
      },
      
      async rejectInvite () {
        await this.rejectTeamInvite( this.$route.params.id )
        this.$router.push ( {path: '/teams' } )
      },
    },
    computed: {
      ...mapState ( 'teamInvite', ['teamInvite', 'teamInviteActionResponse'] ),
    },
    async mounted () {
      await this.getTeamInvite ( this.$route.params.id )
    }
  }
</script>
