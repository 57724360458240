const defaultSnackbar = {
    timeout: -1,
    value: false,
    color: 'success',
    content: '',
    action: {
        callback: () => {},
        params: null,
        text: null,
        thisVal: null
    }
}


const state = {
    snackbarTimeout: 0,
    navigationDrawer: false,
    loading: false,
    snackbar: {
        timeout: -1,
        value: false,
        color: 'success',
        content: '',
        action: {
            callback: () => {},
            params: null,
            text: null,
            thisVal: null
        }
    }
}

const getters = {
    navigationDrawer(state) {
        return state.navigationDrawer
    },
    loading(state) {
        return state.loading
    },
    snackbar(state) {
        return state.snackbar
    }
}

const actions = {
    setLoading({commit}, value) {
        commit('SET_LOADING', value)
    },
    showSnackbar({commit, dispatch}, snackbar) {
        let newSnackbar = {...defaultSnackbar, ...snackbar}
        commit('SHOW_SNACKBAR', {snackbar: newSnackbar, dispatch: dispatch})
    },
    closeSnackbar({commit}, skipCallback = false) {
        if (!skipCallback) {
            state.snackbar.action.callback.call(state.snackbar.action.thisVal, state.snackbar.action.params)
        }
        commit('CLOSE_SNACKBAR')
    }
}

// mutations
const mutations = {
    TOGGLE_DRAWER(state) {
        state.navigationDrawer = !state.navigationDrawer
    },
    SET_LOADING(state, value) {
        state.loading = value
    },
    SHOW_SNACKBAR(state, {snackbar, dispatch}) {
        state.snackbarTimeout = setTimeout(() => {
            dispatch('closeSnackbar', true)

        }, snackbar.timeout + 100)
        state.snackbar = snackbar
    },
    CLOSE_SNACKBAR(state) {
        state.snackbar.value = false
        state.snackbarTimeout = 0
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}