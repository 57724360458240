import axios from '@/plugins/axios'

const state = {
    profile: {
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        raceNumber: '',
        steamId: '',
        iRacingMemberId: '',
        profilePicture: '',
        shorthand: ''
    },
    userRoles: [],
    userPermissions: [],
    users: []
}

const getters = {
    profile(state) {
        return state.profile
    },
    users(state) {
        return state.users
    },
    userRoles(state) {
      return state.userRoles
    },
    userPermissions(state) {
      return state.userPermissions
    }
}

const actions = {
    async getUsers({
        commit
    }) {
        let usersResponse = await axios({
            url:'/api/users',
            method: 'GET'
        })
        let users = usersResponse.data.data.map((user) => {
            user.value = user._id
            if (user.profile && (user.profile.firstName || user.profile.lastName)) {
                user.text = `${user.profile.firstName} ${user.profile.lastName}`
            } else {
                user.text = user.email
            }
            return user
        })
        commit('GET_USERS', users)
    },
    async getProfile({
        commit
    }) {
        let profileResponse = await axios({
            url:'/user/profile',
            method: 'GET'
        })

        commit('GET_PROFILE', profileResponse.data.data)
    },
    async setProfile({dispatch}, profile) {
        await axios({
            url: '/user/profile',
            method: 'POST',
            data: profile
        })
        dispatch('getProfile')
    },
    async setProfilePicture({state, dispatch}, profilePictureUpload) {
        let formData = new FormData();
        formData.append('file', profilePictureUpload);
        formData.append('fileType', 'profilePicture')
        
        let result = await axios.post( '/api/upload', formData,{ headers: { 'Content-Type': 'multipart/form-data'}})
        let profile = Object.assign({}, state.profile);
        profile.profilePicture = result.data.data.fileUpload._id
        dispatch('setProfile', profile)

    },
    async getUserRoles({
        commit,
        rootGetters
    }) {
        if (!rootGetters['auth/isLoggedIn']) {
            commit('GET_USER_ROLES', []);
            return;
        }

        let rolesResponse = await axios({
            url:'/user/roles',
            method: 'GET'
        })

        commit('GET_USER_ROLES', rolesResponse.data.data)
    },
    async getUserPermissions({
        commit,
        rootGetters
    }) {
        if (!rootGetters['auth/isLoggedIn']) {
            commit('GET_USER_PERMISSIONS', []);
            return;
        }
        let permissionsResponse = await axios({
            url:'/user/permissions',
            method: 'GET'
        })

        commit('GET_USER_PERMISSIONS', permissionsResponse.data.data)
    },
    resetPassword({commit}, resetPassword) {
        commit('RESET_PASSWORD', resetPassword)
    },
    forgotPassword({commit}, forgotPassword) {
        commit('FORGOT_PASSWORD', forgotPassword)
    },
}

// mutations
const mutations = {
    GET_PROFILE(state, payload) {
        state.profile = payload
    },
    SET_PROFILE(state, payload) {
        state.profile = payload
    },
    SET_PROFILE_PICTURE(state, payload) {
        state.profile.profilePicture = payload
    },
    GET_USERS(state, payload) {
        state.users = payload
    },
    GET_USER_ROLES(state, payload) {
        state.userRoles = payload
    },
    GET_USER_PERMISSIONS(state, payload) {
        state.userPermissions = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
