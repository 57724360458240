import axios from '@/plugins/axios'

const state = {
    events: [],
    event: {
        season: {
            rules: {}
        },
        track: {
            trackFamily: {}
        }
    },
    currentEvent: {
        season: {
            rules: {}
        },
        track: {
            trackFamily: {}
        }
    },
    lastEvent: {
        season: {
            rules: {}
        },
        track: {
            trackFamily: {}
        }
    },
    entrylist: {
        prequal: {
            bestResult: 2147483647,
            thresholdResult: 2147483647
        },
        entries: []
    }
}

const getters = {
    events(state) {
        return state.events
    },
    event(state) {
        return state.event
    },
    currentEvent(state) {
        return state.currentEvent
    },
    lastEvent(state) {
        return state.lastEvent
    },
    entrylist(state) {
      return state.entrylist
    }
}

const actions = {
    async getEvents({
        commit
    }) {
        let eventsResponse = await axios({
            url: '/api/events',
            method: 'GET'
        })

        commit('GET_EVENTS', eventsResponse.data.data)
    },
    async getEvent({
        commit
    }, eventId) {
        let eventsResponse = await axios({
            url: `/api/event/${eventId}`,
            method: 'GET'
        })

        commit('GET_EVENT', eventsResponse.data.data)
    },
    async getCurrentEvent({
        commit
    }) {
        let eventsResponse = await axios({
            url: `/api/current-event/`,
            method: 'GET'
        })

        commit('GET_CURRENT_EVENT', eventsResponse.data.data)
    },
    async getLastEvent({
        commit
    }) {
        let eventsResponse = await axios({
            url: `/api/last-event/`,
            method: 'GET'
        })

        commit('GET_LAST_EVENT', eventsResponse.data.data)
    },
    async addEvent({
        commit
    }, event) {
        let eventsResponse = await axios({
            url: '/api/events/',
            method: 'POST',
            data: event
        })
        commit('GET_EVENTS', eventsResponse.data.data)
    },
    async editEvent({
        commit,
        state
    }) {
        let eventsResponse = await axios({
            url: `/api/event/${state.eventsResponse._id}`,
            method: 'POST',
            data: state.event
        })
        commit('GET_EVENTS', eventsResponse.data.data)
    },
    async deleteEvent({
        commit
    }, event) {
        let eventsResponse = await axios({
            url: `/api/event/${event.id}/delete`,
            method: 'POST',
            data: event
        })
        commit('GET_EVENTS', eventsResponse.data.data)
    },
    async getEntrylist({
      commit
    }, eventId) {
      let entrylistResponse = await axios({
        url: `/api/event/${eventId}/entrylist`,
        method: 'GET'
      });
      
      commit('GET_ENTRYLIST', entrylistResponse.data.data);
    }
}

// mutations
const mutations = {
    GET_EVENTS(state, payload) {
        state.events = payload
        state.event = {}
    },
    GET_EVENT(state, payload) {
        payload.cars = payload.cars.map((car) => {
            car.rsvpStatus = car.drivers.reduce((a, c) => a && c.registrationStatus, true)
            return car
        })
        state.event = payload
    },
    GET_CURRENT_EVENT(state, payload) {
        state.currentEvent = payload
    },
    GET_LAST_EVENT(state, payload) {
        state.lastEvent = payload
    },
    GET_ENTRYLIST(state, payload) {
      state.entrylist = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
