<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
        <v-container :class="{'mx-0': $vuetify.breakpoint.smAndDown, 'px-0': $vuetify.breakpoint.smAndDown}" v-if="currentSeasons.length > 0">
        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
          <template v-for="season in currentSeasons">
            <v-col
              :key="season._id"
              cols="12"
              md="6"
            >
              <v-card
                outlined
              >
                <v-img
                  :src="`/img/${season.image || 'rlr_logo.jpg'}`"
                  :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
                  :gradient="printGradient(season.rules.simulator.acronym)"
                  class="align-end"
                >
                  <v-card-title class="white--text pb-0 d-block">
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
                      style="word-break: normal !important"
                    >
                      {{season.title}}
                    </p>
                    <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">{{season.rules.simulator.title}}</p>
                    <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">{{season.description}}</p>
                  </v-card-title>
                </v-img>
                
                <v-card-text>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-list two-line class="py-0">
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-calendar-month</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                                <span>
                                  {{printDate ( Math.min.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) ) )}} <v-icon small>mdi-arrow-right</v-icon> {{printDate ( Math.max.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) ) )}}
                                </span>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider inset></v-divider>
                        </v-list>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-dialog
                                v-model="carDialog[season._id]"
                                @click:outside="carDialog[season._id] = false"
                                max-width="400"
                                v-if="season.rules.validCars.length > 1"
                              >
                                <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                    color="blue darken-1"
                                    dark
                                    fab
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-car-multiple</v-icon>
                                  </v-btn>
                                </template>
                                
                                <v-card>
                                  <v-card-title class="font-weight-black text-h5 text--primary">
                                    Mașini disponibile
                                  </v-card-title>
                                  
                                  <v-card-text>
                                <v-list>
                                  <template v-for="(carModel, i) in season.rules.validCars">
                                    <v-list-item  :key="i">
                                      <v-list-item-action-text class="pr-4">
                                        {{carModel.class.acronym}}
                                      </v-list-item-action-text>
                                      <v-list-item-content>
                                        <v-list-item-title>{{carModel.title}}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider  :key="carModel._id"></v-divider>
                                  </template>
                                </v-list>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                              <v-icon v-else>mdi-car</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                              <template v-if="season.rules.validCars.length > 1">
                                <v-list-item-title class="font-weight-bold">Mașini</v-list-item-title>
                                <span>
                                  {{season.rules.validCars.length}} mașini disponibile
                                </span>
                              </template>
                              <template v-else>
                                <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                                <span>
                                  {{season.rules.validCars.map ( carModel => {return carModel.title} ).join ( ', ' ) }} 
                                </span>
                              </template>
                            </v-list-item-content>
                          </v-list-item>
                          
                          <v-divider inset></v-divider>
                          
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-account-group</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content v-if="season.registrations" class="py-0">
                              <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                                {{season.registrations.reduce ( (a, c) => { return a+c.cars.length }, 0 )}} {{season.registrations.reduce ( (a, c) => { return a+c.cars.length }, 0 ) == 1 ? 'mașină înscrisă' : 'mașini înscrise'}}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      
                      <v-col
                        cols="6"
                      >
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-flag-checkered</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                                <span>
                                  {{season.events.length}} curse
                                </span>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-racing-helmet</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Echipaje</v-list-item-title>
                                <span v-if="season.rules.minDriversPerCar == season.rules.maxDriversPerCar">{{season.rules.minDriversPerCar}} {{season.rules.minDriversPerCar > 1 ? 'piloți' : 'pilot'}} în fiecare mașină</span>
                                <span v-else>Între {{season.rules.minDriversPerCar}} și {{season.rules.maxDriversPerCar}} piloți în fiecare mașină</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                
                <v-card-actions>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" :align="$vuetify.breakpoint.smAndUp ? 'left' : 'center'">
                      <v-btn
                        dark
                        color="blue darken-1"
                        :to="`/season/${season._id}?tab=calendar`"
                        class="ma-2"
                      ><v-icon left>mdi-calendar-month</v-icon>Calendar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        class="white--text ma-2"
                        :to="`/season/${season._id}?tab=register`"
                      >
                        <v-icon left>mdi-file-document-edit</v-icon>Înscrieri
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
                      <v-btn
                        dark
                        color="green darken-1"
                        :to="`/event/${currentSeasonsActiveEvents[season._id]._id}?tab=info`"
                        v-if="currentSeasonsActiveEvents[season._id]"
                        class="ma-2"
                      >
                        <v-icon left>mdi-folder-information</v-icon>Etapa actuală
                      </v-btn>
                      <v-btn
                        dark
                        color="green darken-1"
                        :to="`/event/${currentSeasonsActiveEvents[season._id]._id}?tab=registrations`"
                        v-if="currentSeasonsActiveEvents[season._id]"
                        class="ma-2"
                      >
                        <v-icon left>mdi-car-multiple</v-icon>Participanți
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
         <v-container :class="{'mx-0': $vuetify.breakpoint.smAndDown, 'px-0': $vuetify.breakpoint.smAndDown}" v-if="futureSeasons.length > 0">
          <div
            class="font-weight-black text-h4 text-center text-uppercase transition-swing mb-10"
            v-text="'Competiții viitoare'"
          ></div>
        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
          <template v-for="season in futureSeasons">
            <v-col
              :key="season._id"
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
            >
              <v-card
                class="mx-auto"
                :class="{ 'mx-auto': true, 'mb-5': $vuetify.breakpoint.smAndDown }"
              >
                <v-img
                  :src="`/img/${season.image || 'rlr_logo.jpg'}`"
                  :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
                  :gradient="printGradient(season.rules.simulator.acronym)"
                  class="align-end"
                >
                  <v-card-title class="white--text pb-0 d-block">
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
                    >
                      {{season.title}}
                    </p>
                    <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">{{season.rules.simulator.title}}</p>
                    <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3">{{season.description}}</p>
                  </v-card-title>
                </v-img>
                
                <v-card-text>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-list two-line class="py-0">
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-calendar-month</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                                <span>
                                  {{printDate ( Math.min.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) ) )}} <v-icon small>mdi-arrow-right</v-icon> {{printDate ( Math.max.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) ) )}}
                                </span>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider inset></v-divider>
                        </v-list>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-dialog
                                v-model="carDialog[season._id]"
                                @click:outside="carDialog[season._id] = false"
                                max-width="400"
                                v-if="season.rules.validCars.length > 1"
                              >
                                <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                    color="blue darken-1"
                                    dark
                                    fab
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-car-multiple</v-icon>
                                  </v-btn>
                                </template>
                                
                                <v-card>
                                  <v-card-title class="font-weight-black text-h5 text--primary">
                                    Mașini disponibile
                                  </v-card-title>
                                  
                                  <v-card-text>
                                <v-list>
                                  <template v-for="(carModel, i) in season.rules.validCars">
                                    <v-list-item  :key="i">
                                      <v-list-item-action-text class="pr-4">
                                        {{carModel.class.acronym}}
                                      </v-list-item-action-text>
                                      <v-list-item-content>
                                        <v-list-item-title>{{carModel.title}}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider  :key="carModel._id"></v-divider>
                                  </template>
                                </v-list>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                              <v-icon v-else>mdi-car</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                              <template v-if="season.rules.validCars.length > 1">
                                <v-list-item-title class="font-weight-bold">Mașini</v-list-item-title>
                                <span>
                                  {{season.rules.validCars.length}} mașini disponibile
                                </span>
                              </template>
                              <template v-else>
                                <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                                <span>
                                  {{season.rules.validCars.map ( carModel => {return carModel.title} ).join ( ', ' ) }} 
                                </span>
                              </template>
                            </v-list-item-content>
                          </v-list-item>
                          
                          <v-divider inset></v-divider>
                          
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-account-group</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content v-if="season.registrations" class="py-0">
                              <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                                {{season.registrations.reduce ( (a, c) => { return a+c.cars.length }, 0 )}} {{season.registrations.reduce ( (a, c) => { return a+c.cars.length }, 0 ) == 1 ? 'mașină înscrisă' : 'mașini înscrise'}}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      
                      <v-col
                        cols="6"
                      >
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-flag-checkered</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                                <span>
                                  {{season.events.length}} curse
                                </span>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-racing-helmet</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Echipaje</v-list-item-title>
                                <span v-if="season.rules.minDriversPerCar == season.rules.maxDriversPerCar">{{season.rules.minDriversPerCar}} {{season.rules.minDriversPerCar > 1 ? 'piloți' : 'pilot'}} în fiecare mașină</span>
                                <span v-else>Între {{season.rules.minDriversPerCar}} și {{season.rules.maxDriversPerCar}} piloți în fiecare mașină</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                
                <v-card-actions>
                  <v-btn
                    icon
                    large
                    color="blue darken-1"
                    :to="`/season/${season._id}?tab=info`"
                  ><v-icon>mdi-information</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    large
                    color="blue darken-1"
                    :to="`/season/${season._id}?tab=calendar`"
                  ><v-icon>mdi-calendar-month</v-icon>
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    class="white--text"
                    :to="`/season/${season._id}?tab=register`"
                  >
                    Înscrieri
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="green darken-1"
                    :to="`/event/${currentSeasonsActiveEvents[season._id]._id}?tab=info`"
                    v-if="currentSeasonsActiveEvents[season._id]"
                  >
                    <v-icon>mdi-folder-information</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
        <v-container :class="{'mx-0': $vuetify.breakpoint.smAndDown, 'px-0': $vuetify.breakpoint.smAndDown}" v-if="pastSeasons.length > 0">
          <div
            class="font-weight-black text-h4 text-center text-uppercase transition-swing mb-10 mt-12"
            v-text="'↓ Competiții Finalizate ↓'"
          ></div>
        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
          <v-col
            cols="12"
            md="6"
          >
            <BmwGtChallenge4 />
          </v-col>
          <template v-for="season in pastSeasons">
            <v-col
              :key="season._id"
              cols="12"
              md="6"
            >
              <v-card
                outlined
              >
                <v-img
                  :src="`/img/${season.image || 'rlr_logo.jpg'}`"
                  :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
                  :gradient="printGradient(season.rules.simulator.acronym)"
                  class="align-end"
                >
                  <v-card-title class="white--text pb-0 d-block">
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
                      style="word-break: normal !important"
                    >
                      {{season.title}}
                    </p>
                    <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">{{season.rules.simulator.title}}</p>
                    <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">{{season.description}}</p>
                  </v-card-title>
                </v-img>
                
                <v-card-text>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-list two-line class="py-0">
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-calendar-month</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                                <span>
                                  {{printDate ( Math.min.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) ) )}} <v-icon small>mdi-arrow-right</v-icon> {{printDate ( Math.max.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) ) )}}
                                </span>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider inset></v-divider>
                        </v-list>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-dialog
                                v-model="carDialog[season._id]"
                                @click:outside="carDialog[season._id] = false"
                                max-width="400"
                                v-if="season.rules.validCars.length > 1"
                              >
                                <template v-slot:activator="{on, attrs}">
                                  <v-btn
                                    color="blue darken-1"
                                    dark
                                    fab
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-car-multiple</v-icon>
                                  </v-btn>
                                </template>
                                
                                <v-card>
                                  <v-card-title class="font-weight-black text-h5 text--primary">
                                    Mașini disponibile
                                  </v-card-title>
                                  
                                  <v-card-text>
                                <v-list>
                                  <template v-for="(carModel, i) in season.rules.validCars">
                                    <v-list-item  :key="i">
                                      <v-list-item-action-text class="pr-4">
                                        {{carModel.class.acronym}}
                                      </v-list-item-action-text>
                                      <v-list-item-content>
                                        <v-list-item-title>{{carModel.title}}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider  :key="carModel._id"></v-divider>
                                  </template>
                                </v-list>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                              <v-icon v-else>mdi-car</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                              <template v-if="season.rules.validCars.length > 1">
                                <v-list-item-title class="font-weight-bold">Mașini</v-list-item-title>
                                <span>
                                  {{season.rules.validCars.length}} mașini disponibile
                                </span>
                              </template>
                              <template v-else>
                                <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                                <span>
                                  {{season.rules.validCars.map ( carModel => {return carModel.title} ).join ( ', ' ) }} 
                                </span>
                              </template>
                            </v-list-item-content>
                          </v-list-item>
                          
                          <v-divider inset></v-divider>
                          
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-account-group</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content v-if="season.registrations" class="py-0">
                              <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                                {{season.registrations.reduce ( (a, c) => { return a+c.cars.length }, 0 )}} {{season.registrations.reduce ( (a, c) => { return a+c.cars.length }, 0 ) == 1 ? 'mașină înscrisă' : 'mașini înscrise'}}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      
                      <v-col
                        cols="6"
                      >
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-flag-checkered</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                                <span>
                                  {{season.events.length}} curse
                                </span>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-racing-helmet</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content class="py-0">
                              <v-list-item-title class="font-weight-bold">Echipaje</v-list-item-title>
                                <span v-if="season.rules.minDriversPerCar == season.rules.maxDriversPerCar">{{season.rules.minDriversPerCar}} {{season.rules.minDriversPerCar > 1 ? 'piloți' : 'pilot'}} în fiecare mașină</span>
                                <span v-else>Între {{season.rules.minDriversPerCar}} și {{season.rules.maxDriversPerCar}} piloți în fiecare mașină</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                
                <v-card-actions>
                  <v-btn
                    icon
                    large
                    color="blue darken-1"
                    :to="`/season/${season._id}?tab=info`"
                  ><v-icon>mdi-information</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    large
                    color="blue darken-1"
                    :to="`/season/${season._id}?tab=calendar`"
                  ><v-icon>mdi-calendar-month</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="green darken-1"
                    :to="`/season/${season._id}?tab=standings`"
                  >
                    <v-icon left>mdi-clipboard-list</v-icon>Clasament
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
          <v-col
            cols="12"
            md="6"
          >
            <LogitechGChallenge2021 />
          </v-col>
        </v-row>
      </v-container>
  </v-layout>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import LogitechGChallenge2021 from '@/components/static_series/LogitechGChallenge2021.vue'
  import BmwGtChallenge4 from '@/components/static_series/BmwGtChallenge4.vue'

  export default {
    components: {
      LogitechGChallenge2021,
      BmwGtChallenge4
    },
    data: () => {
      return {
        inset: true,
        carModels: [],
        currentSeasons: [],
        pastSeasons: [],
        futureSeasons: [],
        carDialog: {}
      }
    },
    methods: {
      ...mapActions('season', ['getSeasons']),
      printGradient( simulator ) {
        let color = '220, 0, 0, 0.5';
        
        switch (simulator) {
          case 'ACC':
          case 'AC':
            color = '220, 0, 0, 0.5';
            break;
          case 'iR':
            color = '0, 0, 220, 0.5';
            break;
          default:
            color = '0, 0, 220, 0.5';
            break;
        }
        
        return `to top right, rgba(0,0,0,.6), rgba(${color})`
      },
      printDate ( date ) {
        let m = new Date ( date )
        
        let months = [
          'ianuarie',
          'februarie',
          'martie',
          'aprilie',
          'mai',
          'iunie',
          'iulie',
          'august',
          'septembrie',
          'octombrie',
          'noiembrie',
          'decembrie'
        ];
        
        return ("00"+m.getUTCDate()).slice(-2) + " " + months[ m.getUTCMonth() ] + " " + m.getUTCFullYear();
        
        // return  m.getUTCFullYear() +"-"+ ("00"+(m.getUTCMonth()+1)).slice(-2) +"-"+ ("00"+m.getUTCDate()).slice(-2);
      }
    },
    computed: {
      ...mapState('season', ['seasons'])
    },
    async mounted () {
      await this.getSeasons()
      
      this.seasons.forEach ( (season) => { this.carDialog[season._id] = false } );
      
      this.currentSeasons = this.seasons.filter ( (season) => {
        let startDate = Math.min.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) );
        let endDate = Math.max.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) );
        
        // Any series that will start in 2 weeks' time is considered to be open.
        startDate -= 14*24*60*60*1000;
        
        return (startDate < Date.now() && endDate > Date.now());
      });
      this.currentSeasons.sort ( (a, b) => { return Math.min.apply(null, a.events.map ( event => { return Date.parse ( event.time ) } ) ) > Math.min.apply(null, b.events.map ( event => { return Date.parse ( event.time ) } ) ) } )
      
      this.currentSeasonsActiveEvents = {}
      
      this.currentSeasons.forEach ( (season) => {
        let futureEvents = season.events;
        
        let nextEvent = futureEvents.sort ( (a, b) => { return a.time > b.time } ).find ( (event) => { return Date.parse ( event.time ) > Date.now() } );
        
        if ( nextEvent ) {
          this.currentSeasonsActiveEvents[season._id] = nextEvent;
        }
      });
      
      this.pastSeasons = this.seasons.filter ( (season) => {
        let endDate = Math.max.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) );
        
        return (endDate < Date.now());
      }).sort ( (a, b) => { return Math.max.apply(null, b.events.map ( event => { return Date.parse ( event.time ) } ) ) - Math.max.apply(null, a.events.map ( event => { return Date.parse ( event.time ) } ) ) } )
      
      this.futureSeasons = this.seasons.filter ( (season) => {
        let startDate = Math.min.apply(null, season.events.map ( event => { return Date.parse ( event.time ) } ) );
        
        // Any series that will start in 2 weeks' time is considered to be open.
        startDate -= 14*24*60*60*1000;
        return (startDate > Date.now());
      });
      this.futureSeasons.sort ( (a, b) => { return Math.min.apply(null, a.events.map ( event => { return Date.parse ( event.time ) } ) ) > Math.min.apply(null, b.events.map ( event => { return Date.parse ( event.time ) } ) ) } )
    }
  }
</script>
