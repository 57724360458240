<template>
  <v-layout row wrap align-center>
  </v-layout>
</template>

<script>
  import {
    mapActions,
    mapState
  } from 'vuex'

  export default {
    data: () => {
      return {
      }
    },
    methods: {
      ...mapActions('event', ['getCurrentEvent'])
    },
    computed: {
      ...mapState('event', ['currentEvent'])
    },
    async mounted() {
      await this.getCurrentEvent()
      this.$router.push({path: `/event/${this.currentEvent._id}?tab=registrations`})
    }
  }
</script>