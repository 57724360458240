<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md8 offset-md2 lg6 offset-lg3>
      <v-card>        
        <v-btn text color="blue" to="/teams" class="mt-10 ml-5"><v-icon>mdi-chevron-left</v-icon> Inapoi la cluburi</v-btn>
        <v-card-title class="display-3 font-weight-light pl-10">
          {{editingTeam.name}}
        </v-card-title>
        
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Numele clubului*" required v-model="editingTeam.name" :disabled="!isTeamOwner"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            :items="teamDrivers"
                            label="Piloti" multiple v-model="editingTeam.drivers"></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="12">
                        <v-autocomplete
                            :items="availableDrivers"
                            label="Invita piloti" multiple v-model="editingTeam.pendingDrivers"></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="12">
                        <v-autocomplete
                            :items="drivers" :disabled="!isTeamOwner"
                            label="Manageri" multiple v-model="editingTeam.managers"></v-autocomplete>
                    </v-col>

                </v-row>
            </v-container>
            <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="discard">
                Închide
            </v-btn>
            <v-btn color="blue darken-1" dark @click="saveTeam">
                Salvează
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    data: () => {
      return {
        newDrivers: [],
        availableDrivers: [],
        teamDrivers: []
      }
    },
    computed: {
      ...mapState('drivers', ['drivers']),
      ...mapState('user', ['users']),
      ...mapState('team', ['teams', 'editingTeam']),
      ...mapGetters('auth',['userinfo']),
      isTeamOwner() {
        if (this.userinfo && this.editingTeam && this.editingTeam.owner) {
          return this.userinfo.id == this.editingTeam.owner._id
        }
        return false
      }
    },
    async created() {
      await this.getDrivers()
      await this.getUsers()
      await this.getTeams()
      await this.getEditingTeam(this.$route.params.id)
      
      // Save initial data for display purposes:
      this.teamDrivers = this.editingTeam.drivers;
      
      await this.setAvailableDrivers ()
      await (async () => {
        let esteOwner = this.editingTeam.owner != null && this.editingTeam.owner._id == this.userinfo.id
        let esteManager = this.editingTeam.managers.filter(manager => manager._id == this.userinfo.id).length > 0
        if ( !esteOwner && !esteManager ) {
          this.$router.push({path: '/404'})
        }
      })()
        
    },
    methods: {
      discard() {
        this.$router.back()
      },
      async saveTeam() { 
        
        await this.editTeam( this.newDrivers ) 
        this.$router.push({path: '/teams'})
      },
      ...mapActions('drivers', ['getDrivers']),
      ...mapActions('user', ['getUsers']),
      ...mapActions('team', ['editTeam', 'getTeams', 'getEditingTeam']),
      
      setAvailableDrivers () {
        this.availableDrivers = this.drivers.filter ( driver => {
          return !this.editingTeam.drivers.map( d => d._id ).includes ( driver._id )
        })
      }
    }
  }
</script>
