import axios from '@/plugins/axios'

const state = {
    messages: [],
    message: {}
}

const getters = {
    messages(state) {
        return state.messages
    }
}

const actions = {
    async getMessages({commit}) {
        let messagesResponse = await axios.get('/api/messages')
        commit('GET_MESSAGES', messagesResponse.data.data)
    },
    async getMessage({commit}, id) {
        let messageResponse = await axios.get(`/api/message/${id}`)
        commit('GET_MESSAGE', messageResponse.data.data)
    },
    async deleteMessage({commit, dispatch}, id) {
        await axios.get(`/api/message/${id}/delete`)
        commit('DELETE_MESSAGE')
        dispatch('getMessages')
    },
    async markMessageViewed({dispatch, state}, id) {
        await axios.post(`/api/message/${id}`, Object.assign(state.message, {viewed: true}))
        dispatch('getMessages')
    }
}

// mutations
const mutations = {
    GET_MESSAGES(state, payload) {
        state.messages = payload
    },
    GET_MESSAGE(state, payload) {
        state.message = payload
    },
    DELETE_MESSAGE(state) {
        state.message = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}