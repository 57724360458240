<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md10 offset-md1 lg8 offset-lg2>
      <v-card outlined>
        <v-row>
          <v-col cols="12">
            <v-btn
              text
              color="blue"
              :to="`/season/${season._id}?tab=register`" 
            >
              <v-icon>mdi-chevron-left</v-icon>
              {{season.title}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title class="font-weight-black text-h4 text-uppercase mb-0">
              Editează înregistrarea
            </v-card-title>
            <v-card-subtitle>
              Pentru a confirma modificările făcute folosește butonul "Salvează" de la finalul formularului.
            </v-card-subtitle>
          </v-col>
        </v-row>
          <v-card-text v-if="season.rules" outlined>
            <v-form ref="form">
                  <v-autocomplete
                    :items="userAvailableTeams"
                    label="Club"
                    v-model="currentTeamId"
                    @change="teamChanged()"
                  ></v-autocomplete>

                  <template v-if="currentTeamId">
                    <v-card outlined class="mb-5" v-for="(car, index) in seasonRegistration.cars" :key="index">
                      <v-card-title
                        class="font-weight-black text-h5 text-uppercase transition-swing"
                      >
                        <kbd v-for="carClass in seasonRegistration.cars[index].classes" :key="carClass._id" class="mr-1" color="black">{{carClass.acronym}}</kbd><span v-if="car.raceNumber">#{{car.raceNumber}} {{car.carName}}</span> 
                        <v-btn
                          @click="removeCurrentRegistrationCar(index)"
                          color="error"
                          v-if="!seasonRegistration.cars[index]._id"
                          class="px-0"
                          text
                          min-width="40px"
                        >
                          <v-icon class="mx-0">mdi-delete-empty</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-subtitle justify="center">
                        <v-icon left style="vertical-align: bottom; line-height: 32px;">mdi-information</v-icon><span class="d-inline-block" style="vertical-align: bottom; line-height: 30px">Înscrierile efectuate nu pot fi editate sau șterse. Lista piloților din echipaj poate fi schimbată în orice moment.</span>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                            <v-autocomplete
                              :items="carModelsList"
                              label="Tipul masinii"
                              v-model="seasonRegistration.cars[index].carModel"
                              :readonly="!canChangeCarModel && !isCarNew(index)"
                              @change="updateCarClasses(index)"
                            ></v-autocomplete>
                            <v-text-field
                              label="Numele echipajului*"
                              required
                              v-model="car.carName"
                              :readonly="!!seasonRegistration.cars[index]._id"
                              :rules="[carNameRules]"
                              validate-on-blur
                              @update:error="syncErrors"
                            ></v-text-field>
                            <v-autocomplete
                              :items="validNumbersForCar(seasonRegistration.cars[index])"
                              label="Număr de concurs*"
                              v-model="seasonRegistration.cars[index].raceNumber"
                              :rules="[carNumberRules]"
                              :readonly="!!seasonRegistration.cars[index]._id"
                              validate-on-blur
                              @update:error="syncErrors"
                            >
                            </v-autocomplete>
                            <v-text-field
                              v-model="seasonRegistration.cars[index].skin"
                              label="Livery mașină (URL)"
                              class="d-none"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col class="pt-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                            <v-row>
                              <v-col cols="12">
                                <span class="text-subtitle-1">Piloți</span>
                                <v-menu
                                  :close-on-content-click="car.drivers.length >= season.rules.maxDriversPerCar"
                                  offset-y
                                  v-model="driverMenu[index]"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      :color="car.drivers.length < season.rules.minDriversPerCar ? 'red' : 'primary'"
                                      :dark="car.drivers.length < season.rules.maxDriversPerCar"
                                      v-bind="attrs"
                                      v-on="on"
                                      :disabled="car.drivers.length >= season.rules.maxDriversPerCar"
                                      class="ml-4 px-0"
                                    >
                                      <v-icon>mdi-racing-helmet</v-icon><v-icon right small>mdi-plus</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-card max-width="500px">
                                    <template v-if="car.drivers.length < season.rules.maxDriversPerCar">
                                      <v-card-title>Piloți disponibili</v-card-title>
                                      <v-card-subtitle>{{seasonRegistration.team.name}}</v-card-subtitle>
                                    </template>
                                    <template v-else>
                                      <v-card-title>Nu se mai pot adăuga piloți</v-card-title>
                                      <v-card-subtitle>A fost atins numărul maxim de piloți pentru aceasta mașină</v-card-subtitle>
                                    </template>
                                    <v-card-text>
                                      <v-list
                                        style="max-height: 300px"
                                        class="overflow-y-auto"
                                      >
                                        <v-list-item v-for="driver in selectableDrivers[car._id]" :key="driver._id">
                                          <v-list-item-action>
                                            <v-btn
                                              color="green"
                                              :dark="!driverMissingFields(driver).length"
                                              @click="addDriver(index, driver)"
                                              min-width="36px"
                                              class="px-0"
                                              :disabled="!!driverMissingFields(driver).length"
                                            >
                                              <v-icon class="mx-0">mdi-plus</v-icon>
                                            </v-btn>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>{{driver.profile.firstName}} {{driver.profile.lastName}}</v-list-item-title>
                                            <v-list-item-subtitle>
                                              {{printLocation(driver.profile.country, driver.profile.city)}}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="driverMissingFields(driver).length">
                                              Pilotul nu poate fi înregistrat fără {{driverMissingFields(driver).join(', ')}}
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card-text>
                                  </v-card>
                                </v-menu>

                              </v-col>
                              <v-col class="pt-0" :cols="12">
                                <span class="text-caption" v-if="season.rules.maxDriversPerCar > 1">
                                  <v-icon small class="mr-2">mdi-information</v-icon>
                                  Ordinea poate fi schimbată prin drag-and-drop
                                </span>
                                <v-input type="hidden" :value="car.drivers" :rules="[pilotsRules]"></v-input>
                                <v-list two-line>
                                  <draggable
                                    v-model="seasonRegistration.cars[index].drivers"
                                    v-bind="dragDriversChipOptions"
                                    v-if="seasonRegistration.cars[index].availableDrivers"
                                    handle=".handle"
                                  >
                                    <template v-for="(ditem, dindex) in seasonRegistration.cars[index].drivers">
                                      <v-list-item :key="dindex">
                                        <v-list-item-action class="mr-4">
                                          <div class="handle">
                                            <v-icon>mdi-drag-horizontal</v-icon>
                                          </div>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content :class="driverClassRules(seasonRegistration.cars[index].drivers[dindex].classes) !== true ? 'red--text' : ''">
                                          <v-list-item-title>
                                            <kbd v-for="driverClass in seasonRegistration.cars[index].drivers[dindex].classes" :key="driverClass._id" class="mr-1" color="black">{{driverClass.acronym}}</kbd>{{ditem.profile.firstName}} {{ditem.profile.lastName}}
                                          </v-list-item-title>
                                          <v-list-item-subtitle v-if="driverClassRules(seasonRegistration.cars[index].drivers[dindex].classes) !== true" class="red--text">
                                            Selectează cel puțin o licență.
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle v-if="car.drivers.length > 1 && dindex == 0">
                                            Se va conecta primul la server
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-row>
                                            <v-menu
                                              v-model="driverDetailsMenu[index][dindex]"
                                              :close-on-content-click="false"
                                              eager
                                            >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn min-width="20" icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                                              </template>
                                              
                                              <v-card>
                                                <v-list>
                                                  <v-list-item>
                                                    <v-list-item-avatar>
                                                      <v-avatar color="primary">
                                                        <span class="white--text text-h6">{{ditem.profile.firstName[0]}}{{ditem.profile.lastName[0]}}</span>
                                                      </v-avatar>
                                                    </v-list-item-avatar>
                                                    
                                                    <v-list-item-content>
                                                      <v-list-item-title>{{ditem.profile.firstName}} {{ditem.profile.lastName}}</v-list-item-title>
                                                      <v-list-item-subtitle>{{printLocation(ditem.profile.country, ditem.profile.city)}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    
                                                    <v-list-item-action>
                                                      <v-btn min-width="20" dark color="red" text class="px-2" @click="removeDriver(index, ditem._id)"><v-icon>mdi-delete-empty</v-icon></v-btn>
                                                    </v-list-item-action>
                                                  </v-list-item>
                                                </v-list>
                                                
                                                <v-divider></v-divider>
                                                
                                                <v-select
                                                  v-model="seasonRegistration.cars[index].drivers[dindex].classes"
                                                  item-text="obj.name"
                                                  item-value="obj"
                                                  label="Licențele pilotului"
                                                  multiple
                                                  :items="season.driverClasses"
                                                  class="mx-4 mt-4"
                                                  :rules="[driverClassRules]"
                                                  dense
                                                  @change="updateDriverClasses(index)"
                                                  :value-comparator="driverClassComparator"
                                                  :disabled="!isDriverNewInSeason(ditem._id)"
                                                >
                                                  <template v-slot:selection="{ item }">
                                                    <kbd class="mr-1">{{item.obj.acronym}}</kbd>
                                                  </template>
                                                  
                                                  <template v-slot:item="{active, item, attrs, on}">
                                                    <v-list-item v-on="on" v-bind="attrs" #default="{active}" :disabled="!driverClassSelectable(index, dindex, item.group, item)">
                                                      <v-list-item-action>
                                                        <v-checkbox :input-value="active"></v-checkbox>
                                                      </v-list-item-action>
                                                      <v-list-item-content>
                                                        <v-list-item-title>
                                                          <v-row no-gutters align="center">
                                                            <span>{{item.obj.name}}</span>
                                                            <v-spacer></v-spacer>
                                                            <kbd class="mr-1">{{item.obj.acronym}}</kbd>
                                                          </v-row>
                                                        </v-list-item-title>
                                                      </v-list-item-content>
                                                    </v-list-item>
                                                  </template>
                                                </v-select>
                                                
                                                <v-card-title></v-card-title>
                                              </v-card>
                                            </v-menu>
                                          </v-row>
                                        </v-list-item-action>
                                      </v-list-item>
                                    </template>
                                  </draggable>
                                </v-list>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
            </v-form>
          </v-card-text>
          <span class="text--secondary text-subtitle-2 ml-2 d-inline-block" v-if="!this.canAddCar">Numărul maxim de mașini per club a fost atins.</span>
          <span class="text--secondary text-subtitle-2 ml-2 d-inline-block" v-if="!canRegisterNewCar">Înscrierile nu mai sunt permise.</span>
          <span class="text--secondary text-subtitle-2 ml-2 d-inline-block" v-if="!enoughDriversForNewCar && seasonRegistration.team._id">Clubul nu are suficienți piloți pentru a înscrie altă mașină.</span>
          <v-card-actions>
            <v-btn @click="addCurrentRegistrationCar()" :disabled="!this.canAddCar || !canRegisterNewCar || !enoughDriversForNewCar">
              <v-icon>mdi-plus</v-icon> Adaugă o masină
            </v-btn>
            <v-btn color="blue" dark @click="registerTeamForSeason" :disabled="!seasonRegistration.cars || seasonRegistration.cars.length < 1">
              <v-icon>mdi-check</v-icon> Salvează
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import countryData from '@/plugins/vuetify-country-region-input/data';
import axios from '@/plugins/axios';

export default {
  data: () => {
    return {
      registrationHasError: false,
      inset: true,
      userTeamRegistrations: [],
      userAvailableTeams: [],
      carsUserDriving: [],
      nonTeamDrivers: [],
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1
      },
      driverMenu: [],
      countries: {},
      currentTeamId: undefined,
      driverDetailsMenu: []
    };
  },
  methods: {
    ...mapActions("season", [
      "getSeason",
      "getSeasonFromSeasonRegistration",
      "getSeasonRegistration",
      "getCarModelsList",
      "getSeasonValidCarNumbersList",
      "getSeasonRegistration",
      "getSeasonTeamRegistration",
      "getUserSeasonClasses"
    ]),
    validNumbersForCar (car) {
      return this.validNumbersList.filter(raceNumber => {
        return !this.seasonRegistration.cars.map(car => car.raceNumber).includes(raceNumber);
      }).concat([car.raceNumber]);
    },
    driverClassSelectable (carIndex, driverIndex, classGroup, classObj) {
      let classesInGroup = this.season.driverClasses.filter(dc=>dc.group==classGroup);
      let driverClassesInGroup = classesInGroup.filter(c => {
        return this.seasonRegistration.cars[carIndex].drivers[driverIndex].classes
          .map(dc=>dc._id.toString())
          .includes(c.obj._id.toString())
      }).length
      
      return (driverClassesInGroup < 1 || this.seasonRegistration.cars[carIndex].drivers[driverIndex].classes
          .map(dc=>dc._id.toString())
          .includes(classObj.obj._id.toString())
        )
    },
    isCarNew(carIndex) {
      return this.seasonRegistration.cars[carIndex]._id === undefined;
    },
    isDriverNewInSeason(driverId) {
      return !this.userSeasonClasses.filter(usc=>usc.user==driverId).length;
    },
    driverClassComparator(a, b) {
      return a._id.toString() == b._id.toString();
    },
    driverMissingFields (driver) {
      const missingFields = this.season.rules.simulator.requiredDriverFields.filter(field => {
        return driver.profile[field] === undefined;
      })

      return missingFields;
    },
    consoleLog(obj) {
      console.log(obj);
    },
    ...mapActions("team", ["getTeamsList", "getDriversList", "getTeamsUserCanManage", "getTeamsUserOwns"]),
    ...mapActions("app", ["showSnackbar"]),
    async teamChanged () {
      await this.getSeasonTeamRegistration({seasonId: this.season._id, teamId: this.currentTeamId});
      
      await this.setAvailableDrivers();
    },
    updateDriverClasses(carIndex) {
      // Prettify class order:
      this.seasonRegistration.cars[carIndex].drivers = this.seasonRegistration.cars[carIndex].drivers.map(driver => {
        driver.classes = driver.classes.sort((a,b) => {
          let aGroup = this.season.driverClasses
            .find(cls => cls.obj._id.toString() == a._id.toString())
            .group;

          let bGroup = this.season.driverClasses
            .find(cls => cls.obj._id.toString() == b._id.toString())
            .group;

          if (aGroup != bGroup) {
            return aGroup < bGroup ? -1 : 1;
          }

          return a.name.localeCompare(b.name);
        })

        return driver;
      });

      this.updateCarClasses(carIndex);
    },
    async updateCarClasses(carIndex) {
      let carClassesResponse = await axios.post (
        `/api/season/${this.season._id}/carClassesFromDrivers`,
        {
          driversClasses: this.seasonRegistration.cars[carIndex].drivers.map(d=>d.classes.map(c=>c._id.toString())),
          carId: this.seasonRegistration.cars[carIndex]._id,
          car: this.seasonRegistration.cars[carIndex]
        }
      )
      
      this.seasonRegistration.cars[carIndex].classes = carClassesResponse.data.data;
      this.$forceUpdate();
    },
    printCountry (countryCode) {
      try {
        return this.countries[countryCode].name;
      } catch {
        return '';
      }
    },
    printRegion (countryCode, regionCode) {
      try {
        return this.countries[countryCode].regions[regionCode].name;
      } catch {
        return '';
      }
    },
    printLocation (countryCode, regionCode) {
      let country = this.printCountry (countryCode);
      let region = this.printRegion (countryCode, regionCode);
      
      let text = country;
      
      if ( text != '' && region != '' ) {
        text += ', ';
      }
      
      text += region;
      
      return text;
    },
    showDriverMenu (carIdx) {
      this.driverMenu[carIdx] = true;
    },
    closeDriverMenu (carIdx) {
      this.driverMenu[carIdx] = false;
    },
    addDriver (carIndex, driver) {
      this.seasonRegistration.cars[carIndex].drivers.push (driver);
      
      this.setAvailableDrivers();
      this.updateCarClasses(carIndex);
    },
    removeDriver (carIndex, driverId) {
      const index = this.seasonRegistration.cars[carIndex].drivers.map(d=>d._id).indexOf(driverId)
      if (index >= 0) {
        this.seasonRegistration.cars[carIndex].drivers.splice(index, 1)
        this.setAvailableDrivers()
        this.updateCarClasses(carIndex);
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getEvents() {
      this.$http.get("/api/events").then((events) => {
        this.events = events.map((event) => {
          let eventDuration = event.practice + event.qualify + event.race + (event.raceInverted ? event.race : 0);
          let eventEnd = event.date + eventDuration;
          return {
            name: event.title,
            start: event.date,
            end: eventEnd,
            color: "red",
          };
        });
      });
    },
    addCurrentRegistrationCar() {
      if (this.seasonRegistration.cars.length < this.season.rules.maxCarsPerTeam) {
        let newCar = {
          carModel: "",
          carName: "",
          raceNumber: "",
          drivers: [],
          availableDrivers: [],
          team: ""
        };
        
        this.seasonRegistration.cars.push(newCar);
        this.setAvailableDrivers();
      }
    },
    async removeCurrentRegistrationCar(removedCarIndex) {
      let newCars = [];

      this.seasonRegistration.cars.forEach((car, carIndex) => {
        if (carIndex != removedCarIndex) {
          newCars.push(car);
        }
      });

      this.seasonRegistration.cars = newCars;

      await this.setAvailableDrivers();
    },
    async getCurrentRegistration(reload = false) {
      if (!this.seasonRegistration.cars || reload) {
        this.seasonRegistration.cars = [];
        this.seasonRegistration.teamId = "";
      }
      // eslint-disable-next-line no-constant-condition
      while (this.seasonRegistration.cars.length < this.season.rules.minCarsPerTeam && false) {
        let newCar = {
          carModel: "",
          drivers: [],
          carName: "",
          raceNumber: "",
          availableDrivers: this.driversList || [],
        };
        this.seasonRegistration.cars.push(newCar);
      }
    },
    async setAvailableDrivers() {
      let teamDrivers = [];

      if ( this.seasonRegistration.team._id ) {
        await this.getDriversList({
          teamId: this.seasonRegistration.team._id.toString()
        });

        teamDrivers = this.driversList.map((driver) => {
          (driver.text = driver.profile.firstName + " " + driver.profile.lastName), (driver.value = driver._id);

          const driverIsInCar = this.seasonRegistration.cars.filter(car => car.drivers.map(d => d._id).includes(driver._id)).length > 0

          if ( driverIsInCar ) {
            this.seasonRegistration.cars.forEach(car => {
              const driverInCar = car.drivers.find(d => d._id ==  driver._id)

              if (driverInCar !== undefined) {
                driver.classes = driverInCar.classes
              }
            })
          } else {
            let driverClasses = this.userSeasonClasses
              .filter(usc => usc.user == driver._id)
              .map(usc => usc.classes)
              .flat();

            (driver.classes = driverClasses)
          }

          return driver;
        });
      }

      let newCars = [];

      this.seasonRegistration.cars.forEach((car) => {
        if (car.drivers.length == this.season.rules.maxDriversPerCar) {
          car.availableDrivers = teamDrivers.filter((teamDriver) => {
            return car.drivers.map(d=>d._id).includes(teamDriver._id);
          });
          
          // Just in case the car has a driver which is no longer
          // a member of the club, we should add him/her to the list:
          let carNonTeamDrivers = this.nonTeamDrivers.filter ( nonTeamDriver => {
            return (car.drivers.map(d=>d._id).includes(nonTeamDriver._id))
          }).map(driver => {
            // (driver.text = driver.profile.firstName + " " + driver.profile.lastName), (driver.value = driver._id);
            driver.text = "N/A";
            driver.value=driver._id;

            return driver;
          });
          
          car.availableDrivers = car.availableDrivers.concat ( carNonTeamDrivers );
          
        } else {
          let driversToBeRemoved = [];

          this.seasonRegistration.cars.forEach((otherCar) => {
            let carDriverIds = otherCar.drivers.map((driver) => {
              return driver._id;
            });
            driversToBeRemoved = [...driversToBeRemoved, ...carDriverIds];
          });

          car.availableDrivers = teamDrivers.filter((teamDriver) => !driversToBeRemoved.includes(teamDriver._id));
          
          // Just in case the car has a driver which is no longer
          // a member of the club, we should add him/her to the list.
          //
          // This is also needed even if the car is not full,
          // as there might be more than one driver in this situation.
          let carNonTeamDrivers = this.nonTeamDrivers.filter ( nonTeamDriver => {
            return (car.drivers.map(d=>d._id).includes(nonTeamDriver._id))
          }).map(driver => {
            // (driver.text = driver.profile.firstName + " " + driver.profile.lastName), (driver.value = driver._id);
            driver.text = "N/A";
            driver.value=driver._id;
            return driver;
          });
          
          car.availableDrivers = car.availableDrivers.concat ( carNonTeamDrivers );
        }
        
        car.availableDrivers = car.availableDrivers.map(d => {
          let driverClasses = this.userSeasonClasses
            .filter(usc => usc.user == d._id)
            .map(usc => usc.classes)
            .flat();
            
          (d.classes = driverClasses)

          return d;
        })
        
        car.drivers = car.drivers.map(d => {
          let driverClasses = this.userSeasonClasses
            .filter(usc => usc.user == d._id)
            .map(usc => usc.classes)
            .flat();
            
          // Check for the possibility of driver not being registered in the season
          // just yet. So if there's no record of it, don't reset classes to [].
          if ( driverClasses.length > 0 ) {
            (d.classes = driverClasses)
          }

          return d;
        })

        newCars.push(car);
        
      });
      
      this.driverDetailsMenu = new Array(newCars.length).fill([]);
      newCars.forEach ( (car, index) => {
        this.driverDetailsMenu[index] = new Array(car.drivers.length).fill(false);
      })

      this.seasonRegistration.cars = newCars;
    },
    clearDriversList() {
      this.seasonRegistration.cars = this.seasonRegistration.cars.map((car) => {
        car.drivers = [];
        return car;
      });
    },
    async registerTeamForSeason() {
      let formValid = this.$refs.form.validate();

      if (!formValid) {
        this.showSnackbar({
          timeout: 7000,
          content: "Formularul de inscriere contine erori",
          color: "error",
          value: true,
        });

        return;
      }

      if (!this.registrationHasError) {
        let newRegistration = await this.$http.post(
          `/api/season/${this.season._id}/editRegistration/${this.seasonRegistration._id}`,
          this.seasonRegistration
        );
        this.showSnackbar({
          timeout: 7000,
          content: newRegistration.data.data,
          color: newRegistration.data.status == "200" ? "success" : "error",
          value: true,
        });
        await this.getCurrentRegistration(true);
        this.$router.push({ path: `/season/${this.season._id}?tab=register` });
      } else {
        this.showSnackbar({
          timeout: 7000,
          content: "Formularul de inscriere contine erori",
          color: "error",
          value: true,
        });
      }
    },

    printDate(date) {
      let m = new Date(date);

      let result =
        m.getUTCFullYear() + "-" + ("00" + (m.getUTCMonth() + 1)).slice(-2) + "-" + ("00" + m.getUTCDate()).slice(-2);

      result += " @ " + ("00" + m.getHours()).slice(-2) + ":" + ("00" + m.getMinutes()).slice(-2);

      return result;
    },
    setUserRegisteredTeams() {
      this.userTeamRegistrations = [];

      this.season.registrations.forEach((registration) => {
        if (
          registration.initiatedByUser == this.userinfo.id ||
          registration.team.managers.includes(this.userinfo.id) ||
          registration.team.owner == this.userinfo.id
        ) {
          this.userTeamRegistrations.push(registration);
        }
      });
    },
    setUserAvailableTeams() {
        this.userAvailableTeams = this.teamsUserOwns.concat(
          this.teamsUserCanManage.filter(team => {
            return (team.owner._id != this.userinfo.id)
          })
        ).filter(team => {
           return team.simulator._id.toString() == this.season.rules.simulator._id.toString()
        }).map(team => {
          team.text = team.name,
          team.value = team._id
          return team
        })
      },
    setCarsUserDriving() {
      let cars = [];
      this.season.registrations.forEach((registration) => {
        registration.cars.forEach((car) => {
          if (
            car.drivers
              .map((driver) => {
                return driver.driver;
              })
              .includes(this.userinfo.id)
          ) {
            cars.push(car);
          }
        });
      });

      this.carsUserDriving = cars;
    },
    pilotsRules(input) {
      let pilotPiloti = this.season.rules.maxDriversPerCar == 1 ? 'pilot' : 'piloți'
      let pilotiPerMasina =
        this.season.rules.maxDriversPerCar == this.season.rules.minDriversPerCar
          ? this.season.rules.maxDriversPerCar
          : `${this.season.rules.maxDriversPerCar} până la ${this.season.rules.maxDriversPerCar}`;
      return (
        (input.length <= this.season.rules.maxDriversPerCar && input.length >= this.season.rules.minDriversPerCar) ||
        `Selectează ${pilotiPerMasina} ${pilotPiloti}`
      );
    },
    driverClassRules(input) {
      // Class group 0 is mandatory!
      let mandatoryClass = this.season.driverClasses.filter(cls => cls.group == 0).length > 0;

      let criteriaOk = true;

      if ( mandatoryClass ) {
        criteriaOk = input.filter( drvCls => {
          return this.season.driverClasses
            .filter(cls => cls.group == 0)
            .map(cls => cls.obj._id.toString())
            .includes (drvCls._id.toString());

        }).length > 0;
      }

      return criteriaOk || "Selectează cel puțin o licență";
    },
    carNumberRules(input) {
      return (input && !isNaN(input) && !isNaN(parseFloat(input))) || "Alege un număr pentru mașină";
    },
    carNameRules(input) {
      return (input.length > 0 && input.trim().length > 0) || "Alege numele echipajului";
    },
    syncErrors(errorValue) {
      this.registrationHasError = errorValue;
    },
    async getNonTeamDrivers() {
      this.nonTeamDrivers = [];
      
      // Get array of team drivers' IDs:
      let teamDrivers = this.seasonRegistration.team.drivers.map (driver => driver._id);
      
      // Check if there's any non-team driver in cars:
      this.nonTeamDrivers = await Promise.all(
        await Promise.all ( this.seasonRegistration.cars.map(async car => {
          return await Promise.all(car.drivers.map(driver => {
            
            if (!teamDrivers.includes(driver._id)) {
              return this.$http.get(`/api/driver-user/${driver._id}`)
            }
          }).filter(Boolean));
        }))
      );
      this.nonTeamDrivers = this.nonTeamDrivers.reduce((a, b) => a.concat(b), []).map(response => response.data.data)
    },
    onMove: function(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      }
    },
    onDriversChange: function(index, value) {
      if (value.removed) {
        // insert
        this.seasonRegistration.cars[index].drivers.splice(this.dragged.to+this.dragged.newIndex,0, this.seasonRegistration.cars[index].drivers[this.dragged.from])
        // delete
        if (this.dragged.from < this.dragged.to) // LTR
          this.seasonRegistration.cars[index].drivers.splice(this.dragged.from, 1)
        else // RTL
          this.seasonRegistration.cars[index].drivers.splice(this.dragged.from + 1, 1)
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["userinfo", "isLoggedIn"]),
    ...mapState("season", ["season", "carModelsList", "validNumbersList", "seasonRegistration", "userSeasonClasses"]),
    ...mapState("team", ["teamsList", "driversList", "teamsUserCanManage", "teamsUserOwns", "simulators"]),
    canChangeCarModel() {
      return this.season.rules.carModelChangeAllowed && (Date.parse(this.season.deadlines.carModelChange) > Date.now())
    },
    canRegisterNewCar() {
      if ( !this.season.deadlines )
        return true;

      if ( !this.season.deadlines.registration )
        return true;

      return (Date.parse(this.season.deadlines.registration) > Date.now())
    },
    selectableDrivers() {
      let selectableDrivers = {}
      this.seasonRegistration.cars.forEach (car => {
        if (car.availableDrivers) {
          selectableDrivers[car._id] = car.availableDrivers.filter(driver => {
            return !car.drivers.includes(driver._id);
          })
        }
      });
      
      return selectableDrivers;
    },
    selectedDrivers() {
      let selectedDrivers = {}
      this.seasonRegistration.cars.forEach (car => {
        selectedDrivers[car._id] = car.availableDrivers.filter(driver => {
          return car.drivers.includes(driver._id);
        })
      });
      
      return selectedDrivers;
    },
    enoughDriversForNewCar() {
      if (!this.season.rules) {
        return false;
      }

      let numTeamDrivers = this.seasonRegistration.team.drivers.length;

      let signedDrivers = [];
      this.seasonRegistration.cars.forEach((car) => {
        signedDrivers = [...signedDrivers, ...car.drivers];
      });

      let numFreeDrivers = numTeamDrivers - signedDrivers.length;

      let numDriversCriteria = numFreeDrivers >= this.season.rules.minDriversPerCar;

      return numDriversCriteria;
    },
    canAddCar() {
      if (!this.season.rules) {
        return false;
      }
      let numCarsCriteria = this.seasonRegistration.cars.length < this.season.rules.maxCarsPerTeam;

      return numCarsCriteria;
    },
    tab: {
      set(tab) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            tab,
          },
        });
      },
      get() {
        if (this.$route.query.tab) return this.$route.query.tab;
        return "info";
      },
    },
    dragDriversChipOptions() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true
      };
    },
  },
  async mounted() {
    countryData.forEach( country => {
      let regionsHashmap = {};
      country.regions.forEach ( region => {
        regionsHashmap[region.shortCode] = {name: region.name};
      });
      
      this.countries[country.countryShortCode] = {
        name: country.countryName,
        regions: regionsHashmap
      }
    });
    
    await this.getTeamsList();
    await this.getTeamsUserOwns(this.userinfo.id);
    await this.getTeamsUserCanManage(this.userinfo.id);
    
    if ( this.$route.params.season_id ) {
      await this.getSeason ( this.$route.params.season_id );
    }
    
    await this.getUserSeasonClasses( this.$route.params.season_id );
    
    if ( this.$route.params.registration_id ) {
      await this.getSeasonRegistration(this.$route.params.registration_id);
      this.currentTeamId = this.seasonRegistration.team._id
      this.setAvailableDrivers();
    }
    
    await this.getCarModelsList(this.season._id);
    await this.getSeasonValidCarNumbersList(this.season._id);
    await this.getCurrentRegistration();
    

    /*
    this.seasonRegistration.cars.forEach (car => {
      this.driverMenu.push(false);
    });
    */

    for (let i = 0; i < this.season.maxCarsPerTeam; i++) {
      this.driverMenu.push(false);
    }
    
    await this.setUserRegisteredTeams();
    await this.getNonTeamDrivers();    
    await this.setUserAvailableTeams();
    await this.setCarsUserDriving();

    await this.setAvailableDrivers({
      carId: null,
      driverId: null,
    });
  }
};
</script>
