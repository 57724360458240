import axios from '@/plugins/axios'

const state = {
    seasons: [],
    season: {
        rules: {
            simulator: {},
            maxCarsPerTeam: 0,
            minCarsPerTeam: 0,
            maxDriversPerCar: 0,
            minDriversPerCar: 0,
            validCars: []
        },
        permissions: {
          editRaceResults: [],
          editStandings: []
        },
        standings: {},
        events: [],
        deadlines: {
            registration: undefined,
            carModelChange: undefined
        }
    },
    seasonRegistration: {
      cars: [],
      team: {
        drivers: []
      }
    },
    carModelsList: [],
    validNumbersList: [],
    userSeasonClasses: []
}

const getters = {
    seasons(state) {
        return state.seasons
    },
    season(state) {
        return state.season
    },
    carModelsList(state) {
        return state.carModelsList
    }
}

const actions = {
    async getSeason({
        commit
    }, seasonId) {
        let seasonsResponse = await axios({
            url: `/api/season/${seasonId}`,
            method: 'GET'
        })

        commit('GET_SEASON', seasonsResponse.data.data);
    },
    async getSeasonFromSeasonRegistration({
        commit, dispatch
    }, seasonRegistrationId) {
      if ( !state.seasons.length ) {
        await dispatch ('getSeasons')
      }
      
      state.seasons.forEach ( season => {
        if ( season.registrations.map ( registration => {
          return registration._id
        }).includes(seasonRegistrationId) ) {
          commit('GET_SEASON', season)
          return
        }
      })
    },
    async getSeasonRegistration({
        commit
    }, seasonRegistrationId) {
        let seasonsResponse = await axios({
            url: `/api/season-registration/${seasonRegistrationId}`,
            method: 'GET'
        })

        commit('GET_SEASON_REGISTRATION', seasonsResponse.data.data)
    },
    async getSeasonTeamRegistration({
      commit
    }, options) {
        let seasonsResponse = await axios({
            url: `/api/season-team-registration/${options.seasonId}/${options.teamId}`,
            method: 'GET'
        })

        commit('GET_SEASON_REGISTRATION', seasonsResponse.data.data)
    },
    async getSeasons({
        commit
    }) {
        let seasonsResponse = await axios({
            url: '/api/seasons',
            method: 'GET'
        })

        commit('GET_SEASONS', seasonsResponse.data.data)
    },
    async addSeason({
        commit
    }, season) {
        let seasonsResponse = await axios({
            url: '/api/seasons/',
            method: 'POST',
            data: season
        })
        commit('GET_SEASONS', seasonsResponse.data.data)
    },
    async editSeason({
        commit,
        state
    }) {
        let seasonsResponse = await axios({
            url: `/api/season/${state.season._id}`,
            method: 'POST',
            data: state.season
        })
        commit('GET_SEASONS', seasonsResponse.data.data)
    },
    async deleteSeason({
        commit
    }, season) {
        let seasonsResponse = await axios({
            url: `/api/season/${season.id}/delete`,
            method: 'POST',
            data: season
        })
        commit('GET_SEASONS', seasonsResponse.data.data)
    },
    async getseason({
        commit,
        state
    }, seasonId) {
        let season = state.seasons.filter(season => {
            return season._id == seasonId
        })[0]
        commit('GET_VIEWING_SEASON', season)
    },
    async getCarModelsList({
        commit
    }, seasonId) {
        let carModelsResponse = await axios({
            url: `/api/season/${seasonId}/carModelsList`,
            method: 'GET'
        })
        
        let validCars = carModelsResponse.data.data.rules.validCars.map(car => {
            car.text = car.title
            car.value = car._id
            return car
        })
        commit('GET_CARMODELS_LIST', validCars)
    },
    async getSeasonValidCarNumbersList({
        commit
    }, seasonId) {
        let validNumbersResponse = await axios({
            url: `/api/season/${seasonId}/carNumbersList`,
            method: 'GET'
        })
        commit('GET_VALID_NUMBERS_LIST', validNumbersResponse.data.data)
    },
    async getUserSeasonClasses({
        commit
    }, seasonId) {
        let userClassesResponse = await axios({
            url: `/api/season/${seasonId}/userClasses`,
            method: 'GET'
        })
        commit('GET_USER_CLASSES', userClassesResponse.data.data)
    },
    async getSeasonStandings({
        commit
    }, seasonId) {
        let standingsResponse = await axios({
            url: `/api/season/${seasonId}/standings`,
            method: 'GET'
        })

        commit('GET_SEASON_STANDINGS', standingsResponse.data.data);
    }
    
}

// mutations
const mutations = {
    GET_SEASONS(state, payload) {
        state.seasons = payload
        state.season = {}
        state.season = {}
    },
    GET_SEASON(state, payload) {
        state.season = payload
    },
    GET_SEASON_REGISTRATION(state, payload) {
        state.seasonRegistration = payload
    },
    GET_VIEWING_SEASON(state, payload) {
        state.season = payload
    },
    GET_CARMODELS_LIST(state, payload) {
        state.carModelsList = payload
    },
    GET_VALID_NUMBERS_LIST(state, payload) {
        state.validNumbersList = payload
    },
    GET_USER_CLASSES(state, payload) {
        state.userSeasonClasses = payload
    },
    GET_SEASON_STANDINGS(state, payload) {
        state.season.standings = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

