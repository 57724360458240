<template>
  <v-layout>
  </v-layout>
</template>
<script>
export default {
  data: () => {
    return {}
  },
  async mounted() {
    await this.$http.get(`/auth/verify-email/${this.$route.params.timestamp}/${this.$route.params.token}`)
    this.$router.push({path: '/login'})
  }
}
</script>
