<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
    </v-flex>
  </v-layout>
</template>


<script>
  import {
    mapActions
  } from 'vuex'
  export default {
    pageTitle: 'Logout',
    components: {},
    data() {
      return {}
    },
    methods: {
      ...mapActions('auth', [
        'logout'
      ]),
    },
    async mounted() {
      await this.logout()
      this.$router.push({
        'path': '/'
      })

    }
  }
</script>