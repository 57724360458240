<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
      <v-form @submit.prevent="submit">
        <div
        class="text-center text-h2 transition-swing mb-10"
        v-text="'Resetează parola'"
        ></div>
        <v-text-field v-model="email" name="email" label="Email" required></v-text-field>
        <v-btn type="submit" class="mr-4">Resetează parola</v-btn>
      </v-form>
    </v-flex>
  </v-layout>
</template>


<script>
    import {mapActions} from 'vuex'
    export default {
        pageTitle: 'My Profile',
        components: {  },
        data () {
            return {
              email: ''
            }
        },
        methods: {
            ...mapActions('app',['showSnackbar']),
            async submit() {
              try {
                await this.$http.post('/auth/forgot-password', { email: this.email })
                this.showSnackbar({
                  timeout: 7000,
                  value: true,
                  color: 'success',
                  content: "Vei primi un email in cateva secunde",
                })
              } catch (err) {
                this.showSnackbar({
                  timeout: 7000,
                  value: true,
                  color: 'error',
                  content: "Ceva nu a mers bine, contacteaza organizatorii",
                })
              } 
            }
        }
    }
</script>
