import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import CurrentEvent from '../views/CurrentEvent.vue'
import LastEvent from '../views/LastEvent.vue'
import CurrentSeries from '../views/CurrentSeries.vue'
import Profile from '../views/Profile.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Register from '../views/Register.vue'
import Event from '../views/Event.vue'
import EditSessionResults from '../views/EditSessionResults.vue'
import Season from '../views/Season.vue'
import Seasons from '../views/Seasons.vue'
import EditSeasonRegistration from '../views/EditSeasonRegistration.vue'
import Teams from '../views/Teams.vue'
import AddTeam from '../views/AddTeam.vue'
import EditTeam from '../views/EditTeam.vue'
import TeamInvite from '../views/TeamInvite.vue'
import CarInvite from '../views/CarInvite.vue'
import MessageInbox from '../views/MessageInbox.vue'
import ViewMessage from '../views/ViewMessage.vue'
import Discord from '../views/Discord.vue'
import Steam from '../views/Steam.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import RegisterOk from '../views/RegisterOk.vue'

import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: CurrentSeries,
    meta: {
      auth: false
    }
  },
  {
    path: '/current-event',
    name: 'CurrentEvent',
    component: CurrentEvent,
    meta: {
      auth: false
    }
  },
  {
    path: '/last-event',
    name: 'LastEvent',
    component: LastEvent,
    meta: {
      auth: false
    }
  },
  {
    path: '/current-series',
    name: 'Current Series',
    component: CurrentSeries,
    meta: {
      auth: false
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      auth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      auth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: {
      auth: false
    }
  },
  {
    path: '/reset-password/:hash',
    name: 'Reset Password',
    component: ResetPassword,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/404',
    name: 'Four-Oh-Four',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FourOhFour.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/season/:id',
    name: 'Season',
    component: Season,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/season-registration/:season_id',
    name: 'SeasonRegistration',
    component: EditSeasonRegistration,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/season-registration/:season_id/:registration_id',
    name: 'EditSeasonRegistration',
    component: EditSeasonRegistration,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/seasons',
    name: 'Seasons',
    component: Seasons,
    meta: {
      auth: false
    }
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: Event,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/edit-session-results/:event/:session',
    name: 'EditSessionResults',
    component: EditSessionResults,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/teams',
    name: 'MyTeams',
    component: Teams,
    meta: {
      auth: true
    }
  },
  {
    path: '/teams/add',
    name: 'AddTeam',
    component: AddTeam,
    meta: {
      auth: true
    }
  },
  {
    path: '/teams/edit/:id',
    name: 'EditTeam',
    component: EditTeam,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/messages',
    name: 'MessageInbox',
    component: MessageInbox,
    meta: {
      auth: true
    }
  },
  {
    path: '/message/:id',
    name: 'ViewMessage',
    component: ViewMessage,
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/car-invite/:id',
    name: 'CarInvite',
    component: CarInvite,
    meta: {
      auth: true
    }
  },
  {
    path: '/team-invite/:id',
    name: 'TeamInvite',
    component: TeamInvite,
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/discord',
    name: 'Discord',
    component: Discord,
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/steam',
    name: 'Steam',
    component: Steam,
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/registerOk',
    name: 'RegisterOk',
    component: RegisterOk,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '/confirmEmail/:timestamp/:token',
    name: 'VerifyEmail',
    component: VerifyEmail,
    props: true,
    meta: {
      auth: false
    }
  },
  {
    path: '*',
    name: 'CatchAll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FourOhFour.vue'),
    meta: {
      auth: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
