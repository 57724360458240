<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md8 offset-md2 lg6 offset-lg3>
      <v-card class="pb-10">
        <v-btn text color="blue" :to="`/messages`" class="mt-10 ml-5"><v-icon>mdi-chevron-left</v-icon> Înapoi la mesaje</v-btn>
        <v-card-title class="text-h5 pt-10 pl-10">{{message.subject}}</v-card-title>
        <!-- v-subheader class="text-h6 pl-10 pr-10 mb-10">
          De la: {{message.from.profile.firstName}} {{message.from.profile.lastName}}
        </v-subheader -->
        <v-card-text class="body-1 pl-10 pr-10" v-html="message.messageBody"></v-card-text>
        <v-card-actions class="mr-10">
          <v-spacer></v-spacer>
          <v-dialog
            v-model="confirmDeleteDialog"
            persistent
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="error"
                v-bind="attrs"
                v-on="on"
              >
                Sterge
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                Stergi mesajul?
              </v-card-title>
              <v-card-text>Aceasta actiune este definitiva</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  
                  @click="confirmDeleteDialog = false"
                >
                  Nu
                </v-btn>
                <v-btn
                  color="error"
                  
                  @click="confirmDeleteMessage(message._id)"
                >
                  Da
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>  
  import {mapActions, mapState} from 'vuex'
  export default {
    data: () => {
      return {
        confirmDeleteDialog: false
      }
        
    },
    methods: {
      ...mapActions('messages', [
        'getMessage',
        'deleteMessage',
        'markMessageViewed'
      ]),
      confirmDeleteMessage(id) {
        this.deleteMessage(id)
        this.confirmDeleteDialog = false
        this.$router.back()
      }
    },
    computed: {
      ...mapState('messages', ['message'])
    },
    async mounted () {
      await this.getMessage(this.$route.params.id)
      await this.markMessageViewed(this.$route.params.id)
    }
  }
</script>
