<template>
            <v-card
              outlined
            >
              <v-img
                src="@/assets/gchallenge.jpg"
                :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
                gradient="to top right, rgba(0,0,0,.3), rgba(255,165,0,.3)"
                class="align-end"
              >
                <v-card-title class="white--text pb-0 d-block">
                  <p
                    :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
                    style="word-break: normal !important"
                  >
                    Logitech G Challenge 2021
                  </p>
                  <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">Assetto Corsa Competizione</p>
                  <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">Câșțigă un loc la marea finală Logitech G Challenge 2021 din Las Vegas! Câștigătorul va fi stabilit la finala offline, unde vor ajunge doar cei cei mai buni.</p>
                </v-card-title>
              </v-img>
              
              <v-card-text>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-list two-line class="py-0">
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-calendar-month</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                              <span>
                                16 august 2021 <v-icon small>mdi-arrow-right</v-icon> noiembrie 2021
                              </span>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                      </v-list>
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-car</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                            <span>
                              McLaren 720S GT3
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider inset></v-divider>
                        
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-trophy</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Marele premiu</v-list-item-title>
                            <span>
                              Loc la finala G Challenge 2021 în Las Vegas
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                        
                      </v-list>
                    </v-col>
                    
                    <v-col
                      cols="6"
                    >
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-timer</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Calificări</v-list-item-title>
                              <span>
                                2 time trial-uri, 3 curse de calificare
                              </span>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon>mdi-flag-checkered</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content class="py-0">
                            <v-list-item-title class="font-weight-bold">Finala</v-list-item-title>
                              <span>
                                Offline
                              </span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  color="green darken-1"
                  href="https://logitech.simrace.ro"
                >
                  Clasament <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>

</template>

<script>
export default {
    name:'LogitechGChallenge2021'
}
</script>
