<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
      <v-form @submit.prevent="submit">
          <div
            class="font-weight-black text-h4 text-center text-uppercase transition-swing mb-10"
            v-text="'Conectare'"
          ></div>
        <v-text-field model="email" name="email" label="Email" required autocomplete="username"></v-text-field>
        <v-text-field model="password" name="password" label="Parolă" type="password" required autocomplete="current-password"></v-text-field>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3">
            <v-btn type="submit" color="green" dark>Intră în cont <v-icon right>mdi-arrow-right</v-icon></v-btn>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 9">
            <v-btn color="red darken-1" :class="{'ml-2': !$vuetify.breakpoint.smAndDown}" text to="/forgot-password">Ai uitat parola?</v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3">
            <v-btn color="primary" text to="/register"><v-icon left>mdi-account-plus</v-icon> Nu ai cont? Înregistrează-te aici</v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          
          <v-col cols="8">
          </v-col>
        </v-row>
      </v-form>
    </v-flex>
  </v-layout>
</template>


<script>
import {mapActions} from 'vuex'
    export default {
        pageTitle: 'Login',
        components: {},
        data () {
            return {
                email: "",
                password: "",
            }
        },
        methods: {
          ...mapActions('auth', [
            'login'
          ]),
          ...mapActions('app', ['showSnackbar']),
          async submit(event) {
            try {

              await this.login(event)
            
              if (this.$route.query.from !== undefined) {
                this.$router.push({'path': this.$route.query.from})
              } else {
                this.$router.push({'path': '/'})
              }
            
              // show error notification
            } catch (err) {
              this.showSnackbar({
                timeout: 7000,
                value: true,
                color: 'error',
                content: "Email sau parola incorecte",
              })
            }
          }
        }
    }
</script>
