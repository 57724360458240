import axios from 'axios'
import Vue from 'vue'

const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_API_SCHEMA}${process.env.VUE_APP_API_HOST}/`,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    // withCredentials: true
})

function plugin(app, axios) {
    if (plugin.installed) {
        return;
    }
    plugin.installed = true;

    Object.defineProperties(app.prototype, {
        axios: {
            get: function get() {
                return axios;
            }
        },
        $http: {
            get: function get() {
                return axios;
            }
        }
    });

    app.axios = axios;
    app.$http = axios;
}

Vue.use(plugin, axiosInstance);

export default axiosInstance