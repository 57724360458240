import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/modules/app'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import team from '@/store/modules/team'
import teamInvite from '@/store/modules/teamInvite'
import season from '@/store/modules/season'
import carInvite from '@/store/modules/carInvite'
import drivers from '@/store/modules/drivers'
import event from '@/store/modules/event'
import messages from '@/store/modules/messages'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    auth,
    user,
    team,
    season,
    drivers,
    event,
    teamInvite,
    carInvite,
    messages
  }
})
