<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md10 offset-md1 lg8 offset-lg2>
      <v-card outlined>
        <v-card-title class="font-weight-black text-h4 text-uppercase mb-4">
          {{season.title}}
        </v-card-title>
        <v-card-text :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
          <v-tabs color="blue" centered icons-and-text v-model="tab" :show-arrows="true">
            <v-tab href="#info">Prezentare<v-icon>mdi-information</v-icon>
            </v-tab>
            <v-tab href="#calendar">Calendar<v-icon>mdi-calendar</v-icon>
            </v-tab>
            <v-tab href="#standings">Clasament<v-icon>mdi-clipboard-list</v-icon>
            </v-tab>
            <v-tab href="#rules">Regulament<v-icon>mdi-file-document</v-icon>
            </v-tab>
            <v-tab href="#register">Înscrieri<v-icon>mdi-file-document-edit</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item value="info">
              <v-container fluid>
                <v-row class="mt-5" align="center" justify="center">
                  <v-col cols="12">
                      <v-progress-linear
                        color="light-green"
                        height="30"
                        :value="seasonElapsedPercent"
                        :buffer-value="seasonElapsedPercent"
                        class="mb-4"
                        stream
                      >
                        <span class="font-italic text--secondary font-weight-black" v-if="seasonElapsedPercent >= 100"><v-icon left small>mdi-trophy</v-icon>This one's done and dusted. Can we have one more, pretty please?<v-icon right small>mdi-trophy</v-icon></span>
                        <span class="font-italic text--secondary font-weight-black" v-else-if="seasonElapsedPercent > 0">Roughly {{Math.ceil(seasonElapsedPercent)}}% raced already!</span>
                        <span class="font-italic text--secondary font-weight-black" v-else><v-icon left small>mdi-penguin</v-icon>We can't wait to begin!<v-icon right small>mdi-penguin</v-icon></span>
                      </v-progress-linear>
                  </v-col>
                </v-row>
                <!--div v-html="season.longDescription">
                </div -->
                <div class="text-body-2 " v-if="season">
                  <span class="mt-4 font-weight-black text-h6 text-uppercase text--primary">Formatul competiției</span>
                  <p>
                    <strong>{{season.title}}</strong> constă în <strong>{{season.events.length}} etape</strong> care se desfășoară între
                    <strong>{{printDate ( seasonStartDate )}}</strong> și <strong>{{printDate ( seasonEndDate )}}</strong>.
                    <template v-if="season.rules.maxDriversPerCar > 1">
                      Aceasta este o competiție care pune accentul pe lucrul în echipă: fiecare echipaj este compus din
                        <template v-if="season.rules.minDriversPerCar != season.rules.maxDriversPerCar">{{season.rules.minDriversPerCar}} până la {{season.rules.maxDriversPerCar}} piloți
                        </template>
                        <template v-else>
                          {{season.rules.minDriversPerCar}} piloți
                        </template>
                      care se vor succede la comenzile mașinii în care participă.
                    </template>
                    <template v-else>
                      Aceasta este o competiție care pune accentul pe performanțele individuale ale piloților. 
                    </template>
                    Cluburile înregistrate pe platforma RLR au dreptul de a înscrie
                    <template v-if="season.rules.maxCarsPerTeam > 1">
                      cel mult {{season.rules.maxCarsPerTeam}} mașini în competiție, astfel că un număr maxim de {{season.rules.maxCarsPerTeam*season.rules.maxDriversPerCar}} piloți vor putea acumula puncte pentru clubul din care fac parte.
                    </template>
                    <template v-else>
                      o singură mașină în competiție, astfel că
                      <template v-if="season.rules.maxDriversPerCar > 1">
                        maxim {{season.rules.maxDriversPerCar}} piloți pot acumula puncte pentru clubul din care fac parte.
                      </template>
                      <template v-else>
                        doar un singur pilot poate acumula puncte pentru clubul din care face parte.
                      </template>
                    </template>
                  </p>
                  <span class="mt-4 font-weight-black text-h6 text-uppercase text--primary" v-if="season.rules.validCars.length > 1">Mașinile permise</span>
                  <span class="mt-4 font-weight-black text-h6 text-uppercase text--primary" v-else>Mașina folosită</span>
                  <p>
                    <template v-if="season.rules.validCars.length > 1">
                      Poți alege ce mașină îți place în <strong>{{season.title}}</strong>. Te poți înscrie folosind oricare dintre următoarele modele:
                      <ul>
                        <li v-for="car in season.rules.validCars" :key="car._id">
                          {{car.title}}
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      Toți participanții vor concura folosind mașini <strong>{{season.rules.validCars[0].title}}</strong>, așa că diferența o va face numai priceperea piloților!
                      Asigură-te că înțelegi complet mașina și îi descoperi toate secretele ca să ajungi la cel mai bun nivel.
                    </template>
                  </p>
                  <span class="mt-4 font-weight-black text-h6 text-uppercase text--primary">Participarea la curse</span>
                  <p>
                    Fiecare etapă are
                    <template v-if="maxRacesPerEvent != minRacesPerEvent">
                      între {{minRacesPerEvent}} și {{maxRacesPerEvent}} curse
                    </template>
                    <template v-else>
                      <template v-if="maxRacesPerEvent > 1">
                        {{maxRacesPerEvent}} curse
                      </template>
                      <template v-else>
                        o singură cursă
                      </template>
                    </template>
                    cu o durată
                    <template v-if="maxRaceLength != minRaceLength">
                      cuprinsă între {{minRaceLength}} minute și {{maxRaceLength}} minute.
                    </template>
                    <template v-else>
                      de {{maxRaceLength}} minute.
                    </template>
                    Toată lumea este binevenită în competiție,
                    <template v-if="season.rules.prequalEnabled">
                      dar va trebui să demonstreze în sesiunile de precalificare că este suficient de curat
                      <template v-if="season.rules.prequalLimitPercentage > 100">
                        și de rapid - la fiecare etapă vor putea participa doar piloții care au reușit să obțină un timp în precalificări care se află într-un <strong>barem de {{season.rules.prequalLimitPercentage}}%</strong> raportat la cel mai bun rezultat.
                      </template>
                      Mai multe detalii despre sesiunile și regulile de precalificare se pot găsi în pagina fiecărei etape.
                    </template>
                    <template v-else>
                      trebuie doar să fii înregistrat în sezon prin platformă și să confirmi prezența în săptămâna cursei, din pagina dedicată etapei.
                    </template>
                  </p>
                  <p>
                    La fiecare etapă
                    <template v-if="season.rules.maxSplits > 1">
                      se vor organiza maxim {{season.rules.maxSplits}} split-uri, în funcție de prezență.
                      Se vor organiza sesiuni de cursă pentru fiecare split cu cel puțin {{season.rules.minCarsPerSplit}} mașini prezente iar în fiecare split vor participa cel mult {{season.rules.maxCarsPerSplit}} de mașini.
                    </template>
                    <template v-else>
                      vor participa maxim {{season.rules.maxCarsPerSplit}} mașini, în prima divizie valorică.
                    </template>
                    <template v-if="season.rules.maxCarsInClassPerSplit">
                      Pe grila de start vor fi maxim {{season.rules.maxCarsInClassPerSplit}} mașini din aceeași clasă.
                    </template>
                  </p>
                  <template v-if="season.deadlines">
                    <span class="mt-4 font-weight-black text-h6 text-uppercase text--primary">Înscrieri</span>
                    <p>
                      <template v-if="Date.parse(season.deadlines.registration) < Date.now()">
                        Înscrierile pentru <strong>{{season.title}}</strong> s-au încheiat pe <strong>{{printDate(Date.parse(season.deadlines.registration))}}</strong>, dar te așteptăm în alte competiții.
                      </template>
                      <template v-else>
                        Înscrierile pentru <strong>{{season.title}}</strong> sunt deschise până pe <strong>{{printDate(Date.parse(season.deadlines.registration))}}</strong>, așa că hai și tu în competiție!
                      </template>
                    </p>
                  </template>
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item value="calendar">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" xl="4" v-for="(event, index) in season.events" :key="index">
                    <v-card outlined>
                      <v-img
                        :height="200"
                        lazy-src="@/assets/gchallenge.jpg"
                        :src="event.coverPhoto ? `/img/${event.coverPhoto}` : ''"
                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                      >
                        <div class="d-flex justify-space-between flex-wrap flex-column" :style="{'height': '100%'}">
                          <v-card-actions>
                            <v-chip
                              :color="new Date ( Date.parse ( event.time ) ) <= Date.now() ? 'yellow darken-2' : 'green darken-2'"
                              :dark="new Date ( Date.parse ( event.time ) ) > Date.now()"
                              label
                              :ripple="false"
                            >
                              <span class="text-overline">Etapa {{index+1}}</span>
                            </v-chip>
                          </v-card-actions>
                          <div class="pa-0 mt-0 d-flex flex-column">
                            <v-card-title class="text-h5 text-uppercase font-weight-bold white--text">{{event.title}}</v-card-title>
                            <v-btn text tile dark @click="revealSessions(index)" class="flex-grow-1 ma-0 py-5"><v-icon left small>mdi-timer</v-icon>Program</v-btn>
                          </div>
                        </div>
                      </v-img>
                      <v-container>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <div outlined tile class="d-flex flex-wrap justify-space-between px-2 py-2">
                              <span class="text-caption"><v-icon class="mr-2">mdi-road-variant</v-icon>{{ event.track.trackFamily.name }} {{event.track.name}}</span>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-divider></v-divider>
                            <div outlined tile class="d-flex flex-wrap justify-space-between px-2 py-2">
                              <span class="text-caption"><v-icon class="mr-2">mdi-calendar</v-icon>{{ printDate ( new Date ( Date.parse ( event.time ) ) ) }}</span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions class="pa-0 mt-0 d-flex">
                        <v-btn tile depressed text color="primary" :to="`/event/${event._id}?tab=info`" class="flex-grow-1 ma-0 py-5"><v-icon left>mdi-information</v-icon>Info</v-btn>
                        <v-btn tile depressed text color="green" dark :to="`/event/${event._id}?tab=results`" v-if="new Date ( Date.parse ( event.time ) ) <= Date.now()" class="flex-grow-1 ma-0 py-5"><v-icon left>mdi-clipboard-list</v-icon>Rezultate</v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <v-card
                          v-if="sessionsRevealed==index"
                          class="transition-fast-in-fast-out v-card--reveal d-flex flex-column"
                          style="height: 100%;"
                        >
                          <v-card-title class="d-flex justify-space-between">
                            <span class="text-uppercase">Program</span>
                            <v-btn text @click="hideSessions()" color="red">Închide</v-btn>
                          </v-card-title>
                          <v-card-text style="overflow-y: auto">
                          <v-row no-gutters v-for="session in event.sessions" :key="session._id">
                            <template v-if="!session.official && session.type.code=='Q'">
                              <v-col cols="12">
                                <v-card-subtitle class="pa-0 mt-4 text-subtitle-2">{{printDate(new Date(Date.parse(session.startDate)))}} - {{printDate( new Date(session.startDate).getTime()+session.durationMinutes*60000)}}</v-card-subtitle>
                              </v-col>
                              <v-col cols="12">
                                <v-card outlined tile class="d-flex flex-wrap justify-space-between px-2 py-2">
                                  <span class="text-caption text-uppercase font-weight-medium">
                                    <v-icon small class="mr-2">mdi-timer</v-icon>
                                    <span>
                                      {{session.title}}
                                    </span>
                                  </span>
                                  <span v-if="session.hotstintLaps>1">
                                    Medie pe {{session.hotstintLaps}} tururi
                                  </span>
                                </v-card>
                              </v-col>
                            </template>
                          </v-row>
                          <v-row no-gutters>
                            <v-card-subtitle class="pa-0 mt-4 text-subtitle-2">{{ printDate ( new Date ( Date.parse ( event.time ) ) ) }}</v-card-subtitle>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="12" v-for="session in event.sessions" :key="session._id">
                              <v-card outlined tile class="d-flex flex-wrap justify-space-between px-2 py-2" v-if="session.official || session.type.code!='Q'">
                                <span class="text-caption text-uppercase font-weight-medium">
                                  <v-icon small class="mr-2" v-if="session.type.code=='Q' && session.official">mdi-timer</v-icon>
                                  <v-icon small class="mr-2" v-else-if="session.type.code=='R'">mdi-flag-checkered</v-icon>
                                  <v-icon small class="mr-2" v-else-if="session.type.code=='A'">mdi-human-male-board</v-icon>
                                  <v-icon small class="mr-2" v-else>mdi-flag-outline</v-icon>
                                  <span>
                                    <span>{{session.title}}</span><span v-if="session.official"> ({{session.durationMinutes}}min)</span>
                                  </span>
                                </span>
                                <span>
                                  <span v-if="session.official">
                                   {{printTime(session.startDate)}}
                                  </span>
                                  <span v-else>
                                    {{printTime(session.startDate)}}
                                  </span>
                                </span>
                              </v-card>
                            </v-col>
                          </v-row>
                          </v-card-text>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item value="standings">
              <v-container fluid>
                  <v-row align="center">
                    <v-col
                      cols="12"
                      align="center"
                    >
                      <v-dialog
                        v-model="newStandingsDialog"
                        max-width="600"
                        scrollable
                        persistent
                      >
                        <v-card>
                          <v-card-title>Creează clasament nou</v-card-title>
                          <v-card-text>
                            <v-form>
                              <v-row>
                                <v-col cols="12"><v-checkbox v-model="newStandings.public" label="Afișează public?" @chnage="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="6">
                                  <v-text-field :rules="[rules.required]" label="Nume" v-model="newStandings.title" @change="unsavedChanges=true"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-select
                                    :rules="[rules.required]"
                                    :items="availableStandingsTypes"
                                    return-object
                                    item-value="_id"
                                    item-text="name"
                                    label="Tip clasament"
                                    v-model="newStandings.type"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  <v-select
                                    v-if="season.carClasses"
                                    multiple
                                    :items="season.carClasses.map(cls => cls.obj)"
                                    item-text="name"
                                    item-value="_id"
                                    label="Clase acceptate"
                                    v-model="newStandings.filters.carClasses"
                                  ></v-select>
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    v-if="season.driverClasses"
                                    multiple
                                    :items="season.driverClasses.map(cls => cls.obj)"
                                    item-text="name"
                                    item-value="_id"
                                    label="Licențe acceptate"
                                    v-model="newStandings.filters.driverClasses"
                                  ></v-select>
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    v-if="season.rules.validCars"
                                    multiple
                                    :items="season.rules.validCars"
                                    item-text="title"
                                    item-value="_id"
                                    return-object
                                    label="Mașini acceptate"
                                    v-model="newStandings.filters.carModels"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-slider
                                    v-model="newStandings.filters.maxIgnoredEvents"
                                    label="Etape ignorate"
                                    step="1"
                                    ticks="always"
                                    tick-size="5"
                                    color="red"
                                    track-color="green"
                                    thumb-label="always"
                                    thumb-size="24"
                                    thumb-color="red"
                                    :max="season.events.length-1"
                                    :min="0"
                                  ></v-slider>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col cols="12"><span class="text-subtitle-2">Coloane afișate</span></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="newStandings.displayedColumns" label="Poziție" value="position" disabled></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="newStandings.displayedColumns" label="Mașină" value="cars" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="newStandings.displayedColumns" label="Piloți" value="drivers" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="newStandings.displayedColumns" label="Club" value="teams" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="newStandings.displayedColumns" label="Puncte" value="points" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="9">
                                  <v-row no-gutters align="center">
                                    <v-checkbox hide-details v-model="newStandings.displayedColumns" value="miscText" @change="unsavedChanges=true" class="shrink mr-2 mt-0">
                                    </v-checkbox>
                                    <v-text-field label="Coloană extra" v-model="newStandings.miscTextName" @change="unsavedChanges=true"></v-text-field>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn @click="addNewStandings()" color="green" dark><v-icon small>mdi-plus</v-icon>Creează</v-btn>
                            <v-btn @click="newStandingsDialog=false" color="red" text>Anulează</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="editStandingsEventsDialog"
                        max-width="600"
                        scrollable
                        v-if="currentStandings"
                        persistent
                      >
                        <v-card>
                          <v-card-title>Curse punctate</v-card-title>
                          <v-card-text>
                            <v-menu
                              :close-on-content-click="true"
                              v-model="standingsAddNewEventMenu"
                              @click:outside="standingsAddNewEventMenu=false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  text
                                  color="primary"
                                  :disabled="!currentStandingsAvailableEvents.length"
                                  v-bind="attrs"
                                  v-on="on"
                                  :offset-x="true"
                                >
                                  <v-icon left>mdi-plus</v-icon>Adaugă etape
                                </v-btn>
                              </template>
                              <v-card max-width="600px">
                                <v-card-title>Etape disponibile</v-card-title>
                                <v-card-subtitle>{{season.title}}</v-card-subtitle>
                                <v-card-text>
                                  <v-list
                                    style="max-height: 300px"
                                    class="overflow-y-auto"
                                    dense
                                  >
                                    <v-list-item
                                      v-for="(event, eventIndex) in currentStandingsAvailableEvents"
                                      :key="eventIndex"
                                    >
                                      <v-list-item-action>
                                        <v-btn color="green" dark min-width="36px" class="px-0"><v-icon class="mx-0" @click="addCurrentStandingsEvent(event)">mdi-plus</v-icon></v-btn>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        {{event.title}}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                            
                            <v-divider></v-divider>
                            <v-card outlined v-for="(event, eventIndex) in currentStandings.events" :key="event.obj._id" class="mt-2">
                              <v-card-title>{{event.obj.title}}</v-card-title>
                              <v-card-text>
                                <v-list>
                                  <v-list-item :key="sessionListIndex" v-for="(session, sessionListIndex) in event.sessions">
                                    <v-row align="center">
                                      <v-col>{{session.obj.title}}</v-col>
                                      <v-col>
                                        <v-select
                                          :items="availableScoringTypes"
                                          v-model="currentStandings.events[eventIndex].sessions[sessionListIndex].scoringType"
                                          return-object
                                          item-value="_id"
                                          item-text="name"
                                          label="Grilă punctare"
                                        ></v-select>
                                      </v-col>
                                      <v-list-item-action>
                                        <v-btn
                                          color="red"
                                          text
                                          min-width="36px"
                                          class="px-0"
                                        >
                                          <v-icon class="mx-0" @click="removeCurrentStandingsEventSession(eventIndex, sessionListIndex)" left>
                                            mdi-delete-empty
                                          </v-icon>
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-row>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-row align="center">
                                      <v-col>
                                        <v-select
                                          :items="availableSessionsFromEvent(event.obj)"
                                          return-object
                                          item-value="_id"
                                          item-text="title"
                                          v-model="event.pendingSession"
                                          label="Sesiunea"
                                        ></v-select>
                                      </v-col>
                                      <v-col>
                                        <v-select
                                          :items="availableScoringTypes"
                                          return-object
                                          item-value="_id"
                                          item-text="name"
                                          v-model="event.pendingScoringType"
                                          label="Grilă punctare"
                                        ></v-select>
                                      </v-col>
                                      <v-list-item-action>
                                        <v-btn
                                          color="green"
                                          text
                                          min-width="36px"
                                          class="px-0"
                                          :disabled="!event.pendingSession._id || !event.pendingScoringType._id"
                                        >
                                          <v-icon class="mx-0" @click="addCurrentStandingsEventSession(eventIndex)" left>
                                            mdi-check-bold
                                          </v-icon>
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-row>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn @click="refreshCurrentStandingsEntries(); editStandingsEventsDialog=false;" color="green" dark>Salvează</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="editStandingsDialog"
                        max-width="600"
                        persistent
                        scrollable
                        v-if="currentStandings"
                      >
                        <v-card>
                          <v-card-title>Editează clasament {{currentStandings.title}}</v-card-title>
                          <v-card-text>
                            <v-form>
                              <v-row>
                                <v-col cols="12"><v-checkbox v-model="currentStandings.public" label="Afișează public?" @chnage="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="6">
                                  <v-text-field :rules="[rules.required]" label="Nume" v-model="currentStandings.title"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-select
                                    :rules="[rules.required]"
                                    :items="availableStandingsTypes"
                                    return-object
                                    item-value="_id"
                                    item-text="name"
                                    label="Tip clasament"
                                    v-model="currentStandings.type"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  <v-select
                                    v-if="season.carClasses"
                                    multiple
                                    :items="season.carClasses.map(cls => cls.obj)"
                                    item-text="name"
                                    item-value="_id"
                                    label="Clase acceptate"
                                    v-model="currentStandings.filters.carClasses"
                                  ></v-select>
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    v-if="season.driverClasses"
                                    multiple
                                    :items="season.driverClasses.map(cls => cls.obj)"
                                    item-text="name"
                                    item-value="_id"
                                    label="Licențe acceptate"
                                    v-model="currentStandings.filters.driverClasses"
                                  ></v-select>
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    v-if="season.rules.validCars"
                                    multiple
                                    :items="season.rules.validCars"
                                    item-text="title"
                                    item-value="_id"
                                    return-object
                                    label="Mașini acceptate"
                                    v-model="currentStandings.filters.carModels"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-slider
                                    v-model="currentStandings.filters.maxIgnoredEvents"
                                    label="Etape ignorate"
                                    step="1"
                                    ticks="always"
                                    tick-size="5"
                                    color="red"
                                    track-color="green"
                                    thumb-label="always"
                                    thumb-size="24"
                                    thumb-color="red"
                                    :max="season.events.length-1"
                                    :min="0"
                                  ></v-slider>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col cols="12"><span class="text-subtitle-2">Coloane afișate</span></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="currentStandings.displayedColumns" label="Poziție" value="position" disabled></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="currentStandings.displayedColumns" label="Mașină" value="cars" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="currentStandings.displayedColumns" label="Piloți" value="drivers" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="currentStandings.displayedColumns" label="Club" value="teams" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="3"><v-checkbox v-model="currentStandings.displayedColumns" label="Puncte" value="points" @change="unsavedChanges=true"></v-checkbox></v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-row no-gutters align="center">
                                    <v-checkbox hide-details v-model="currentStandings.displayedColumns" value="miscText" @change="unsavedChanges=true" class="shrink mr-2 mt-0"></v-checkbox>
                                    <v-text-field label="Coloană extra" v-model="currentStandings.miscTextName"></v-text-field>
                                </v-row>
                              </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn @click="saveEditCurrentStandings()" color="green" dark>Salvează</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row align="center" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="12"
                      xl="6"
                      lg="6"
                      md="8"
                      sm="12"
                      align="center"
                    >
                      <v-slide-group
                        center-active
                        v-model="selectedStandings"
                        @change="activeStandings=selectedStandings"
                        show-arrows="always"
                      >
                        <v-slide-item
                          v-for="standings in availableStandings"
                          :key="standings._id"
                          :value="standings._id"
                          v-slot="{active, toggle}"
                        >
                          <v-btn
                            :input-value="active"
                            active-class="primary white--text"
                            depressed
                            rounded
                            @click="toggle"
                            class="mx-2"
                          >{{standings.title.toUpperCase()}}
                          </v-btn>
                        </v-slide-item>
                      </v-slide-group>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row align="center" no-gutters>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="12"
                      xl="4"
                      lg="4"
                      md="5"
                      sm="12"
                      align="center"
                    >
                        <v-btn
                          @click="deleteCurrentStandings()"
                          text
                          v-if="canUserEditStandings && currentStandings && currentStandings._id"
                          color="red"
                        >
                          <v-icon color="red">mdi-delete-empty</v-icon>Șterge
                        </v-btn>
                        <v-btn
                          @click="newStandingsDialog=true"
                          text
                          v-if="canUserEditStandings"
                          color="green"
                        >
                          <v-icon color="green" left>mdi-plus</v-icon>Adaugă
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row align="center" no-gutters>
                      <v-col
                        cols="12"
                        align="center"
                        v-if="canUserEditStandings && currentStandings"
                      >
                        <v-switch label="Editează tabel" v-model="editEnabled" color="green" v-if="canUserEditStandings && currentStandings" class="d-inline-block"></v-switch>
                        <v-btn tile text color="primary" dark @click="editStandingsDialog=true"><v-icon left>mdi-pencil</v-icon>Detalii clasament</v-btn>
                        <v-btn tile text color="primary" dark @click="editStandingsEventsDialog=true"><v-icon left>mdi-calendar</v-icon>Etape</v-btn>
                        <v-btn tile text color="primary" dark @click="refreshCurrentStandingsEntries();"><v-icon left>mdi-refresh</v-icon>Recalculează</v-btn>
                        <v-btn tile text color="green" v-if="unsavedChanges" dark @click="saveStandings()"><v-icon left>mdi-content-save</v-icon>Salvează</v-btn>
                      </v-col>
                  </v-row>
                <v-row>
                  <v-col cols="12" v-if="currentStandings">
                    <v-data-table
                      :headers="standingsTableHeader.filter(h => editEnabled || currentStandings.displayedColumns.includes(h.value))"
                      :items="currentStandings.entries.filter(e => editEnabled || !e.disqualified)"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:header.miscText>
                        {{currentStandings.miscTextName}}
                      </template>

                      <template v-slot:header.cars v-if="currentStandings.type.code == 'T'">
                        Echipă
                      </template>

                      <template v-slot:header.cars v-else>
                        Mașină
                      </template>
                      
                      <template v-slot:[`item.position`]="{ item }" v-if="editEnabled">
                        <v-switch
                          v-model="item.disqualified"
                          :false-value="true"
                          :true-value="false"
                          :value="item.disqualified"
                          :label="item.position.toString()"
                          @change="unsavedChanges=true"
                        ></v-switch>
                      </template>
                      <template v-slot:[`item.position`]="{ item }" v-else>
                          {{item.position}}
                      </template>
                      
                      <template v-slot:[`item.teams`]="{ item }" v-if="editEnabled">
                        <template v-for="(team, index) in item.teams">
                          <v-checkbox
                            v-model="team.ignored"
                            :label="`${team.name}`"
                            :key="index"
                            @change="unsavedChanges=true"
                            :true-value="false"
                            :false-value="true"
                            :disabled="item.teams.filter(t=>!t.ignored).length < 2 && !team.ignored"
                          ></v-checkbox>
                        </template>
                      </template>
                      <template v-slot:[`item.teams`]="{ item }" v-else>
                        <template v-for="team in item.teams">
                          <span :key="team._id" v-if="!team.ignored">
                            {{team.name}}
                          </span>
                        </template>
                      </template>
                      
                      <template v-slot:[`item.drivers`]="{ item }" v-if="editEnabled">
                        <template v-for="(driver, index) in item.drivers">
                          <v-checkbox
                            v-model="driver.ignored"
                            :label="`${driver.profile.firstName} ${driver.profile.lastName}`"
                            :key="index"
                            @change="unsavedChanges=true"
                            :true-value="false"
                            :false-value="true"
                            :disabled="item.drivers.filter(d=>!d.ignored).length < 2 && !driver.ignored"
                          ></v-checkbox>
                        </template>
                      </template>
                      <template v-slot:[`item.drivers`]="{ item }" v-else>
                        <template v-for="driver in item.drivers">
                          <span :key="driver._id" v-if="driver.ignored !== true" class="my-1 d-block">
                            <kbd v-for="(driverClass, kindex) in getUserClasses(driver._id)" :key="kindex" class="mr-1">{{driverClass.acronym}}</kbd>
                            {{driver.profile.firstName}} {{driver.profile.lastName}}
                          </span>
                        </template>
                      </template>
                      
                      <template v-slot:[`item.cars`]="{ item }" v-if="editEnabled">
                        <template v-for="(car, index) in item.cars">
                          <v-checkbox
                            v-model="car.ignored"
                            :label="`#${car.raceNumber} ${car.carName}`"
                            :key="index"
                            @change="unsavedChanges=true"
                            :true-value="false"
                            :false-value="true"
                            :disabled="item.cars.filter(c=>!c.ignored).length < 2 && !car.ignored"
                          ></v-checkbox>
                        </template>
                      </template>
                      <template v-slot:[`item.cars`]="{ item }" v-else>
                        <template v-for="(car, index) in item.cars.filter(c=>!c.ignored)">
                          <span :key="car._id" v-if="currentStandings.type.code != 'T'" class="my-1 d-block">
                            <v-row>
                              <v-img style="flex-wrap: nowrap;" v-if="carHasLiveryPreview (car.raceNumber)" :src="carLiveryPreviewURL(car.raceNumber)" max-width="80" max-height="32" class="mr-1" /><kbd v-for="(carClass, kindex) in car.classes" :key="kindex">{{carClass.acronym}}</kbd>#{{car.raceNumber}} {{car.carName}}
                            </v-row>
                          </span>
                          <span v-else-if="index == 0" :key="car._id">
                            {{car.carName}}
                          </span>
                        </template>
                      </template>
                      
                      <template v-slot:[`item.compensationPoints`]="props" v-if="editEnabled">
                        <v-edit-dialog
                          :return-value.sync="props.item.compensationPoints"
                          persistent
                          large
                        >
                          {{props.item.compensationPoints}}p
                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.compensationPoints"
                              :rules="[rules.numberRequired]"
                              label="Compensare"
                              autofocus
                              type="number"
                              @change="unsavedChanges=true"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.compensationPoints`]="{item}" v-else>
                        {{item.compensationPoints}}p
                      </template>
                      
                      <template v-slot:[`item.points`]="{item}" v-if="editEnabled">
                        {{item.points}}+{{item.compensationPoints}}={{parseFloat(item.points)+parseFloat(item.compensationPoints)}}p
                      </template>
                      <template v-slot:[`item.points`]="{item}" v-else>
                        <v-tooltip left>
                          <template v-slot:activator="{on, attrs}">
                            <span v-on="on" v-bind="attrs">
                              {{parseFloat(item.points)+parseFloat(item.compensationPoints)}}p
                            </span>
                          </template>
                          <div v-for="event in item.scoredEvents" :key="event.event_id" :class="event.ignored ? 'red--text' : 'green--text'">
                            {{event.points}}p {{event.event.title}}
                          </div>
                          <div>
                            {{parseFloat(item.compensationPoints)}}p ajustare
                          </div>
                        </v-tooltip>
                      </template>
                      
                      <template v-slot:[`item.miscText`]="props" v-if="editEnabled">
                        <v-edit-dialog
                          :return-value.sync="props.item.miscText"
                          persistent
                          large
                        >
                          {{props.item.miscText}}
                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.miscText"
                              :rules="[rules.required]"
                              :label="currentStandings.miscTextName"
                              autofocus
                              @change="unsavedChanges=true"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.miscText`]="{item}" v-else>
                        {{item.miscText}}
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" v-else-if="!season.standings.length" align="center">
                    <span class="text-h4">Clasamentul va fi disponibil în curând.</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item value="rules">
              <v-row>
                <v-col xs6 sm6 md6 lg6>
                  <v-btn text :disabled="pdfRulebookPage == 1" @click="pdfRulebookPage--">
                    <v-icon>mdi-chevron-left</v-icon>Pagina anterioara
                  </v-btn>
                </v-col>
                <v-col xs6 sm6 md6 lg6>
                  <v-btn text  class="float-right" :disabled="pdfRulebookPage == rulebookPageCount" @click="pdfRulebookPage++">Pagina urmatoare<v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-container fluid>
                <pdf @num-pages="updateRuleBookPageCount" :page="pdfRulebookPage" :src="`/${season.pdfRulebookPath}`"></pdf>
              </v-container>
              <v-row>
                <v-col xs6 sm6 md6 lg6>
                  <v-btn text :disabled="pdfRulebookPage == 1" @click="pdfRulebookPage--">
                    <v-icon>mdi-chevron-left</v-icon>Pagina anterioara
                  </v-btn>
                </v-col>
                <v-col xs6 sm6 md6 lg6>
                  <v-btn text  class="float-right" :disabled="pdfRulebookPage == rulebookPageCount" @click="pdfRulebookPage++">Pagina urmatoare<v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item value="register">
              <v-container v-if="!isLoggedIn" fluid>
                <v-row>
                  <v-col>
                    <p class="text-h6 font-weight-light">Pentru a te inscrie intr-un sezon trebuie <router-link
                        to="/login">sa fii autentificat</router-link>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-else fluid>
                <v-form ref="form">
                <v-row>
                  <v-col>
                    <v-subheader class="text-h6 font-weight-light">
                      Mașini în care ești pilot
                    </v-subheader>

                    <span v-if="carsUserDriving.length < 1" class="pl-4 subtitle-1">Nu ești înregistrat ca pilot în
                      sezon</span>

                    <v-list>
                      <template v-for="(car, index) in carsUserDriving">
                        <v-list-item :key="car._id">

                          <v-list-item-action>
                            <v-btn disabled outlined depressed fab class="ml-2" color="blue">
                              <v-icon>mdi-file-document-edit-outline</v-icon>
                            </v-btn>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon class="mr-3">mdi-car</v-icon>{{ "#" + car.raceNumber + " " + car.carName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-icon class="mr-3">mdi-racing-helmet</v-icon>
                              <span :key="driver._id" :id="`driver-${car.raceNumber}-${driver._id}`"
                                :style="{color: driver.registrationStatus ? 'green' : 'red'}"
                                text
                                v-for="(driver, dIndex) in car.drivers"
                              >
                                {{driver.driverName}}{{dIndex != car.drivers.length - 1 ? ', ' : ''}}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index < userTeamRegistrations.length - 1" :key="index" :inset="inset">
                        </v-divider>
                      </template>
                    </v-list>

                    <v-subheader class="text-h6 font-weight-light">
                      Cluburile tale înregistrate
                    </v-subheader>

                    <span v-if="userTeamRegistrations.length < 1" class="pl-4 subtitle-1">Nu a fost inregistrat niciun
                      club pe care îl coordonezi</span>

                    <v-list>
                      <template v-for="(registration, index) in userTeamRegistrations">
                        <v-list-item :key="registration._id">
                          <v-list-item-action>
                            <v-btn outlined depressed fab class="ml-2" color="blue"
                              :to="`/season-registration/${season._id}/${registration._id}`">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon class="mr-3">mdi-account-group</v-icon>{{ registration.teamName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <template v-for="car in registration.cars">
                                <div :key="car._id">
                                  <v-icon class="mr-3">mdi-car</v-icon>
                                  #{{car.raceNumber}} {{car.carName}}
                                  <span
                                    v-if="car.drivers.reduce ( (a, c) => { return a && c.registrationStatus }, true )">(Echipaj
                                    confirmat:
                                    {{car.drivers.map ( driver => {return driver.driverName} ).join (', ')}})</span>
                                  <span v-else>(Echipaj neconfirmat:
                                    <span :key="driver._id" :id="`driver-${car.raceNumber}-${driver._id}`"
                                      :style="{color: driver.registrationStatus ? 'green' : 'red'}"
                                      text
                                      v-for="(driver, dIndex) in car.drivers"
                                    >
                                      {{driver.driverName}}{{dIndex != car.drivers.length - 1 ? ', ' : ''}}
                                    </span>
                                    )
                                  </span>
                                </div>
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index < userTeamRegistrations.length - 1" :key="index" :inset="inset">
                        </v-divider>
                      </template>
                    </v-list>

                    <v-row>
                      <v-col>
                        <v-btn
                          :disabled="!registrationsOpen"
                          @click="openRegistration()"
                          :to="`/season-registration/${season._id}`"
                        >
                          <v-icon>mdi-plus</v-icon> Înscrie un club
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<style>
.entry-content {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}
.img-responsive {
  width: 100%;
}
.wp-block-image {
  margin-right: 44px;;
}
.v-slide-group__prev {
  display: none !important;
}
</style>
<script>
  import {
    mapActions,
    mapState,
    mapGetters
  } from 'vuex'
  import pdf from 'vue-pdf'
  import axios from '@/plugins/axios';

  export default {
    components: {
      pdf
    },
    data: () => {
      return {
        registrationHasError: true,
        currentRegistration: {
          team: '',
          cars: []
        },
        inset: true,
        items: [{
            action: 'mdi-label',
            title: 'signups',
            divider: true
          },
          {
            action: 'mdi-label',
            title: 'duration',
            divider: true
          }
        ],
        dialogOpen: false,
        events: [{
            name: 'Monaco',
            start: '2020-12-6 20:00',
            end: '2020-12-6 22:00',
            color: 'red',
          },
          {
            name: 'Monza',
            start: '2020-12-13 20:00',
            end: '2020-12-13 22:00',
            color: 'red',
          },
          {
            name: 'Brands Hatch',
            start: '2020-12-20 20:00',
            end: '2020-12-20 22:00',
            color: 'red',
          },
          {
            name: 'Kyalami',
            start: '2020-12-27 20:00',
            end: '2020-12-27 22:00',
            color: 'red',
          },
        ],
        standings: [],
        focus: '',
        type: 'month',
        typeToLabel: {
          month: 'Month',
          week: 'Week',
          day: 'Day',
          '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        userTeamRegistrations: [],
        userAvailableTeams: [],
        carsUserDriving: [],
        wantsToRegister: false,
        selectedCarClass: {},
        driverMenu: false,
        driverClassMenu: false,
        pdfRulebookPage: 1,
        rulebookPageCount: 0,
        sessionsRevealed: -1,
        standingsTableHeader: [
          { text: "#", value: "position", sortable: false, align: 'center' },
          { text: "Mașină", value: "cars", sortable: false },
          { text: "Piloți", value: "drivers", sortable: false },
          { text: "Club", value: "teams", sortable: false },
          { text: "Compensare", value: "compensationPoints", sortable: false },
          { text: "Puncte", value: "points", sortable: false },
          { text: "Info", value: "miscText", sortable: false }
        ],
        selectedStandings: undefined,
        currentStandings: {
          title: '',
          type: { code: 'D' },
          miscTextName: '',
          entries: [],
          displayedColumns: [],
          events: [],
          public: false,
          filters: {
            carClasses: [],
            driverClasses: []
          }
        },
        newStandings: {
          title: '',
          type: { code: 'D' },
          miscTextName: '',
          entries: [],
          displayedColumns: [],
          events: [],
          public: false,
          filters: {
            carClasses: [],
            driverClasses: [],
            carModels: [],
            maxIgnoredEvents: 0
          }
        },
        standingsHashmap: {},
        newStandingsDialog: false,
        editStandingsDialog: false,
        editStandingsEventsDialog: false,
        rules: {
          required: value => !!value || 'Obligatoriu',
          numberRequired: value => value !== undefined || 'Obligatoriu',
          timePenaltyRange: value => (value >= 0 && value <= 300) || 'Între 0 și 300 de secunde.',
          lapPenaltyRange: value => (value >= 0 && value <= 100) || 'Între 0 și 100 de tururi.',
          gridPenaltyRange: value => (value >= 0 && value <= 20) || 'Între 0 și 20 de poziții.'
        },
        emptyStandings: {
          title: '',
          type: {code: 'D'},
          displayedColumns: ['position', 'points'],
          miscTextName: '',
          entries: [],
          filters: {
            carClasses: [],
            driverClasses: []
          }
        },
        editEnabled: false,
        unsavedChanges: false,
        availableStandingsEvents: [],
        availableScoringTypes: [],
        availableStandingsTypes: [],
        standingsAddNewEventMenu: false
      }
    },
    methods: {
      consoleLog(obj) {
        console.log(obj);
      },
      ...mapActions("user", ["getUserPermissions"]),
      availableSessionsFromEvent(event) {
        let ev = this.availableStandingsEvents.find(e => e._id==event._id);
        
        if (!ev) {
          return [];
        }
        
        return ev.sessions;
      },
      addCurrentStandingsEvent (event) {
        let alreadyRegistered = this.currentStandings.events.filter(event => event.obj._id==event._id).length > 0;
        
        if ( !alreadyRegistered ) {
          this.currentStandings.events.push({
            obj: event,
            sessions: [],
            pendingSession: {},
            pendingScoringType: {}
          });
        }
      },
      removeCurrentStandingsEvent(eventIndex) {
        this.currentStandings.events.splice(eventIndex, 1);
      },
      addCurrentStandingsEventSession(eventIndex) {
        let ev = this.currentStandings.events[eventIndex]
        ev.sessions.push({
          obj: ev.pendingSession,
          scoringType: ev.pendingScoringType
        })
        /*
        this.currentStandings.events[eventIndex].pendingSession = {}
        this.currentStandings.events[eventIndex].scoringType = {}
        */
      },
      removeCurrentStandingsEventSession(eventIndex, sessionIndex) {
        this.currentStandings.events[eventIndex].sessions.splice(sessionIndex, 1);
      },
    getUserClasses(driverId) {      
      if (this.userSeasonClasses) {
        return this.userSeasonClasses.filter(item => item.user == driverId).map(uc=>uc.classes).flat();
      }
      
      return [];
    },
    printCountry (countryCode) {
      try {
        return this.countries[countryCode].name;
      } catch {
        return '';
      }
    },
    printRegion (countryCode, regionCode) {
      try {
        return this.countries[countryCode].regions[regionCode].name;
      } catch {
        return '';
      }
    },
    printLocation (countryCode, regionCode) {
      let country = this.printCountry (countryCode);
      let region = this.printRegion (countryCode, regionCode);
      
      let text = country;
      
      if ( text != '' && region != '' ) {
        text += ', ';
      }
      
      text += region;
      
      return text;
    },
    showDriverMenu () {
      this.driverMenu = true;
    },
    closeDriverMenu () {
      this.driverMenu = false;
    },
    getFullDriverName (carIndex, driverId) {
      if (!this.currentRegistration.cars[carIndex].availableDrivers) {
        return '';
      }
      
      let driver = this.currentRegistration.cars[carIndex].availableDrivers.find(d => d._id == driverId);
      
      if (!driver) {
        return '';
      }
      
      return driver.profile.firstName + " " + driver.profile.lastName;
    },
      viewDay({
        date
      }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor(event) {
        return event.color
      },
      setToday() {
        this.focus = ''
      },
      prev() {
        this.$refs.calendar.prev()
      },
      next() {
        this.$refs.calendar.next()
      },
      showEvent({
        nativeEvent,
        event
      }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },

      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      getEvents() {
        this.$http.get('/api/events').then(events => {
          this.events = events.map(event => {
            let eventDuration = event.practice + event.qualify + event.race + (event.raceInverted ? event.race :
              0);
            let eventEnd = event.date + eventDuration;
            return {
              name: event.title,
              start: event.date,
              end: eventEnd,
              color: 'red',
            }
          })
        })
      },
      addCurrentRegistrationCar() {
        if (this.currentRegistration.cars.length < this.season.rules.maxCarsPerTeam) {
          let newCar = {
            carModel: '',
            carName: '',
            raceNumber: '',
            drivers: [],
            team: ''
          }
          newCar.availableDrivers = this.driversList.filter((availableDriver) => {
            let available = true

            this.currentRegistration.cars.forEach(otherCar => {
              if (otherCar.drivers.indexOf(availableDriver._id) >= 0) {
                available = false;
              }

            })
            return available;
          })
          
          newCar.availableDrivers = newCar.availableDrivers.map(d => ({...d, classes: []}))
          this.currentRegistration.cars.push(newCar)
        }
      },
      syncErrors(errorValue) {
        this.registrationHasError = errorValue
      },
      ...mapActions('season', [
        'getSeason',
        'getUserSeasonClasses',
        'getSeasonStandings'
      ]),
      ...mapActions('app', ['showSnackbar']),
      printDate(date) {
        const monthNames = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie",
          "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"
        ];
        
        let m = new Date(date);
        
        let result = ("00" + m.getUTCDate()).slice(-2) + " " + monthNames[m.getUTCMonth()] + " " + m.getUTCFullYear();

        return result;
      },
      printShortDate(date) {
        let m = new Date(date);
        
        let result = ("00" + m.getUTCDate()).slice(-2) + "." + ("00" + (m.getUTCMonth()+1)).slice(-2);

        return result;
      },
      printTime(date) {
        let m = new Date(date)
        
        let result = ("00" + m.getHours()).slice(-2) + ":" + ("00" + m.getMinutes()).slice(-2)

        return result;
      },
      setUserRegisteredTeams() {
        this.userTeamRegistrations = []

        this.season.registrations.forEach(registration => {
          if (registration.initiatedByUser == this.userinfo.id ||
            registration.team.managers.includes(this.userinfo.id) ||
            registration.team.owner == this.userinfo.id
          ) {
            this.userTeamRegistrations.push(registration)
          }
        })
      },
      setCarsUserDriving() {
        let cars = []
        this.season.registrations.forEach(registration => {
          registration.cars.forEach(car => {
            if (car.drivers.map(driver => {
                return driver.driver
              }).includes(this.userinfo.id)) {
              cars.push(car)
            }
          })
        })

        this.carsUserDriving = cars
      },
      openRegistration() {
        this.wantsToRegister = true
      },
      closeRegistration() {
        this.wantsToRegister = false
      },
      pilotsRules(input) {
        let pilotiPerMasina = this.season.rules.maxDriversPerCar == this.season.rules.minDriversPerCar ?
          this.season.rules.maxDriversPerCar :
          `${this.season.rules.maxDriversPerCar} pana la ${this.season.rules.maxDriversPerCar}`
        return (input.length <= this.season.rules.maxDriversPerCar && input.length >= this.season.rules
            .minDriversPerCar) ||
          `Selecteaza ${pilotiPerMasina} piloti`
      },
      updateRuleBookPageCount(e) {
        this.rulebookPageCount = e
      },
      carNumberRules (input) {        
        return ( input && !isNaN (input) && !isNaN (parseFloat(input)) ) || 'Alege un număr pentru mașină'
      },
      carNameRules ( input ) {
        return ( input.length > 0 && input.trim().length > 0 ) || 'Alege numele echipajului'
      },
      domDecoder (str) {
        let parser = new DOMParser();
        let dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html');
        return dom.body.textContent;
      },
      
      decoder (str) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
      },
      revealSessions(eventIndex) {
        this.sessionsRevealed = eventIndex;
      },
      hideSessions() {
        this.sessionsRevealed = -1;
      },
      async getAvailableStandingsEvents() {
        let response = await axios.get(`/api/standings/${this.season._id}/available-events`);
        this.availableStandingsEvents = response.data.data;
      },
      async getAvailableScoringTypes() {
        let response = await axios.get(`/api/standings/scoring-types`);
        this.availableScoringTypes = response.data.data;
      },
      async getAvailableStandingsTypes() {
        let response = await axios.get(`/api/standings/standings-types`);
        this.availableStandingsTypes = response.data.data;
      },
      addNewStandings() {
        let s = {
          _id: (Math.random() + 1).toString(36).substring(7),
          events: [],
          entries: [],
          filters: {
            carClasses: this.newStandings.filters.carClasses,
            driverClasses: this.newStandings.filters.driverClasses,
            carModels: this.newStandings.filters.carModels,
            maxIgnoredEvents: this.newStandings.filters.maxIgnoredEvents
          },
          displayedColumns: this.newStandings.displayedColumns,
          miscTextName: this.newStandings.miscTextName,
          title: this.newStandings.title,
          type: this.newStandings.type,
        }
        
        this.newStandings = this.emptyStandings;
        
        this.season.standings.push(s);
        // Update session hashmap
        this.season.standings.forEach ( standings => {
          this.standingsHashmap[standings._id] = standings
        })
        
        this.activeStandings = s._id;
        this.newStandingsDialog = false;
        this.unsavedChanges = true;
      },
      deleteCurrentStandings() {
        let standingsIndex = this.season.standings.findIndex(s => s._id == this.currentStandings._id);
        
        if ( standingsIndex != -1 ) {
          this.season.standings.splice(standingsIndex, 1);
        }
        
        if ( this.season.standings.length ) {
          this.activeStandings = this.season.standings[0]._id || '';
        } else {
          this.activeStandings = '';
        }

        this.unsavedChanges = true;
        
      },
      async refreshCurrentStandingsEntries() {
        let response = await axios.post (
          `/api/standings/${this.season._id}/entries`,
          this.currentStandings
        )
        
        this.currentStandings.entries = response.data.data;
        
        this.unsavedChanges = true;
      },
      saveEditCurrentStandings() {
        /*
        this.season.standings.map ( s => {
          if ( s._id != this.currentStandings._id ) {
            return s;
          }
          
          return this.currentStandings;
        })
        */
        
        this.refreshCurrentStandingsEntries();
 
        // Update session hashmap
        this.standingsHashmap = {};
        this.season.standings.forEach ( standings => {
          this.standingsHashmap[standings._id] = standings
        })
        
        this.activeStandings = this.currentStandings._id;
        this.editStandingsDialog = false;
        this.editStandingsEventsDialog = false;
      },
      removeDriverFromCurrentStandings(entryId, driverId) {
        this.currentStandings.entries = this.currentStandings.entries.map ( entry => {
          if ( entry._id != entryId ) {
            return entry;
          }
          
          entry.drivers = entry.drivers.filter ( d => d._id != driverId );
          
          return entry;
        });
        
        this.unsavedChanges = true
      },
      removeCarFromCurrentStandings(entryId, carId) {
        this.currentStandings.entries = this.currentStandings.entries.map ( entry => {
          if ( entry._id != entryId ) {
            return entry;
          }
          
          entry.cars = entry.cars.filter ( d => d._id != carId );
          
          return entry;
        });
        
        this.unsavedChanges = true
      },
      removeTeamFromCurrentStandings(entryId, teamId) {
        this.currentStandings.entries = this.currentStandings.entries.map ( entry => {
          if ( entry._id != entryId ) {
            return entry;
          }
          
          entry.teams = entry.teams.filter ( d => d._id != teamId );
          
          return entry;
        });
        
        this.unsavedChanges = true
      },
      async getFreshStandingsInfo ( entryId, request, options ) {
        let response = await axios.post (
          `/api/standings/${this.season._id}/fresh`,
          {
            drivers: options.drivers,
            cars: options.cars,
            request: request
          }
        )
        
        return response.data.data
      },
      async refreshEntryTeams( entryId, options ) {
        let newTeams = await this.getFreshStandingsInfo ( entryId, 'teams', options );
        
        this.currentStandings.entries = this.currentStandings.entries.map ( entry => {
          if ( entry._id != entryId ) {
            return entry;
          }
          
          entry.teams = newTeams;
          
          return entry;
        });
        
        this.unsavedChanges = true
        this.saveEditCurrentStandings();
      },
      async refreshEntryDrivers( entryId, options ) {
        let newDrivers = await this.getFreshStandingsInfo ( entryId, 'drivers', options );
        
        this.currentStandings.entries = this.currentStandings.entries.map ( entry => {
          if ( entry._id != entryId ) {
            return entry;
          }
          
          entry.drivers = newDrivers;
          
          return entry;
        });
        
        this.unsavedChanges = true
        this.saveEditCurrentStandings();
      },
      async refreshEntryCars( entryId, options ) {
        let newCars = await this.getFreshStandingsInfo ( entryId, 'cars', options );
        
        this.currentStandings.entries = this.currentStandings.entries.map ( entry => {
          if ( entry._id != entryId ) {
            return entry;
          }
          
          entry.cars = newCars;
          
          return entry;
        });
        
        this.unsavedChanges = true
        this.saveEditCurrentStandings();
      },
      removeEntryFromCurrentStandings (entryId) {
        this.currentStandings.entries = this.currentStandings.entries.filter ( entry => entry._id != entryId);
        
        this.unsavedChanges = true
        this.saveEditCurrentStandings();
      },
      async getNewStandingsEntries () {
        let response = await axios.post (
          `/api/standings/${this.season._id}/new-entries`,
          {
            drivers: this.currentStandings.entries.map ( e => e.drivers ).flat(),
            cars: this.currentStandings.entries.map ( e => e.cars ).flat(),
            typeCode: this.currentStandings.type.code,
            startPosition: (Math.max( ...this.currentStandings.entries.map(e => e.position) )+1)
          }
        )
        
        this.currentStandings.entries = this.currentStandings.entries.concat ( response.data.data )
        this.unsavedChanges = true
        this.saveEditCurrentStandings();
      },
      async saveStandings() {
        this.saveEditCurrentStandings();
        
        let response = await axios.post (
          `/api/standings/${this.season._id}/save`,
          {
            standings: this.season.standings
          }
        )
        
        this.season.standings = response.data.data;
        
        // Update some stuff to please this stupid view
        this.season.standings = this.season.standings.map(st => {
          st.events = st.events.map(ev => {
            ev.pendingSession = {};
            ev.pendingScoringType = {};
            
            return ev;
          })
          return st;
        })
      
        // Update session hashmap
        this.season.standings.forEach ( standings => {
          this.standingsHashmap[standings._id] = standings
        })
      
        this.unsavedChanges = false;
        this.editEnabled = false;
        
        if ( this.season.standings.length ) {
          this.activeStandings = this.season.standings[0]._id || '';
        } else {
          this.activeStandings = '';
        }
      },
    carLiveryFileName (raceNumber) {
      const seasonPrefix = this.season.code ? `RLR_${this.season.code}` : 'RLR';
      return `${seasonPrefix}_${raceNumber}.png`;
    },
    carHasLiveryPreview (raceNumber) {
      try {
        // eslint-disable-next-line no-unused-vars
        const fileName = require("../../public/car_ico/" + this.carLiveryFileName(raceNumber));
        return true;
      } catch {} // eslint-disable-line no-empty
      
      return false;
    },
    carLiveryPreviewURL (raceNumber) {
      return `/car_ico/${this.carLiveryFileName(raceNumber)}`;
    }
    },  
    computed: {
      ...mapGetters('auth', ['userinfo', 'isLoggedIn']),
      ...mapState('season', ['season', 'carModelsList', 'validNumbersList', 'userSeasonClasses']),
      ...mapState('team', ['simulators']),
      ...mapGetters("user", ["userPermissions"]),
      availableStandings () {
        if (this.canUserEditStandings) {
          return this.season.standings;
        }
        
        return this.season.standings.filter ( s => s.public )
      },
      seasonRegistrationsCount () {
        return this.season.registrations.reduce ( (a, c) => { return a+c.cars.length }, 0 )
      },
      minRacesPerEvent () {
        return Math.min.apply (null, this.season.events.map(event => event.sessions.filter(s => s.type.code == 'R').length));
      },
      maxRacesPerEvent () {
        return Math.max.apply (null, this.season.events.map(event => event.sessions.filter(s => s.type.code == 'R').length));
      },
      minRaceLength () {
        return Math.min.apply (null,
          this.season.events.map(event => {
            return Math.min.apply(null, event.sessions.filter(s => s.type.code == 'R').map(s => s.durationMinutes));
          })
        );
      },
      maxRaceLength () {
        return Math.max.apply (null,
          this.season.events.map(event => {
            return Math.max.apply(null, event.sessions.filter(s => s.type.code == 'R').map(s => s.durationMinutes));
          })
        );
      },
      seasonStartDate () {
        return Math.min.apply(null, this.season.events.map ( event => { return Date.parse ( event.time ) } ) )
      },
      seasonEndDate () {
        return Math.max.apply(null, this.season.events.map ( event => { return Date.parse ( event.time ) } ) )
      },
      seasonElapsedPercent () {
        const totalEvents = this.season.events.length;
        const elapsedEvents = this.season.events.filter(e => Date.parse(e.time) < Date.now()).length;

        /*
        const totalTime = this.seasonEndDate - this.seasonStartDate;
        const elapsedTime = ( Date.now() - this.seasonStartDate );

        return Math.min(100, Math.max(0, elapsedTime/totalTime*100));
        */

       return (elapsedEvents/totalEvents * 100);
      },
      currentStandingsAvailableEvents() {
        if (!this.currentStandings || !this.availableStandingsEvents) {
          return [];
        }
        
        let available = this.availableStandingsEvents.filter(event => {
          let registered = this.currentStandings.events
            .map(cse=> cse.obj._id.toString())
            .includes(event._id)
          
          return !registered;
        })
        
        return available;
      },
      registrationsOpen() {
        return Date.parse(this.season.deadlines.registration) > Date.now();
      },
      canUserEditStandings () {
        if ( this.season && this.season.permissions && this.season.permissions.editStandings ) {
          return this.userPermissions.filter(perm => this.season.permissions.editStandings.includes(perm)).length;
        }
        
        return false;
      },
      isTeamStandings() {
        if (!this.currentStandings) {
          return true;
        }
        
        return this.currentStandings.type.code == 'T';
        
      },
      extraColumnName() {
        if (!this.currentStandings) {
          return 'Info';
        }
        
        return this.currentStandings.miscTextName;
      },
      tab: {
        set(tab) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              tab
            }
          })

          
          if ( this.$route.query.tab && this.$route.query.tab == 'standings' ) {
            if ( this.season.standings.length ) {
              this.activeStandings = this.selectedStandings || this.season.standings[0]._id || '';
            } else {
              this.activeStandings = this.selectedStandings || '';
            }
          }
        },
        get() {
          if (this.$route.query.tab)
            return this.$route.query.tab
          return "info"
        }
      },
      activeStandings: {
        set(activeStandings) {
          if (activeStandings == this.activeStandings) {
            return;
          }
          
          if ( activeStandings !== undefined ) {
            this.selectedStandings = activeStandings;
          }
          
          this.$router.replace({ query: { ...this.$route.query, activeStandings } });
          
          this.currentStandings = this.standingsHashmap[this.activeStandings];
        },
        get() {
          if (this.$route.query.activeStandings) return this.$route.query.activeStandings;
          return "";
        }
      },
    dragDriversChipOptions() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
    },
    async mounted() {
      await this.getUserPermissions();
      
      await this.getSeason(this.$route.params.id);
      await this.getSeasonStandings(this.$route.params.id);
      
      if ( this.canUserEditStandings ) {
        await this.getAvailableStandingsEvents();
        await this.getAvailableScoringTypes();
        await this.getAvailableStandingsTypes();
      }
      
      await this.getUserSeasonClasses(this.season._id)
      await this.setUserRegisteredTeams()
      await this.setCarsUserDriving()
      
      // Update some stuff to please this stupid view
      this.season.standings = this.season.standings.map(st => {
        st.events = st.events.map(ev => {
          ev.pendingSession = {};
          ev.pendingScoringType = {};
          
          return ev;
        })
        return st;
      })
      
      // Update session hashmap
      this.season.standings.forEach ( standings => {
        this.standingsHashmap[standings._id] = standings
      })
        
      // Make sure to set sessionresults if we're loading straight into the results tab:
      if ( this.$route.query.tab && this.$route.query.tab == 'standings' ) {
        if ( this.season.standings.length ) {
          this.activeStandings = this.selectedStandings || this.availableStandings[0]._id || '';
        } else {
          this.activeStandings = this.selectedStandings || '';
        }
      }
      
      this.selectedStandings = this.activeStandings;
      this.currentStandings = this.standingsHashmap[this.activeStandings];
      this.newStandings = this.emptyStandings;
    }
  }
</script>
<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.v-slide-group__prev {
  display: flex !important;
}

.v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__next {
  display: flex !important;
}
</style>
