<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
      <v-form @submit.prevent="register">
        <div
        class="text-center text-h2 transition-swing mb-10"
        v-text="'Înregistrează un cont nou'"
        ></div>
        <v-text-field name="email" autocomplete="email" v-model="email" label="Email"></v-text-field>
        <v-text-field name="password" autocomplete="new-password" v-model="password" label="Parolă" type="password"></v-text-field>
        <v-text-field name="password_confirm"  autocomplete="new-password" v-model="password_confirm" label="Confirmă parola" type="password"></v-text-field>
        <v-btn type="submit" class="mr-4">Înregistrează contul</v-btn><router-link to="/login">Ai deja cont? Intră în el</router-link>
      </v-form>
    </v-flex>
  </v-layout>
</template>


<script>
    export default {
        pageTitle: 'My Profile',
        components: {  },
        data () {
            return {
                email: '',
                password: '',
                password_confirm: '',
            }
        },
        methods: {
          async register() {
            try {
              await this.$http.post('/auth/register', { email: this.email, password: this.password, password_confirm: this.password_confirm})
              this.$router.push('/registerOk')
            } catch (err) {
              this.showSnackbar({
                timeout: 7000,
                value: true,
                color: 'error',
                content: "Ceva nu a mers bine, contacteaza organizatorii",
              })
            } 
          }
        }
    }
</script>
