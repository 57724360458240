<template>
  <div class="home">
    <v-layout row wrap class="my-5" align-center>
      <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
        <v-img :src="require('@/assets/rlr_logo.jpg')" max-height="320" max-width="640" />
        <div class="text-center text-h6 transition-swing mt-10">
          Bine ai venit! Vei primi in cateva momente un link de confirmare pe adresa de email introdusa.
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'RegisterOk',
  components: {
  }
}
</script>
