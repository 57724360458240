<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md8 offset-md2 lg6 offset-lg3>
      <v-card>
        <v-card-title class="text-h4 font-weight-light pl-10 text-truncate">Mesajele tale</v-card-title>
        <v-card-text>
          <v-list v-if="messages.length" three-line>
            <template v-for="(message, index) in messages">
              <v-list-item
                :key="message.subject"
                :to="`/message/${message._id}`"
              >
                <v-list-item-avatar>
                  <v-icon v-if="message.viewed">mdi-message-text-outline</v-icon>
                  <v-icon v-else>mdi-message-text</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="message.subject"></v-list-item-title>
                  <v-list-item-subtitle v-html="`Trimis de: ${message.from.profile.firstName} ${message.from.profile.lastName}`"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < messages.length - 1" :key="index"></v-divider>
            </template>
          </v-list>
          <v-list v-else>
            <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-circle-off-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Nu ai nici un mesaj</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>  
  import {mapActions, mapState} from 'vuex'
  export default {
    data: () => {
      return {}
        
    },
    methods: {
      ...mapActions('messages', [
        'getMessages',
      ])
    },
    computed: {
      ...mapState('messages', ['messages'])
    },
    async mounted () {
      await this.getMessages()
    }
  }
</script>
