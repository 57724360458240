<template>
  <v-loader>
  </v-loader>
</template>
<script>
import {mapActions, mapState} from 'vuex'
export default {
  data: () => {
    return {}
  },
  computed: {
    ...mapState('user', ['profile'])
  },
  methods: {
    ...mapActions('user', ['getProfile', 'setProfile'])
  },
  async mounted() {
    if (!this.$route.query['openid.identity']) {
      this.$router.push({path: '/404'})
    }
    await this.getProfile()
    let steamId = this.$route.query['openid.identity'].replace('https://steamcommunity.com/openid/id/','')
    this.setProfile({...this.profile, ...{ steamId: steamId } })
    this.$router.push({path: 'profile'})
  }
}
</script>
