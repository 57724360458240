import axios from '@/plugins/axios'

const state = {
    drivers: []
}

const getters = {
    drivers(state) {
        return state.drivers
    }
}

const actions = {
    async getDrivers({
        commit
    }) {
        let driversResponse = await axios({
            url:'/api/drivers',
            method: 'GET'
        })
        let drivers = driversResponse.data.data.map((driver) => {
            driver.value = driver._id
            driver.text = `${driver.profile.firstName} ${driver.profile.lastName}`
            return driver
        })
        commit('GET_DRIVERS', drivers)
    },
    async addDriver({commit}, driver) {
        let driversResponse = await axios({
            url: '/api/drivers/',
            method: 'POST',
            data: driver
        })
        commit('GET_DRIVERS', driversResponse.data.data)
    },
    async editDriver({commit}, driver) {
        let driversResponse = await axios({
            url: `/api/drivers/${driver.id}`,
            method: 'POST',
            data: driver
        })
        commit('GET_DRIVERS', driversResponse.data.data)
    },
    async deleteDriver({commit}, driver) {
        let driversResponse = await axios({
            url: `/api/drivers/${driver.id}/delete`,
            method: 'POST',
            data: driver
        })
        commit('GET_DRIVERS', driversResponse.data.data)
    },
}

// mutations
const mutations = {
    GET_DRIVERS(state, payload) {
        state.drivers = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}