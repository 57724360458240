<template>
  <v-app>
    <v-app-bar
      app
      color="dark-grey"
      dark
      :src="require('@/assets/rlr_logo.jpg')"
    >
      
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(2,8,12,.2), rgba(2,8,12, 1)"
        ></v-img>
      </template>
      
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>

      <template v-if="!$vuetify.breakpoint.smAndDown">
        <v-spacer></v-spacer>
        <v-btn
          icon
          :color="$vuetify.theme.dark ? 'white' : 'blue-grey'"
          @click="changeDarkMode()"
        >
          <v-icon>mdi-theme-light-dark</v-icon>
        </v-btn>
        <v-btn to="/current-series" text>
          <span>Competitii</span>
        </v-btn>
        <v-btn to="/teams" v-if="isLoggedIn" text>
          <span>Cluburi</span>
        </v-btn>
        <v-btn v-if="isLoggedIn" to="/messages" text>
          <v-badge
            :color="newMessages ? 'pink' : null"
            dot
          >
            <span>Mesaje</span>
          </v-badge>
        </v-btn>
        <v-btn v-if="isLoggedIn" to="/profile" text>
          <span>Contul meu</span>
        </v-btn>
        <v-btn v-if="isLoggedIn" to="/logout" text>
          <span>Deconectare</span>
        </v-btn>
        <v-btn v-if="!isLoggedIn" to="/login" text>
          <span>Conectare</span>
        </v-btn>
        <v-spacer></v-spacer>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              Dark mode
            </v-list-item-title>
          <v-switch
            icon
            :input-value="$vuetify.theme.dark"
            @change="changeDarkMode()"
            >
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-switch>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
          <router-link to="/current-series" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-medal</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Competitii
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/teams" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Cluburi
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/messages" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Mesaje
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/profile" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Contul meu
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="!isLoggedIn" to="/login" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-login</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Conectare
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/logout" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Deconectare
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar
      :timeout="snackbar.timeout"
      :value="snackbar.value"
      :color="snackbar.color"
      app
      >
      {{snackbar.content}}
      <template v-if="snackbar.action.text" v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="closeSnackbar()"
        >
          {{snackbar.action.text}}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  import {mapGetters, mapState, mapActions} from 'vuex'
 

  export default {
    name: 'App',

    components: {},

    data: () => ({
      group: "actions",
      drawer: false,
      messageGetterInterval: null,
      newMessages: 0,
      lastMessagesChecked: 0
    }),
    computed: {
      ...mapGetters('auth', ['isLoggedIn']),
      ...mapState('app', ['snackbar']),
      ...mapState('messages', ['messages'])
    },
    methods: {
      ...mapActions('messages', ['getMessages']),
      ...mapActions('app', ['showSnackbar','closeSnackbar']),
      changeDarkMode() {
        console.log(`Setting dark theme to ${!this.$vuetify.theme.dark}`);
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      },
      async checkMessages() {
        await this.getMessages()
        if (this.messages) {
          this.newMessages = this.messages.map(message => {
            return !message.viewed
          }).reduce((a,c) => { return a + c }, 0);
          if (this.lastMessagesChecked != this.newMessages) {
            this.showSnackbar({
              timeout: 5000,
              value: true,
              color: 'success',
              content: `Ai ${this.newMessages} ${this.newMessages == 1 ? 'mesaj nou': 'mesaje noi'}`,
              action: {
                callback: this.$router.push,
                params: '/messages',
                text: 'Vezi mesajele',
                thisVal: this.$router
              }
            })
          }
          this.lastMessagesChecked = this.newMessages
        } 
      }
    },
    async mounted() {
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
          if (theme === "true") {
              this.$vuetify.theme.dark = true;
          } else {
              this.$vuetify.theme.dark = false;
          }
      } else if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
          this.$vuetify.theme.dark = true;
          localStorage.setItem(
              "dark_theme",
              this.$vuetify.theme.dark.toString()
          );
      }

      if(this.isLoggedIn){
        this.checkMessages()
        this.messageGetterInterval = setInterval(this.checkMessages, 30000)
      }
    },
    destroy() {
      this.messageGetterInterval = null
      delete this.messageGetterInterval
    }
  };
</script>
