<template>
  <v-layout row wrap align-center>
  </v-layout>
</template>

<script>
  import {
    mapActions,
    mapState
  } from 'vuex'

  export default {
    data: () => {
      return {
      }
    },
    methods: {
      ...mapActions('event', ['getLastEvent'])
    },
    computed: {
      ...mapState('event', ['lastEvent'])
    },
    async mounted() {
      await this.getLastEvent()
      this.$router.push({path: `/event/${this.lastEvent._id}?tab=results`})
    }
  }
</script>