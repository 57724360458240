<template>
  <v-container>
    <input 
      ref="upload"
      id="fileUpload"
      type="file"
      style="display:none"/>
    <v-sheet
      id="dropzone"
      ref="dzone"
      tabindex="0"
      title="Click to grap a file from your PC!"
      color="indigo lighten-4"
      width="100%"
      style="cursor:pointer;"
      height="100%"
      class="pa-2"
    >
      <v-row justify="center">
        <v-icon
          v-if="!dragover" 
          color="indigo darken-2" 
          size="75"
        >mdi-cloud-upload-outline</v-icon>
        <v-icon
          v-if="dragover" 
          color="indigo darken-2" 
          size="75"
        >mdi-book-plus</v-icon>
      </v-row>
      <v-row justify="center">
        <span class="title indigo--text text--darken-2">Drag'n drop or click to upload file!</span>
      </v-row>
    </v-sheet>

  </v-container>
</template>

<script>
  export default {
    data: () => {
      return {
        dragover: false
      }
    },
    async mounted() {
      const dropzone = this.$el.querySelector("#dropzone")
      const fileupload = this.$el.querySelector("#fileUpload") // eslint-disable-line no-unused-vars
      if(dropzone) {
        // register all drag & drop event listeners
        dropzone.addEventListener("dragenter", e => {
          e.preventDefault()
          this.dragover = true
        })
        dropzone.addEventListener("dragleave", e => {
          e.preventDefault()
          this.dragover = false
        })
        dropzone.addEventListener("dragover", e => {
          e.preventDefault()
          this.dragover = true
        })
        dropzone.addEventListener("drop", e => {
          e.preventDefault()
          const dragevent = e
          if(dragevent.dataTransfer) {
            this.filesSelected(dragevent.dataTransfer.files) 
          }
        })
        dropzone.addEventListener("click", e => {
          e.preventDefault ()
          if(fileupload) {
            fileupload.click()
          }
        })
        dropzone.addEventListener("keypress", e => {
          e.preventDefault()
          const keyEvent = e
          if (keyEvent.key === "Enter") {
            if(fileupload) {
              fileupload.click()
            }
          }
        })
        // register listeners on the file input
        if(fileupload) {
          fileupload.addEventListener("change", e => {
            const target = (e.target)
            if(target.files) {
              this.filesSelected(target.files)
            }
          })
        }
      }
    },
    methods: {
      filesSelected(fileList) { // eslint-disable-line no-unused-vars 
        this.dragover = false
        this.$emit('filesSelected', fileList)
      }
    }
  }
</script>