<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
            <div
            class="text-center text-h2 transition-swing mb-10"
            v-text="'Editează profil'"
            ></div>
            <v-row class="mb-4">
                <v-col cols="4">
                    <v-avatar size="96" class="mr-4">
                        <img v-if="profile.profilePicture" :src="`${apiRoot}/api/files/${profile.profilePicture.filename}`" alt="Avatar">
                    </v-avatar>
                </v-col>
                <v-col cols="8">
                    <file-upload v-on:filesSelected="handlePictureUpload"></file-upload>
                </v-col>
            </v-row>
            <v-row class="md-12" v-if="!userHasName || !userHasiRacingMemberId || !userHasSteamId">
                <v-col cols="1">
                  <v-icon x-large color="blue darken-1">mdi-exclamation-thick</v-icon>
                </v-col>
                <v-col cols="11">
                    <p class="body-2"><span v-if="!userHasName">Pentru a putea fi înscris ca pilot într-un club, este necesară completarea <b>numelui</b> și <b>prenumelui</b>.<br /><br /></span><span v-if="!userHasiRacingMemberId">Numai piloții cu  <b>iRacing Member ID</b> vor putea intra în cluburi de iRacing.</span><span v-if="!userHasSteamId">Numai piloții cu contul de <b>Steam</b> asociat vor putea intra în cluburi de ACC.</span></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col md-6>
                    <v-text-field
                        v-model="profile.firstName"
                        label="Prenume"></v-text-field>
                </v-col>
                <v-col md-6>
                    <v-text-field
                        v-model="profile.lastName"
                        label="Nume de familie"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col md-6>
                    <v-country-select label="Țară" v-model="profile.country" />
                </v-col>
                <v-col md-6>
                    <v-region-select label="Oraș/Regiune" v-model="profile.city" :country="profile.country" :countryName="false" />
                </v-col>
            </v-row>
            <v-row>
                <v-col md-6>
                    <v-text-field
                        v-model="profile.iRacingMemberId"
                        label="iRacing member ID"></v-text-field>
                </v-col>
                <v-col md-6>
                    <v-text-field
                        v-model="profile.shorthand"
                        label="Prescurtarea numelui"
                        @keypress="isLetter($event)"
                        @keyup="uppercaseShorthand"
                        counter="3"
                        maxlength="3"
                        :rules="[threeLettersRule]"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">Atașează alte conturi:</v-col>
                <v-col cols="4">
                    <v-btn :disabled="Boolean(profile.discordCode)" color="primary" :loading="loading" @click.native="linkDiscord">
                        <v-icon left dark>mdi-discord</v-icon>
                        Discord
                    </v-btn>
                    <v-icon v-if="Boolean(profile.discordCode)" color="green" class="ml-1">
                      mdi-check
                    </v-icon>
                </v-col>
                <v-col cols="4">
                    <v-btn :disabled="Boolean(profile.steamId)" color="gray" :loading="loading" @click.native="linkSteam">
                        <v-icon left dark>mdi-steam</v-icon>
                        Steam
                    </v-btn>
                    <v-icon v-if="Boolean(profile.steamId)" color="green" class="ml-1">
                      mdi-check
                    </v-icon>
                </v-col>
            </v-row>
            <v-btn color="primary" :loading="loading" @click.native="update">
                <v-icon left dark>mdi-check</v-icon>
                Salvează
            </v-btn>
    </v-flex>
  </v-layout>
</template>


<script>
    import {mapState, mapActions} from 'vuex'
    import FileUpload from "@/components/FileUpload.vue"

    export default {
        pageTitle: 'My Profile',
        components: {
            FileUpload
                
        },
        async mounted() {
            await this.getProfile()
            
            this.userHasiRacingMemberId = !!this.profile.iRacingMemberId
            this.userHasSteamId = Boolean ( this.profile.steamId )
            this.userHasName = !!this.profile.firstName && !!this.profile.lastName
        },
        data () {
            return {
              userHasiRacingMemberId: false,
              userHasSteamId: false,
              userHasName: false
            }
        },
        computed: {
            ...mapState('user', ['profile']),
            ...mapState('app', ['loading']),
            fillmurray() {
                return Math.ceil(Math.random() * (400 - 96) + 96)
            },
            apiRoot() {
                return `${process.env.VUE_APP_API_SCHEMA}${process.env.VUE_APP_API_HOST}`
            }
        },
        methods: {
            ...mapActions('user', ['getProfile', 'setProfile', 'setProfilePicture']),
            ...mapActions('app', ['showSnackbar']),
            openAvatarPicker(){},
            async update() {
                await this.setProfile(this.profile)
                this.showSnackbar({
                    timeout: 7000,
                    value: true,
                    color: 'success',
                    content: 'Profil actualizat cu succes'
                })
            },
            linkDiscord() {
                window.location = `${process.env.VUE_APP_API_SCHEMA}${process.env.VUE_APP_API_HOST}/auth/discord`
            },
            linkSteam() {
                window.location = `${process.env.VUE_APP_API_SCHEMA}${process.env.VUE_APP_API_HOST}/auth/steam`
            },
            handlePictureUpload(files) {
                this.setProfilePicture(files[0])
            },
            isLetter(e) {
                let char = String.fromCharCode(e.keyCode); // Get the character
                if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
                else e.preventDefault(); // If not match, don't add to input text
            },
            uppercaseShorthand() {
                this.profile.shorthand = this.profile.shorthand.toUpperCase().substr(0, 3);
            },
            threeLettersRule(input) {
                return (input !== undefined && (input.length == 3 || !input.length)) || "Prescurtarea trebuie să conțină 3 litere";
            }
        }
    }
</script>
