import axios from '@/plugins/axios'

const state = {
    carInvite: {},
    carInviteActionResponse: {}
}

const getters = {
    carInvite(state) {
        return state.carInvite
    },
}

const actions = {
    async getCarInvite({
        commit
    }, carInviteId) {
        let eventsResponse = await axios({
            url: `/api/car-invite/${carInviteId}`,
            method: 'GET'
        })

        commit('GET_CAR_INVITE', eventsResponse.data)
    },
    async acceptCarInvite({
        commit
    }, carInviteId) {
        let eventsResponse = await axios({
            url: `/api/car-invite/${carInviteId}/accept`,
            method: 'POST'
        })
        commit('POST_ACCEPT_CAR_INVITE', eventsResponse.data)
    },
    async rejectCarInvite({
        commit
    }, carInviteId) {
        let eventsResponse = await axios({
            url: `/api/car-invite/${carInviteId}/reject`,
            method: 'POST'
        })
        commit('POST_REJECT_CAR_INVITE', eventsResponse.data)
    },
}

// mutations
const mutations = {
    GET_CAR_INVITE(state, payload) {
      if (payload.status == 200 ) {
        state.carInvite = payload.data
        state.carInviteActionResponse = payload
      } else {
        state.carInvite = {}
        state.carInviteActionResponse = payload
      }
    },
    POST_ACCEPT_CAR_INVITE(state, payload) {
        state.carInviteActionResponse = payload
    },
    POST_REJECT_CAR_INVITE(state, payload) {
        state.carInviteActionResponse = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

