<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md8 offset-md2 lg6 offset-lg3>
      <v-card>
        <v-card-title class="text-h4 font-weight-light pl-10 text-truncate">Cluburile mele</v-card-title>
        <v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="availableSimulators.length > 0" text color="blue" to="/teams/add">
              <v-icon>mdi-plus</v-icon> Creează club
            </v-btn>
          </v-card-actions>
          <v-card>
            <v-card-title class="headline font-weight-bold mb-2 mt-5">
              Cluburi pe care le dețin
            </v-card-title>
            
            <template v-for="(simulator, index) in simulators">
              <v-card-title v-if="teamsUserOwns.filter(t => {return t.simulator._id == simulator._id}).length"
                :key="simulator._id" class="subheading font-weight-bold">
                {{simulator.title}}
              </v-card-title>
              <v-list :key="index">
                <v-list-item-group>
                  <template
                    v-for="(team, teamIndex) in teamsUserOwns.filter(t => {return t.simulator._id == simulator._id})">
                    <v-divider v-if="teamIndex != 0" :key="teamIndex"></v-divider>

                    <v-list-item :key="team.name">

                      <v-list-item-content>
                        <v-list-item-title v-text="team.name"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="editTeam(team._id)" class="mr-2">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="askDeleteTeamConfirmation(team._id)">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip> -->
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </template>
          </v-card>
          <v-card>
            <v-card-title class="headline font-weight-bold mb-2 mt-5">
              Cluburi pe care le administrez
            </v-card-title>
            
            <template v-for="(simulator, index) in simulators">
              <v-card-title v-if="teamsUserCanManage.filter(t => {return t.simulator._id == simulator._id}).length"
                :key="simulator._id" class="subheading font-weight-bold">
                {{simulator.title}}
              </v-card-title>
              <v-list :key="index">
                <v-list-item-group>
                  <template
                    v-for="(team, teamIndex) in teamsUserCanManage.filter(t => {return t.simulator._id == simulator._id})">
                    <v-divider v-if="teamIndex != 0" :key="teamIndex"></v-divider>

                    <v-list-item :key="team.name">

                      <v-list-item-content>
                        <v-list-item-title v-text="team.name"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="editTeam(team._id)" class="mr-2">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </template>
          </v-card>
          <v-card>
            <v-card-title class="headline font-weight-bold mb-2 mt-5">
              Cluburi în care sunt pilot
            </v-card-title>

            <v-card-text>
              <v-list>
                <v-list-item-group>
                  <template v-for="(team, index) in teamsUserCanDrive">
                    <v-list-item :key="team.name">

                      <v-list-item-content>
                        <v-list-item-title>{{team.name}} ({{team.simulator.title}})</v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>

                    <v-divider v-if="index < teamsUserCanDrive.length - 1" :key="index"></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Really delete?
        </v-card-title>
        <v-card-text>Are you sure you want to delete the <strong>{{teamToDelete.name}}</strong> team? This cannot be
          undone!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            No
          </v-btn>
          <v-btn color="red darken-1" text @click="confirmTeamDeletion">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
  import {
    mapActions,
    mapState,
    mapGetters
  } from 'vuex'
  export default {

    data: () => {
      return {
        teamToDelete: {},
        deleteDialog: false
      }
    },
    computed: {
      ...mapState('team', [
        'teams',
        'teamsUserCanDrive',
        'teamsUserOwns',
        'teamsUserCanManage',
        'simulators',
        'availableSimulators'
      ]),
      ...mapGetters('auth', ['userinfo']),
    },
    methods: {
      ...mapActions('team', [
        'getTeams',
        'getTeamsUserCanDrive',
        'getTeamsUserOwns',
        'getTeamsUserCanManage',
        'deleteTeam',
        'getAvailableSimulators'
      ]),
      addTeam() {},
      editTeam(id) {
        this.$router.push({
          path: `/teams/edit/${id}`
        })
      },
      askDeleteTeamConfirmation(id) {
        let team = this.teams.filter((team) => {
          return team._id == id
        })
        this.teamToDelete = team[0]
        this.deleteDialog = true
      },
      async confirmTeamDeletion() {
        await this.deleteTeam(this.teamToDelete)
        await this.getTeams()
        await this.getTeamsUserCanDrive(this.userinfo.id)
        await this.getTeamsUserOwns(this.userinfo.id)
        await this.getTeamsUserCanManage(this.userinfo.id)
        await this.getAvailableSimulators()
        this.deleteDialog = false
      }
    },
    async mounted() {
      await this.getTeams()
      await this.getTeamsUserCanDrive(this.userinfo.id)
      await this.getTeamsUserOwns(this.userinfo.id)
      await this.getTeamsUserCanManage(this.userinfo.id)
      await this.getAvailableSimulators()
    }
  }
</script>
