<template>
  <v-layout row wrap class="my-5" align-center>
    <v-flex xs10 offset-xs1 sm8 offset-sm2 md8 offset-md2 lg6 offset-lg3>
      <v-card>
        <v-card-title>RLR Seasons</v-card-title>
        <v-card-text>
          <v-tabs color="deep-purple accent-4" right>
            <v-tab>Current</v-tab>
            <v-tab>Ended</v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-subheader :inset="inset">
                      Current Seasons
                    </v-subheader>

                    <v-list>
                      <template v-for="(season, index) in seasons">
                        <v-list-item :key="season._id" :to="`/season/${season._id}`">
                          <v-list-item-action>
                            <v-icon>mdi-label</v-icon> <!-- should be an avatar -->
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ season.title }}</v-list-item-title>
                            <v-list-item-subtitle v-html="season.description"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider v-if="index < seasons.length - 1" :key="index" :inset="inset"></v-divider>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-subheader :inset="inset">
                      Past Seasons
                    </v-subheader>

                    <v-list>
                      <template v-for="(item, itemindex) in []">
                        <v-list-item v-if="item.action" :key="itemindex">
                          <v-list-item-action>
                            <v-icon>{{ item.action }}</v-icon>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider v-else-if="item.divider" :key="itemindex" :inset="inset"></v-divider>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    data: () => {
      return {
        inset: true
      }
    },
    methods: {
      ...mapActions('season', ['getSeasons'])
    },
    computed: {
      ...mapState('season', ['seasons'])
    },
    async mounted () {
      await this.getSeasons()
    }
  }
</script>