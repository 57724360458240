<template>
    <v-layout row wrap class="my-5" align-center>
        <v-flex xs10 offset-xs1 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
            <v-card>
                <v-card-text>
                    <div
                        class="text-center text-h2 transition-swing mb-10"
                        v-text="'Introdu noua parolă'"
                    ></div>
                    <v-text-field
                        type="password"
                        v-model="password"
                        label="Parola Noua"></v-text-field>
                    <v-text-field
                        type="password"
                        v-model="passwordConfirm"
                        label="Confirma Parola"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" :loading="loading" @click.native="savePassword">
                        <v-icon left dark>mdi-check</v-icon>
                        Salvează parola
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>


<script>
    import {mapActions} from 'vuex'
    export default {
        pageTitle: 'My Profile',
        data () {
            return {
                loading: false,
                password: '',
                passwordConfirm: '',
            }
        },
        methods: {
            ...mapActions('auth', ['showSnackbar']),
            async savePassword() {
                try {
                await this.$http.post(`/auth/reset-password/${this.$route.params.hash}`, { password: this.password, passwordConfirm: this.passwordConfirm })
                this.showSnackbar({
                  timeout: 7000,
                  value: true,
                  color: 'success',
                  content: "Parola resetata",
                })
                this.$router.push({path: '/login'})
              } catch (err) {
                this.showSnackbar({
                  timeout: 7000,
                  value: true,
                  color: 'error',
                  content: "Ceva nu a mers bine, contacteaza organizatorii",
                })
              } 
            }
        }
    }
</script>