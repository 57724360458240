<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md8 offset-md2 lg6 offset-lg3>
      <v-card>
        <v-btn text color="blue" to="/teams" class="mt-10 ml-5"><v-icon>mdi-chevron-left</v-icon> Înapoi la cluburile mele</v-btn>
        <v-card-title class="text-h5 pt-10 pl-10">Creează un club nou</v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Numele clubului*" required v-model="teamName"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            :items="availableSimulators"
                            label="Simulator" v-model="simulator"></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            :items="drivers"
                            label="Drivers" multiple v-model="selectedDrivers"></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            :items="users"
                            label="Managers" multiple v-model="selectedManagers"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-container>
            <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="discard">
                Închide
            </v-btn>
            <v-btn color="blue darken-1" dark @click="saveTeam">
                Salvează
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-flex>
    <v-btn fab dark absolute bottom right class="mb-12" color="blue" to="/teams/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-layout>
</template>
<script>
import { mapState, mapActions } from 'vuex'

  export default {

    data: () => {
      return {
        teamName: '',
        selectedDrivers: [],
        selectedManagers: [],
        simulator: ''
      }
    },
    computed: {
      ...mapState('drivers', ['drivers']),
      ...mapState('user', ['users']),
      ...mapState('team', ['availableSimulators'])
    },
    async mounted() {
      await this.getDrivers()
      await this.getUsers()
      await this.getAvailableSimulators()
    },
    methods: {
      discard() {
        this.$router.back()
      },
      async saveTeam() {
        await this.addTeam({
          name: this.teamName,
          pendingDrivers: this.selectedDrivers,
          managers: this.selectedManagers,
          simulator: this.simulator
        })
        this.$router.back()
      },
      ...mapActions('drivers', ['getDrivers']),
      ...mapActions('user', ['getUsers']),
      ...mapActions('team', ['addTeam', 'getAvailableSimulators']),
    }
  }
</script>
