import axios from '@/plugins/axios'

const state = {
    teams: [],
    teamsList: [],
    driversList: [],
    editingTeam: {},
    pendingDrivers: [],
    simulators: [],
    availableSimulators: [],
    teamsUserCanDrive: [],
    teamsUserCanManage: [],
    teamsUserOwns: []
}

const getters = {
    teams(state) {
        return state.teams
    },
    teamsList(state) {
        return state.teamsList
    },
    editingTeam(state) {
        return state.editingTeam
    },
    pendingDrivers(state) {
        return state.pendingDrivers
    }
}

const actions = {
    async getTeams({
        commit,
        dispatch
    }) {
        let teamsResponse = await axios({
            url: '/api/teams',
            method: 'GET'
        })
        await dispatch('getSimulators')
        commit('GET_TEAMS', teamsResponse.data.data)
    },
    async getSimulators({
        commit
    }) {
        let simulatorsResponse = await axios({
            url: '/api/simulators',
            method: 'GET'
        })

        commit('GET_SIMULATORS', simulatorsResponse.data.data)
    },
    async getAvailableSimulators({
        commit,
        state,
        dispatch
    }) {
        if (!state.teams.length) {
            await dispatch('getTeamsUserOwns')
        }
        if (!state.simulators.length) {
            await dispatch('getSimulators')
        }
        let availableSimulators = state.simulators.filter(simulator => {
            let available = true
            state.teamsUserOwns.forEach(team => {
                if (team.simulator._id == simulator._id) {
                    available = false
                }
            })
            return available
        }).map(simulator => {
            simulator.text = simulator.title
            simulator.value = simulator._id
            return simulator
        })
        commit('GET_AVAILABLE_SIMULATORS', availableSimulators)
    },
    async getTeamsUserCanDrive({
        commit,
        dispatch
    }, userId) {
        if (!state.teams.length) {
            dispatch('getTeams')
        }

        commit('GET_TEAMS_USER_CAN_DRIVE', state.teams.filter(team => {
            return team.drivers.map(driver => {
                return driver._id
            }).includes(userId)
        }))
    },
    async getTeamsUserCanManage({
        commit,
        dispatch
    }, userId) {
        if (!state.teams.length) {
            dispatch('getTeams')
        }

        commit('GET_TEAMS_USER_CAN_MANAGE', state.teams.filter(team => {
            return team.managers.map(manager => {
                return manager._id
            }).includes(userId)
        }))
    },
    async getTeamsUserOwns({
        commit,
        dispatch,
        state,
    }, userId) {
        if (!state.teams.length) {
            dispatch('getTeams')
        }

        commit('GET_TEAMS_USER_OWNS', state.teams.filter(team => {
            return team.owner._id == userId
        }))
    },
    async getTeamsList({
        commit,
        dispatch,
        state
    }) {
        await dispatch('getTeams')
        let teams = state.teams.map(team => {
            return {
                ...team,
                text: team.name,
                value: team._id
            }
        })
        commit('GET_TEAMS_LIST', teams)
    },
    async getDriversList({
        commit
    }, options) {
        let driversResponse = await axios({
            url: `/api/team/${options.teamId}/drivers`,
            method: 'GET'
        })

        if ( driversResponse.data.status !== 200 ) {
            commit('GET_DRIVERS_LIST', []);
            return;
        }

        commit('GET_DRIVERS_LIST', driversResponse.data.data);
    },
    async getPendingDriversList({
        commit,
        state,
        rootState,
        dispatch
    }, teamId) {
        let pendingDrivers = state.teams.filter(team => {
            return team._id == teamId
        })[0].pendingDrivers

        await dispatch('user/getUsers', {}, {
            root: true
        })
        let pendingDriversList = rootState.user.users.filter(user => {
            let driverIsInTeam = (pendingDrivers.map(driver => {
                return driver._id
            }).indexOf(user._id) >= 0)
            let driverHasSeasonNeccesaryIds = rootState.season.season.rules.simulator.requiredDriverFields.reduce((a, c) => {
                return a && !user.profile[c]
            })
            return driverIsInTeam && driverHasSeasonNeccesaryIds
        }).map(user => {
            user.text = `${user.profile.firstName} ${user.profile.lastName}`
            user.value = user._id
            return user
        })
        
        commit('GET_PENDING_DRIVERS_LIST', pendingDriversList)
    },
    async addTeam({
        commit
    }, team) {
        let teamsResponse = await axios({
            url: '/api/teams/',
            method: 'POST',
            data: team
        })
        commit('GET_TEAMS', teamsResponse.data.data)
    },
    async editTeam({
        commit,
        state
    }) {
        try {
            let teamsResponse = await axios({
                url: `/api/team/${state.editingTeam._id}`,
                method: 'POST',
                data: state.editingTeam
            })
            commit('GET_TEAMS', teamsResponse.data.data)
        } catch (err) { 
            console.log(err)
        }
    },
    async deleteTeam({
        dispatch
    }, team) {
        await axios({
            url: `/api/team/${team._id}/delete`,
            method: 'GET',
            data: team
        })
        await dispatch('getTeamsUserOwns')
    },
    async getEditingTeam({
        commit,
        state,
        rootState,
        dispatch
    }, teamId) {
        let team = state.teams.filter(team => {
            return team._id == teamId
        })[0]

        await dispatch('user/getUsers', {}, {
            root: true
        })
        team.drivers = rootState.user.users.filter(user => {
            return team.drivers.map(driver => {
                return driver._id
            }).indexOf(user._id) >= 0
        }).map(user => {
            user.text = `${user.profile.firstName} ${user.profile.lastName}`
            user.value = user._id
            return user
        })

        team.managers = rootState.user.users.filter(user => {
            return team.managers.map(manager => {
                return manager._id
            }).indexOf(user._id) >= 0
        }).map(user => {
            user.text = `${user.profile.firstName} ${user.profile.lastName}`
            user.value = user._id
            return user
        })

        team.pendingDrivers = rootState.user.users.filter(user => {
            return team.pendingDrivers.map(driver => {
                return driver._id
            }).indexOf(user._id) >= 0
        }).map(user => {
            user.text = `${user.profile.firstName} ${user.profile.lastName}`
            user.value = user._id
            return user
        })

        commit('GET_EDITING_TEAM', team)
    }
}

// mutations
const mutations = {
    GET_TEAMS(state, payload) {
        state.teams = payload
        state.editingTeam = {}
    },
    GET_TEAMS_USER_CAN_DRIVE(state, payload) {
        state.teamsUserCanDrive = payload
    },
    GET_TEAMS_USER_CAN_MANAGE(state, payload) {
        state.teamsUserCanManage = payload
    },
    GET_TEAMS_USER_OWNS(state, payload) {
        state.teamsUserOwns = payload
    },
    GET_EDITING_TEAM(state, payload) {
        state.editingTeam = payload
    },
    GET_TEAMS_LIST(state, payload) {
        state.teamsList = payload
    },
    GET_DRIVERS_LIST(state, payload) {
        state.driversList = payload
    },
    GET_PENDING_DRIVERS_LIST(state, payload) {
        state.pendingDriversList = payload
    },
    GET_SIMULATORS(state, payload) {
        state.simulators = payload
    },
    GET_AVAILABLE_SIMULATORS(state, payload) {
        state.availableSimulators = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}