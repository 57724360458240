<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md8 offset-md2 lg6 offset-lg3>
      <v-card>
        <v-btn text color="blue" :to="`/event/${event._id}?tab=results&sessionresults=${currentSession._id}`" class="mt-10 ml-5"
          ><v-icon>mdi-chevron-left</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">Inapoi la cursa:</span>
          {{ event.title }}</v-btn
        >
        <v-card-title class="text-h4 font-weight-light pl-10 text-truncate">
          Editează rezultate &nbsp; <span class="font-weight-black mx-2">{{ currentSession.title }}</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"  v-if="canUserEditResults">
          <v-container fluid>
            <v-row v-if="canUserEditResults">
              <v-col cols=12 align="center">
                <v-dialog v-model="fileDialog" persistent max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mx-2"
                      v-bind="attrs" v-on="on"
                    >
                      <v-icon left>mdi-file-edit</v-icon>Încarcă rezultate
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">
                      Încarcă rezultatele
                    </v-card-title>

                    <v-card-text
                      >Alege fișierul pe care vrei să-l încarci și formatul său, pentru a fi procesat de platformă.

                      <v-file-input
                        show-size
                        v-model="resultsFile"
                      ></v-file-input>
                      <v-select
                       v-model="fileProcessor"
                       :items="validFileProcessors"
                       item-text="name"
                       item-value="value"
                       return-object
                       single-line
                       label="Select processor"
                      ></v-select>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="closeFileUploadDialog()">
                        Anulează
                      </v-btn>
                      <v-btn
                        :disabled="!resultsFile || !fileProcessor"
                        color="green"
                        :dark="!!resultsFile && !!fileProcessor"
                        @click="uploadResultsFile()"
                      >
                        Încarcă<v-icon right>mdi-upload</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  color="green"
                  class="mx-2"
                  dark
                  v-if="unsavedChanges"
                  @click="saveResults()"
                >
                  <v-icon left>mdi-content-save</v-icon>Salvează
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="unsavedChanges" align="center">
              <v-col align="center">
                <span class="text-subtitle-1 red--text font-weight-bold">Ai modificări nesalvate!</span>
              </v-col>
            </v-row>
            <v-row v-if="waitingForProcessorResult" class="my-5">
              <v-col  align="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="70"
                  width="5"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-if="currentSession && currentSession.results.length > 0 && !waitingForProcessorResult">
              <v-col>
                <v-data-table
                  :headers="isRaceSession ? raceEventResultsTableHeaders : hotlapEventResultsTableHeaders"
                  :items="currentSession.results"
                  :group-by="event.results && event.results.length && event.results[0].Cursa ? 'Cursa' : null"
                  disable-pagination
                  hide-default-footer
                  :header-props="{ 'disable-sort': true }"
                  dense
                >
                  <template v-slot:[`item.car`]="{ item }">
                    {{item.eventRsvp.car.carModel.title}}
                  </template>
                  <template v-slot:[`item.team`]="{ item }">
                    <router-link :to="`/team-profile/${item.team._id}`">{{item.team.name}}</router-link>
                  </template>
                  <template v-slot:[`item.numberOfLaps`]="{ item }">
                    <v-dialog
                      v-model="carLapsDialog[currentSession.results.indexOf(item)]"
                      max-width="700"
                      @click:outside="carLapsDialog[currentSession.results.indexOf(item)] = false"
                      scrollable
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn min-width="32" class="px-1" text color="primary" v-on="on"><v-icon>mdi-timer</v-icon>{{item.laps.length}}</v-btn>
                      </template>
                      
                      <v-card class="pb-1">
                        <v-card-title>
                          <span class="headline">#{{ item.eventRsvp.car.raceNumber }} {{item.eventRsvp.car.carName}}</span>
                        </v-card-title>
                        <v-card-subtitle>
                          <span>Tururi</span>
                        </v-card-subtitle>
                        
                        <v-card-text style="max-height: 700px">
                          <v-data-table
                            :headers="[
                              {text:'#',value:'index',sortable:false,width:'5%'},
                              {text:'Pilot',value:'driver',sortable:false,width:'45%'},
                              {text:'Timp',value:'time',sortable:false,width:'45%'},
                              {text:'Valid',value:'valid',sortable:false,width:'5%'}
                            ]"
                            :items="item.laps"
                            disable-pagination
                            hide-default-footer
                            dense
                          >
                            <template v-slot:item="{item, index}">
                              <tr>
                                <td>{{index + 1 }}</td>
                                <td>{{ item.driver.profile.firstName }} {{ item.driver.profile.lastName }}</td>
                                <td>{{ printLaptime (item.time) }}</td>
                                <td>
                                  <v-icon color="green" v-if="item.valid">mdi-check</v-icon>
                                  <v-icon color="red" v-else>mdi-close</v-icon>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:[`item.totalTime`]="{ item }">
                    {{printRaceResultTime (item.totalTime)}}
                    <span v-if="item.dsq">
                      (<strong class="red--text">DQ</strong>)
                    </span>
                    <span v-else-if="item.timePenalties.length>0">
                      (<strong class="red--text">+{{item.timePenalties.reduce((a,b) => a+b.amount, 0)}}s</strong>)
                    </span>
                  </template>
                  <template v-slot:[`item.crew`]="{ item }">
                    #{{ item.eventRsvp.car.raceNumber }} {{item.eventRsvp.car.carName}}
                    <br />
                    (<template v-for="(driver, index) in item.drivers">
                      <span :key="driver._id">
                        <router-link :to="`/driver-profile/${driver._id}`">{{driver.profile.firstName.slice(0, 1)}}. {{driver.profile.lastName}}</router-link>
                        <span v-if="index < item.drivers.length - 1">, </span>
                      </span>
                    </template>)
                  </template>
                  <template v-slot:[`item.fastestLap`]="{ item }">
                    <span class="font-weight-bold">{{printBestLaptime (item.laps)}}</span> ({{printBestLaptimeDriver(item.laps)}})
                  </template>
                  <template v-slot:[`item.penalties`]="{ item }">
                    <v-dialog
                      v-model="carPenaltiesDialog[currentSession.results.indexOf(item)]"
                      max-width="900"
                      @click:outside="carPenaltiesDialog[currentSession.results.indexOf(item)] = false"
                      scrollable
                    >
                      <template v-slot:activator="{ on }">
                        <template v-if="item.timePenalties.length + item.lapPenalties.length + item.gridPenalties.length > 0">
                          <v-btn min-width="32" class="px-1" text tile color="red" v-on="on"><v-icon>mdi-clipboard-text</v-icon>{{item.timePenalties.length + item.lapPenalties.length + item.gridPenalties.length}}</v-btn>
                        </template>
                        <template v-else-if="item.dsq">
                          <v-btn min-width="32" class="px-1" text tile color="red" v-on="on"><v-icon>mdi-clipboard-text</v-icon>DQ</v-btn>
                        </template>
                        <template v-else>
                          <v-btn min-width="32" class="px-1" text tile color="primary" v-on="on"><v-icon>mdi-clipboard-text</v-icon>0</v-btn>
                        </template>
                      </template>
                      <v-card class="pb-1">
                          <v-card-title>
                            <span class="headline">
                            #{{ item.eventRsvp.car.raceNumber }} {{item.eventRsvp.car.carName}}
                            </span>
                        </v-card-title>
                        <v-card-subtitle>
                            <v-switch
                              v-model="item.dsq"
                              color="error"
                              large
                              @change="resultsModified()"
                            >
                              <template v-slot:label>
                                <span :class="item.dsq ? 'red--text mr-4 font-weight-regular' : 'mr-4 font-weight-regular'">Descalificat</span>
                              </template>
                            </v-switch>
                        </v-card-subtitle>
                        
                        <v-card-text style="max-height: 700px">
                          <v-tabs
                            v-model="carPenaltyTab[currentSession.results.indexOf(item)]"
                            background-color="primary"
                            dark
                            fixed-tabs
                            icons-and-text
                          >
                            <v-tab href="#time" v-if="isRaceSession">
                              Timp
                              <v-icon>mdi-clock-plus-outline</v-icon>
                            </v-tab>
                            <v-tab href="#laps" v-if="isRaceSession">
                              Tururi
                              <v-icon>mdi-flag-minus</v-icon>
                            </v-tab>
                            <v-tab href="#grid">
                              Poziții
                              <v-icon>mdi-chevron-triple-down</v-icon>
                            </v-tab>
                            
                            <v-tabs-items v-model="carPenaltyTab[currentSession.results.indexOf(item)]">
                              <v-tab-item value="time">
                                <v-row class="mx-1" dense v-if="isRaceSession">
                                  <v-col
                                    cols="12"
                                  >
                                    <v-list two-line>
                                      <v-subheader v-if="item.timePenalties.length > 0">Penalizări adăugate</v-subheader>
                                      <v-list-item v-for="(penalty, i) in item.timePenalties" :key="i">
                                        <v-list-item-action>
                                          <v-btn icon color="red" @click="removeTimePenalty(currentSession.results.indexOf(item), i)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-row>
                                            <v-col cols="3" class="py-0">
                                              <v-text-field type="number" :rules="[rules.required, rules.timePenaltyRange]" label="Secunde" v-model="penalty.amount" @change="resultsModified()" class="py-0"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0">
                                              <v-text-field :rules="[rules.required]" label="Descriere" v-model="penalty.description" @change="resultsModified()" class="py-0"></v-text-field>
                                            </v-col>
                                            <v-col cols="3" class="py-0">
                                              <v-select :rules="[rules.required]" :items="item.drivers" :item-text="(value) => `${value.profile.firstName} ${value.profile.lastName}`" item-value="_id" label="Pilot vinovat" v-model="penalty.driver" @change="resultsModified()" class="py-0"></v-select>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider v-if="item.timePenalties.length > 0"></v-divider>
                                      <v-subheader>Adaugă penalizări</v-subheader>
                                      <v-list-item>
                                        <v-list-item-action>
                                          <v-btn
                                            icon
                                            color="green"
                                            @click="addTimePenalty(currentSession.results.indexOf(item))"
                                            :disabled="!carPendingTimePenalty[currentSession.results.indexOf(item)].formValid"
                                          >
                                            <v-icon>mdi-plus-box</v-icon>
                                          </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-form v-model="carPendingTimePenalty[currentSession.results.indexOf(item)].formValid">
                                            <v-row>
                                              <v-col cols="3" class="py-0">
                                                <v-text-field type="number" :rules="[rules.required, rules.timePenaltyRange]" label="Secunde" v-model="carPendingTimePenalty[currentSession.results.indexOf(item)].amount" class="py-0"></v-text-field>
                                              </v-col>
                                              <v-col cols="6" class="py-0">
                                                <v-text-field :rules="[rules.required]" label="Descriere" v-model="carPendingTimePenalty[currentSession.results.indexOf(item)].description" class="py-0"></v-text-field>
                                              </v-col>
                                              <v-col cols="3" class="py-0">
                                                <v-select :rules="[rules.required]" :items="item.drivers" :item-text="(value) => `${value.profile.firstName} ${value.profile.lastName}`" item-value="_id" label="Pilot vinovat" v-model="carPendingTimePenalty[currentSession.results.indexOf(item)].driver" class="py-0"></v-select>
                                              </v-col>
                                            </v-row>
                                          </v-form>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                              </v-tab-item>
                              <v-tab-item value="laps">
                                <v-row class="mx-1" dense v-if="isRaceSession">
                                  <v-col
                                    cols="12"
                                  >
                                    <v-list two-line>
                                      <v-subheader v-if="item.lapPenalties.length > 0">Penalizări adăugate</v-subheader>
                                      <v-list-item v-for="(penalty, i) in item.lapPenalties" :key="i">
                                        <v-list-item-action>
                                          <v-btn icon color="red" @click="removeLapPenalty(currentSession.results.indexOf(item), i)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-row>
                                            <v-col cols="3" class="py-0">
                                              <v-text-field type="number" :rules="[rules.required, rules.lapPenaltyRange]" label="Tururi" v-model="penalty.amount" @change="resultsModified()" class="py-0"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0">
                                              <v-text-field :rules="[rules.required]" label="Descriere" v-model="penalty.description" @change="resultsModified()" class="py-0"></v-text-field>
                                            </v-col>
                                            <v-col cols="3" class="py-0">
                                              <v-select :rules="[rules.required]" :items="item.drivers" :item-text="(value) => `${value.profile.firstName} ${value.profile.lastName}`" item-value="_id" label="Pilot vinovat" v-model="penalty.driver" @change="resultsModified()" class="py-0"></v-select>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider v-if="item.lapPenalties.length > 0"></v-divider>
                                      <v-subheader>Adaugă penalizări</v-subheader>
                                      <v-list-item>
                                        <v-list-item-action>
                                          <v-btn
                                            icon
                                            color="green"
                                            @click="addLapPenalty(currentSession.results.indexOf(item))"
                                            :disabled="!carPendingLapPenalty[currentSession.results.indexOf(item)].formValid"
                                          >
                                            <v-icon>mdi-plus-box</v-icon>
                                          </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-form v-model="carPendingLapPenalty[currentSession.results.indexOf(item)].formValid">
                                            <v-row>
                                              <v-col cols="3" class="py-0">
                                                <v-text-field type="number" :rules="[rules.required, rules.lapPenaltyRange]" label="Tururi" v-model="carPendingLapPenalty[currentSession.results.indexOf(item)].amount" class="py-0"></v-text-field>
                                              </v-col>
                                              <v-col cols="6" class="py-0">
                                                <v-text-field :rules="[rules.required]" label="Descriere" v-model="carPendingLapPenalty[currentSession.results.indexOf(item)].description" class="py-0"></v-text-field>
                                              </v-col>
                                              <v-col cols="3" class="py-0">
                                                <v-select :rules="[rules.required]" :items="item.drivers" :item-text="(value) => `${value.profile.firstName} ${value.profile.lastName}`" item-value="_id" label="Pilot vinovat" v-model="carPendingLapPenalty[currentSession.results.indexOf(item)].driver" class="py-0"></v-select>
                                              </v-col>
                                            </v-row>
                                          </v-form>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                              </v-tab-item>
                              <v-tab-item value="grid">
                                <v-row class="mx-1" no-gutters>
                                  <v-col
                                    cols="12"
                                  >
                                    <v-list two-line>
                                      <v-subheader v-if="item.gridPenalties.length > 0">Penalizări adăugate</v-subheader>
                                      <v-list-item v-for="(penalty, i) in item.gridPenalties" :key="i">
                                        <v-list-item-action>
                                          <v-btn icon color="red" @click="removeGridPenalty(currentSession.results.indexOf(item), i)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-row>
                                            <v-col cols="3" class="py-0">
                                              <v-text-field type="number" :rules="[rules.required, rules.gridPenaltyRange]" label="Poziții" v-model="penalty.amount" @change="resultsModified()" class="py-0"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0">
                                              <v-text-field :rules="[rules.required]" label="Descriere" v-model="penalty.description" @change="resultsModified()" class="py-0"></v-text-field>
                                            </v-col>
                                            <v-col cols="3" class="py-0">
                                              <v-select :rules="[rules.required]" :items="item.drivers" :item-text="(value) => `${value.profile.firstName} ${value.profile.lastName}`" item-value="_id" label="Pilot vinovat" v-model="penalty.driver" @change="resultsModified()" class="py-0"></v-select>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider v-if="item.gridPenalties.length > 0"></v-divider>
                                      <v-subheader>Adaugă penalizări</v-subheader>
                                      <v-list-item>
                                        <v-list-item-action>
                                          <v-btn
                                            icon
                                            color="green"
                                            @click="addGridPenalty(currentSession.results.indexOf(item))"
                                            :disabled="!carPendingGridPenalty[currentSession.results.indexOf(item)].formValid"
                                          >
                                            <v-icon>mdi-plus-box</v-icon>
                                          </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-form v-model="carPendingGridPenalty[currentSession.results.indexOf(item)].formValid">
                                            <v-row>
                                              <v-col cols="3" class="py-0">
                                                <v-text-field type="number" :rules="[rules.required, rules.gridPenaltyRange]" label="Poziții" v-model="carPendingGridPenalty[currentSession.results.indexOf(item)].amount" class="py-0"></v-text-field>
                                              </v-col>
                                              <v-col cols="6" class="py-0">
                                                <v-text-field :rules="[rules.required]" label="Descriere" v-model="carPendingGridPenalty[currentSession.results.indexOf(item)].description" class="py-0"></v-text-field>
                                              </v-col>
                                              <v-col cols="3" class="py-0">
                                                <v-select :rules="[rules.required]" :items="item.drivers" :item-text="(value) => `${value.profile.firstName} ${value.profile.lastName}`" item-value="_id" label="Pilot vinovat" v-model="carPendingGridPenalty[currentSession.results.indexOf(item)].driver" class="py-0"></v-select>
                                              </v-col>
                                            </v-row>
                                          </v-form>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-tabs>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from '@/plugins/axios';

export default {
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Obligatoriu',
        timePenaltyRange: value => (value >= 0 && value <= 300) || 'Între 0 și 300 de secunde.',
        lapPenaltyRange: value => (value >= 0 && value <= 100) || 'Între 0 și 100 de tururi.',
        gridPenaltyRange: value => (value >= 0 && value <= 20) || 'Între 0 și 20 de poziții.'
      },
      eventResultsLeaderLaps: 0,
      raceEventResultsTableHeaders: [
        { text: "Poz.", value: "finalOverallResult", sortable: true, width: "4%" },
        { text: "Echipaj", value: "crew", sortable: false, width: "30%" },
        { text: "Club", value: "team", sortable: false, width: "25%" },
        { text: "Tururi", value: "numberOfLaps", sortable: false, width: "10%" },
        { text: "Timp total", value: "totalTime", sortable: false, width: "13%" },
        { text: "Fastest lap", value: "fastestLap", sortable: true, width: "13%" },
        { text: "Penalizări", value: "penalties", sortable: false, width: "5%" },
      ],
      hotlapEventResultsTableHeaders: [
        { text: "Poz.", value: "finalOverallResult", sortable: true, width: "5%" },
        { text: "Echipaj", value: "crew", sortable: false, width: "33%" },
        { text: "Club", value: "team", sortable: false, width: "27%" },
        { text: "Tururi", value: "numberOfLaps", sortable: false, width: "10%" },
        { text: "Fastest lap", value: "fastestLap", sortable: true, width: "20%" },
        { text: "Penalizări", value: "penalties", sortable: false, width: "5%" },
      ],
      currentSession: {
        type: { code: 'P' },
        results: []
      },
      fileDialog: false,
      validFileProcessors: [],
      waitingForProcessorResult: false,
      unsavedChanges: false,
      resultsFile: null,
      fileProcessor: null,
      carPenaltiesDialog: [],
      carLapsDialog: [],
      carPendingTimePenalty: [],
      carPendingLapPenalty: [],
      carPendingGridPenalty: [],
      carPenaltyTab: []
    };
  },
  methods: {
    ...mapActions("event", ["getEvent"]),
    ...mapActions("user", ["getUserPermissions"]),
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("season", ["getSeason"]),
    closeFileUploadDialog() {
      this.fileDialog = false;
    },
    async uploadResultsFile () {
      this.waitingForProcessorResult = true;
      this.closeFileUploadDialog();
    
      let formData = new FormData();
      formData.append('file', this.resultsFile);
      
      let fileUploadResponse = await axios.post( '/api/upload', formData, { headers: { 'Content-Type': 'multipart/form-data'}});

      // TODO: Error checking in case of failed upload!
      
      let fileProcessingResponse = await axios.post (
        `/api/process-results-file`,
        {
          fileId: fileUploadResponse.data.data.fileUpload._id,
          event: this.event._id,
          session: this.currentSession._id,
          processor: this.fileProcessor.value
        }
      )
      
      this.currentSession.results = fileProcessingResponse.data.data.results;
      this.resultsModified();
      this.onResultsLoaded ();
      
      this.waitingForProcessorResult = false;
    },
    resultsModified () {
      this.unsavedChanges = true;
    },
    resultsSaved () {
      this.unsavedChanges = false;
    },
    async saveResults () {
      this.waitingForProcessorResult = true;
      
      let saveResultsResponse = await axios.post (
        `/api/event/${this.event._id}/save-results`,
        {
          results: this.currentSession.results,
          session: this.currentSession._id
        }
      )
      
      // TODO: Error checking!
      
      this.currentSession.results = saveResultsResponse.data.data.results;
      
      this.resultsSaved ();
      
      this.waitingForProcessorResult = false;
      
      this.onResultsLoaded ()
    },
    printDate(date) {
      let m = new Date(date);

      let result =
        m.getUTCFullYear() + "-" + ("00" + (m.getUTCMonth() + 1)).slice(-2) + "-" + ("00" + m.getUTCDate()).slice(-2);

      result += " @ " + ("00" + m.getHours()).slice(-2) + ":" + ("00" + m.getMinutes()).slice(-2);

      return result;
    },
    printLaptime(time) {
      if (time == 2147483647) {
        return "---";
      }

      let t = "";
      let minutes = Math.floor(time / 60000);
      // minutes = ("00"+(minutes)).slice(-2)

      let seconds = Math.floor(time / 1000) % 60;
      seconds = ("00" + seconds).slice(-2);

      let milliseconds = time % 1000;
      milliseconds = ("000" + milliseconds).slice(-3);

      t += minutes + ":" + seconds + "." + milliseconds;

      return t;
    },
    printRaceResultTime(time) {
      let t = "";

      let hours = Math.floor(time / 3600000);

      if (hours) {
        t += hours;
        t += ":";
      }

      let minutes = Math.floor(time / 60000) % 60;
      if (hours) {
        t += ("00" + minutes).slice(-2);
      } else {
        t += minutes;
      }

      t += ":";

      let seconds = Math.floor(time / 1000) % 60;
      t += ("00" + seconds).slice(-2);

      t += ".";

      let milliseconds = time % 1000;
      t += milliseconds = ("000" + milliseconds).slice(-3);

      return t;
    },
    printBestLaptime (laps) {
      return this.printLaptime(laps.reduce ((a, b) => a > b.time && b.valid ? b.time : a, 2147483647));
    },
    printBestLaptimeDriver (laps) {
      let driver = '';
      let bestlap = 2147483647;
      
      laps.forEach (lap => {
        if (lap.time < bestlap) {
          bestlap = lap.time;
          driver = lap.driver.profile.firstName.slice(0,1) + lap.driver.profile.lastName.slice(0,1);
        }
      })
      
      return driver;
    },
    updateEventResultsTableData() {
      this.event.results = this.event.results.map((result) => {
        result.Cursa = result.race;
        return result;
      });
    },
    onResultsLoaded () {
      this.carPenaltiesDialog = new Array (this.currentSession.results.length).fill ( false );
      this.carLapsDialog = new Array (this.currentSession.results.length).fill ( false );
      this.carPenaltyTab = new Array (this.currentSession.results.length).fill ( '' );
      
      this.carPendingTimePenalty = [];
      this.carPendingLapPenalty = [];
      this.carPendingGridPenalty = [];
      for (let i = 0; i < this.currentSession.results.length; i++) {
        this.carPendingTimePenalty.push ( {amount: undefined, description: undefined, driver: undefined, formValid: false } )
        this.carPendingLapPenalty.push ( {amount: undefined, description: undefined, driver: undefined, formValid: false } )
        this.carPendingGridPenalty.push ( {amount: undefined, description: undefined, driver: undefined, formValid: false } )
      }
    },
    addTimePenalty (index) {
      this.currentSession.results[index].timePenalties.push (Object.assign({}, this.carPendingTimePenalty[index]))
      
      this.carPendingTimePenalty[index].amount = '';
      this.carPendingTimePenalty[index].description = '';
      this.carPendingTimePenalty[index].driver = '';
      
      this.resultsModified();
    },
    removeTimePenalty (carIndex, penaltyIndex) {
      this.currentSession.results[carIndex].timePenalties.splice(penaltyIndex, 1);
      this.resultsModified();
    },
    addLapPenalty (index) {
      this.currentSession.results[index].lapPenalties.push (Object.assign({}, this.carPendingLapPenalty[index]))
      
      this.carPendingLapPenalty[index].amount = undefined;
      this.carPendingLapPenalty[index].description = undefined;
      this.carPendingLapPenalty[index].driver = undefined;
      
      this.resultsModified();
    },
    removeLapPenalty (carIndex, penaltyIndex) {
      this.currentSession.results[carIndex].lapPenalties.splice(penaltyIndex, 1);
      this.resultsModified();
    },
    addGridPenalty (index) {
      this.currentSession.results[index].gridPenalties.push (Object.assign({}, this.carPendingGridPenalty[index]))
      
      this.carPendingGridPenalty[index].amount = undefined;
      this.carPendingGridPenalty[index].description = undefined;
      this.carPendingGridPenalty[index].driver = undefined;
      
      this.resultsModified();
    },
    removeGridPenalty (carIndex, penaltyIndex) {
      this.currentSession.results[carIndex].gridPenalties.splice(penaltyIndex, 1);
      this.resultsModified();
    }
  },
  computed: {
    ...mapState("event", ["event"]),
    ...mapState("season", ["season"]),
    ...mapGetters("user", ["userPermissions"]),
    ...mapGetters("auth", ["userinfo", "isLoggedIn"]),
    canUserEditResults () {
      return this.userPermissions.filter(perm => this.season.permissions.editRaceResults.includes(perm)).length;
    },
    isHotlapSession () {
      return this.currentSession.type.code == 'Q' || this.currentSession.type.code == 'P'
    },
    isRaceSession () {
      return this.currentSession.type.code == 'R'
    }
  },
  async mounted() {
    await this.getEvent(this.$route.params.event);
    await this.getSeason(this.event.season._id);
    await this.getUserPermissions();
    
    this.currentSession = this.event.sessions.filter(s => s._id == this.$route.params.session)[0];
    
    this.onResultsLoaded ();
    
    
    switch ( this.event.season.rules.simulator.acronym ) {
      case 'AC':
        this.validFileProcessors = [
          {
            'name': 'AC server results JSON',
            'value': 'acresultsjson'
          }
        ];
        break;
      case 'ACC':
        this.validFileProcessors = [
          {
            'name': 'ACC server results JSON',
            'value': 'accresultsjson'
          }
        ];
        break;
      case 'iR':
        this.validFileProcessors = [
          {
            'name': 'iRacing session results CSV',
            'value': 'irsessioncsv'
          }
        ];
        break;
    }

    this.eventResultsLeaderLaps = this.event.results[0] ? this.event.results[0].numLaps : 0;

    if (this.event.results[0] && this.event.results[0].race) {
      // daca am race, adica eventul are 2 curse
      this.eventResultsLeaderLaps = [];
      this.eventResultsLeaderLaps[this.event.results[0].race] = this.event.results[0].numLaps; // tururi lider prima cursa
      for (let i = this.event.results.length - 1; i >= 0; i--) {
        this.eventResultsLeaderLaps[this.event.results[i].race] = this.event.results[i].numLaps;
      }
    }
  },
};
</script>

<style>
.v-row-group__header .text-start button:nth-of-type(2) {
  display: none;
}
</style>
